import api from '@/api';
import AffiliateRequest from '@/model/affiliate/AffiliateRequest';
import AffiliateSearchRequest from '@/model/affiliate/AffiliateSearchRequest';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return api.base().get('/affiliate/data')
        .then(response => commit('updateData', response.data.data));
    },
    getAllAffiliates(actions, { filter, pagination, sort }) {
      return api.base().post('/affiliate/get/all', AffiliateSearchRequest({ ...(filter || {}), pagination, sort, }))
        .then(response => ({ data: response.data.data, pagination: { page: response.data.metadata.page, total: response.data.metadata.total }, sort: response.data.metadata.sort }));
    },
    getAffiliateById({ commit }, id) {
      return api.base().get('/affiliate/id/' + id)
        .then(response => response.data.data);
    },
    getAffiliateTokens({ commit }, id) {
      return api.base().get('/affiliate/token/affiliate/' + id)
        .then(response => response.data.data);
    },
    createAffiliateToken(action, token){
      return api.base().post('/affiliate/token/create', token)
        .then(response => response.data.data);
    },
    updateAffiliateToken(action, token){
      return api.base().post('/affiliate/token/update/'+token.id, token)
        .then(response => response.data.data);
    },
    updateAffiliate({ commit }, { affiliate, id }) {
      return api.base()
        .post('/affiliate/update/' + id, AffiliateRequest(affiliate))
        .then(response => response.data.data);
    },

    createAffiliate({ commit }, { affiliate }) {
      return api.base()
        .post('/affiliate/add', AffiliateRequest(affiliate))
        .then(response => response.data.data);
    },

    deleteCpa({ commit }, {id,cpaId}) {
      return api.base()
        .post('/affiliate/delete/cpa/' + id + '/' + cpaId)
        .then(response => response.data.data);
    },

    createCpaAffiliate({ commit }, {id,cpa}) {
      return api.base()
        .post('/affiliate/add/cpa/' + id , cpa)
        .then(response => response.data.data);
    }
  }

};
