import { vue } from '../../main'
import i18n from '@/libs/i18n/index'
import {localize} from 'vee-validate';

const locales = [
  { locale: 'en', locale_full: 'en', label: 'English', country: 'GB', },
  { locale: 'fr', locale_full: 'fr', label: 'Français', country: 'FR' },
  { locale: 'de', locale_full: 'de', label: 'Deutsch', country: 'AT', },
]

export default {
  namespaced: true,
  state: {
    lang: null,
  },
  getters: {
    msg: (state) => (key, data) => {
      if (key == null || typeof key !== 'string') {
        return key
      }
      let x = state.lang//Reactive update
      if (vue.$te(key) && vue.$t(key) !== '') {
        return vue.$t(key, data)
      }
      let temp = key.replace(/ /g, '')
      if (vue.$te(temp) && vue.$t(temp) !== '') {
        return vue.$t(temp, data)
      }
      temp = temp.toLocaleLowerCase()
      return vue.$te(temp) && vue.$t(temp) !== '' ? vue.$t(temp, data) : key
    },
    getCurrentLang(state) {
      return state.lang.toLowerCase()
    },
    currentLocale(state, getters) {
      return locales.find(l => l.locale === getters.currentLang) || this.locales.find(l => l.locale === 'en')
    },
    currentLang(state) {
      return state.lang || localStorage.selectedLang || 'en'
    },
  },
  mutations: {
    setLanguage(state, lang) {
      state.lang = lang || localStorage.selectedLang || 'en'
      if (locales.find(l => l.locale === state.lang) == null) {
        state.lang = 'en'
      }
      console.log('setLanguage', state.lang)
      i18n.locale = state.lang
      localStorage.setItem('selectedLang', state.lang)
      vue.$root.$emit('bv::refresh::table')
      vue.$root.$emit('crm::lang-updated', state.selected)
      state.loaded = true
      vue.$root.$emit('crm::language::updated')
      vue.$moment.locale(state.lang)
      localize(state.lang);
    }
  },
  actions: {

  },

};
