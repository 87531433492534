import api from '@/api'
import UserSearchRequest from '@/model/user/UserSearchRequest';
import UserRequest from '@/model/user/UserRequest';

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    getAllOrganizations(actions) {
      return api.base()
        .post('/organization/get')
        .then(
          response => {
            return response.data.data
            // pagination: { page: response.data.metadata.page, total: response.data.metadata.total},
            // sort: response.data.metadata.sort
          }
        )
    },
    getOrganizationById({commit}, id){
      return api.base()
        .get('/organization/id/' + id)
        .then(
          response => {
            return response.data.data
          }
        )
    },
    updateOrganization({ commit }, { organization, id }) {
      console.log("organization", organization);
      console.log("id", id);
      return api.base()
        .post('/organization/update/'+id, organization)
        .then(
          response => {
            return response.data.data
          }
        )
    }


  },
  }
