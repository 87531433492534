<template>
  <v-select
      v-model="model"
      v-bind:multiple="multiple || selectProps.multiple"
      :options="$store.getters['data/allAffiliates']"
      label="company"
      :reduce="(affiliate) => affiliate.id"
      v-bind="selectProps"
      :clearable="clearable"
  >
  </v-select>
</template>

<script>
export default {
  name: 'AffiliateSelectBox',
  props: {
    value: {
      required: true,
    },
    acronym: {
      default: () => false
    },
    multiple:{
      type: Boolean,
      default: () => false
    },
    clearable:{},
    selectProps:{
      default: () => ({})
    }
  },
  computed:{
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    }
  },
  methods:{
  }
}
</script>

<style scoped>

</style>
