import axios from 'axios'
import {vue} from '@/main.js'


function setInterceptor(instance) {
  instance.interceptors.response.use(
    (response) => {
      // console.log('response', { response, data:response.data })
      return response
    }, (error) => {
      if(error.response == null){
       return  Promise.reject({ errorId: 'SERVER_NOT_RESPONDING' })
      }
      if (error.response.status === 401) {
        vue.$root.$emit('system::loggedOut')
        // alert('You are not authorized')
      }
      if (error.response.data) {
        return Promise.reject(error.response.data)
      }
      return Promise.reject({ error: error.message})
    })
  return instance
}

const api = {
  internal() {
    return axios.create()
  },
  auth() {
    const headers = {
      'Content-Type': 'application/json',
      // Accept: '*!/!*',
    }
    return setInterceptor(axios.create({
      withCredentials: false,
      baseURL: window.config.apiUrl,
      timeout: 100000,
      headers,
    }))
  },
  base() {
    const headers = {
      'Content-Type': 'application/json',
      // Accept: '*!/!*',
    }
    return setInterceptor(axios.create({
      withCredentials: true,
      baseURL: window.config.apiUrl,
      timeout: 100000,
      headers,
    }))
  },


  downloadApi() {
    let headers = {
      'Content-Type': 'application/json',
      'Accept' : '*/*',
      "Authorization": `Bearer ${localStorage.Bearer || ''}`,
    };
    if (localStorage.base != null) {
      headers['org'] = localStorage.base
    }
    let instance = axios.create({
      baseURL: window.config.apiUrl,
      responseType:  'arraybuffer',
      timeout: 100000,
      headers
    })
    return instance;
  },
}

export default api
