<template>
  <b-modal no-fade v-model="dialog.show" size="lg" id="modal-new-account"
           :hide-footer="true" centered @show="closeAllPopovers"
           no-close-on-backdrop
  >
    <template #modal-title>
      {{$t('account.newAccount')}}
    </template>
    <div>
      <b-row v-if="dialog.show && !loading.account ">
        <b-col cols="6">
          <label>{{ $t('columns.group') }}</label>
          <v-select class="small" v-model="account.group"
                    :options="groups" v-bind:loading="loading.groups"  :state="error ? false : null"
                    :reduce="option => option.id" label="label">
          </v-select>
        </b-col>
        <b-col cols="6">
          <label>{{ $t('columns.currency') }}</label>
          <v-select class="small" v-model="account.currency"
                    :options="['EUR', 'USD', 'GBP']">
          </v-select>
        </b-col>
        <!--enabled column-->
        <b-col class="mt-1" cols="6">
          <b-form-checkbox inline v-model="account.active" >{{ $t('columns.active') }}</b-form-checkbox>
          <b-form-checkbox inline v-model="account.sendMail" >{{ $t('operations.sendMail') }}</b-form-checkbox>
        </b-col>
        <b-col class="mt-1" cols="12">
          <b-button variant="outline-primary"  block size="sm" v-bind:disabled="isInvalidForm || busy" @click="create">
            {{ $t('operations.create') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'
import mixinPopover from '@/components/mixin/mixinPopover'
import AccountRequest from '@/model/account/AccountRequest'
import { mapActions } from 'vuex'
import mixinNotifications from '@/components/mixin/mixinNotifications'

export default {
  name: 'newAccountModal',
  mixins: [mixinBase, mixinNotifications, mixinPopover],
  data: () => ({
    dialog: { show: false, },
    account:{},
    organizationId: null,
    groups: null,
    loading: { groups: true, account: true },
    busy: false,
    error: null,
  }),
  computed: {
    isInvalidForm(){
      return !this.account.group || !this.account.currency
    }
  },
  methods: {
    ...mapActions('account', ['createAccount']),
    ...mapActions('tradingGroup', ['getGroupsBasic']),
    showModal(clientId, organizationId) {
      this.dialog.show = true
      this.error = null
      this.groups = []
      this.loading.groups = true
      this.loading.account = true
      this.organizationId = organizationId
      this.account = AccountRequest({clientId: clientId, active: true})
      this.refreshGroups()
      this.loading.account = false
    },
    refreshGroups(){
      let $this = this
      this.getGroupsBasic({filter: {organizationIds: [this.organizationId]}, pagination: {page: 1, size: 1000}})
          .then(groups => {
            $this.groups = groups.map(group => {
              group.label = group.description + (group.demo ? ' (Demo)' : '')
              return group
            });
            $this.loading.groups = false
          }, error => {
            $this.showServerErrorNotification('fetchDataError', 'tradingGroup', error)
          })
    },
    create(){
      let $this = this
      this.busy = true
      this.createAccount(this.account)
          .then((account) => {
            $this.showSuccessNotification('createSuccess', 'account')
            $this.dialog.show = false
            $this.$emit('created',account)
          }, error => {
            $this.error = error
            $this.showServerErrorNotification('createDataError', 'account', error)
          }).finally(() => {$this.busy = true})
    }
  }
}
</script>

<style scoped>

</style>
