<template>
  <v-select
      v-bind:multiple="multiple || selectProps.multiple"
      v-model="model"
      :options="options"
      label="name"
      :reduce="(bu) => bu.id"
      v-bind="selectProps"
      v-bind:readonly="readonly"
      v-bind:disabled="disabled"
  >
    <template v-slot:option="option">
      <span :class="option.type"></span>
      {{option.name +' - '+ option.type }}
    </template>
 </v-select>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmptyList } from '@/model/Utils'

export default {
  name: 'BusinessUnitSelectBox',
  props: {
    value: {
      required: true,
      multiple:{
        default:()=>false
      }
    },
    selectedOrganization: {
      required: false,
      default: ()=> []
    },
    selectedDepartment: {
      required: false,
      default: ()=> []
    },
    selectProps:{
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    clearable:{
      type: Boolean,
      default: () => false
    },
    multiple:{
      type: Boolean,
      default: () => false
    },
  },
  watch:{
    selectedOrganization(){
      this.validateFilter();
    },
    selectedDepartment(){
      this.validateFilter();
    }
  },
  computed:{
    ...mapGetters('data', ['allBusinessUnits', 'getBusinessUnitById']),
    options(){
      let options = this.allBusinessUnits;
      if(!isEmptyList(this.selectedOrganization)){
        options =  options.filter((bu) => {
          return this.selectedOrganization.includes(bu.orgId);
        });
      }
      if(!isEmptyList(this.selectedDepartment)){
        options =  options.filter((bu) => {
          return this.selectedDepartment.includes(bu.type);
        });
      }
      return options;
    },
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    },
    _selectProps(){
      let props = {...this.$props, ...this.selectProps}
      delete props.value
      delete props.options
      delete props.label
      delete props.reduce
      return props
    }
  },
  methods:{
    getFullDesctiption(bu){
      return `${bu.name} -  ${bu.type}`
    },
    validateFilter(){
      console.log('validateFilter')
      if(isEmptyList(this.value)){
        return
      }
      let filter = this.value.map(buid=>this.getBusinessUnitById(buid))
      if(!isEmptyList(this.selectedOrganization)) {
        filter = filter.filter((bu) => {
          return this.selectedOrganization.includes(bu.orgId)
        })
      }
      if(!isEmptyList(this.selectedDepartment)) {
        filter = filter.filter((bu) => {
          return this.selectedDepartment.includes(bu.type)
        })
      }
      if (isEmptyList(filter)) {
        this.$emit('input', [])
      }else {
        this.$emit('input', filter.map(bu=>bu.id))
      }
    }
  }
}
</script>

<style scoped>

</style>
