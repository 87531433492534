import api from '@/api';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return api.base()
        .get('/email/data')
        .then(
          response => {
            commit('updateData', response.data.data);
          }
        );
    },

    sendManualMail({ commit }, {
      email,
      clientId
    }) {
      return api.base()
        .post('/email/send/' + clientId, email)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    sendTemplateMail({ commit }, {
      event,
      clientId
    }) {
      return api.base()
        .post('/email/byTemplate/'+event+'send/' + clientId)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

  }

};
