import api from '@/api'
import CalendarRequest from '@/model/calendar/CalendarRequest'
import CalendarSearchRequest from '@/model/calendar/CalendarSearchRequest'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCalendars(actions, request) {
      return api.base()
        .post('/calendar/get/all', CalendarSearchRequest(request))
        .then(
          response => {
            return response.data.data
          },
          err => Promise.reject(err)
        )
    },
    getEventsByClient(actions, clientId) {
      return api.base()
        .post('/calendar/get/client/'+clientId, )
        .then(
          response => {
            return response.data.data
          },
          err => Promise.reject(err)
        )
    },
    getExternalCalendars(actions, request) {
      return Promise.resolve([])
    },
    getCalendarById(actions, id) {
      return api.base()
        .get('/calendar/id/' + id)
        .then(
          response => {
            return response.data.data
          },
          err => Promise.reject(err)
        )
    },
    addCalendar(actions, calendar) {
      return api.base()
        .post('/calendar/add', CalendarRequest(calendar))
        .then(
          response => {
            return response.data.data
          },
          err => Promise.reject(err)
        )
    },
    updateCalendar(actions, {
      calendar,
      id
    }) {
      return api.base()
        .post('/calendar/update/' + id, CalendarRequest(calendar))
        .then(
          response => {
            return response.data.data
          },
          err => Promise.reject(err)
        )
    },
    updateUserStatus(actions, {
      eventId,
      userId,
      status
    }) {
      return api.base()
        .post(`/calendar/update/${eventId}/user/${userId}`, null, { params: { status: status } })
        .then(
          response => {
            return response.data.data
          },
          err => Promise.reject(err)
        )
    },
    cancelEvent(actions, id) {
      return api.base()
        .delete('/calendar/cancel/' + id)
        .then(
          response => {
            return response.data.data
          },
          err => Promise.reject(err)
        )
    }
  }
}
