export function safeParseFloat(value) {
  if (value === undefined || value === null || isNaN(value)) {
    return null
  }
  return parseFloat(value)
}

export function safeParseInt(value) {
  if (value === undefined || value === null || isNaN(value)) {
    return null
  }
  return parseInt(value)
}

export function toFixed(value, precision) {
  if (value === undefined || value === null || isNaN(value)) {
    return null
  }
  if (precision === undefined || precision === null || isNaN(precision)) {
    precision = 2
  }
  if (!(value instanceof Number)) {
    value = parseFloat(value)
  }
  return parseFloat(value.toFixed(precision))
}
