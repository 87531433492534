import Vue from 'vue';

import VueMoment from 'vue-moment';
import Moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/he';
import i18n from './i18n';

Vue.use(VueMoment, { moment: Moment });
Vue.filter('moment', function (value, format) {
  if (value === null || value === undefined || format === undefined) {
    return '';
  }
  if (format === 'from') {
    return Moment(value)
      .fromNow();
  }
  if (format === 'diff') {
    return Moment()
      .diff(Moment(value), 'minutes');
  }
  return Moment(value)
    .format(format);
});

Vue.filter('momentDate', function (value) {
  if (value == null) {
    return '';
  }
  return Moment(value, 'YYYY-MM-DD')
    .format(i18n.t('timeFormat.date.format'));
});

Vue.filter('momentDateTime', function (value) {
  if (value == null) {
    return '';
  }
  return Moment(value, 'YYYY-MM-DD HH:mm')
    .format(i18n.t('timeFormat.dateTime.format'));
});
