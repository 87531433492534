import api from '@/api';
import ClientStatusSearchRequest from '@/model/clientStatus/ClientStatusSearchRequest';
import ClientRequest from '@/model/client/ClientRequest';
import ClientStatusRequest from '@/model/clientStatus/ClientStatusRequest';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return Promise.resolve();

    },
    getAllClientStatus(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/clientStatus/get/all',
          ClientStatusSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getClientStatusById({ commit }, id) {
      return api.base()
        .get('/clientStatus/id/' + id)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    updateClientStatus({ commit }, {
      clientStatus,
      id
    }) {
      return api.base()
        .post('/clientStatus/update/' + id, ClientStatusRequest(clientStatus))
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    createClientStatus({ commit }, { clientStatus }) {
      return api.base()
        .post('/clientStatus/add', ClientStatusRequest(clientStatus))
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    handleEvent({ commit }, { id, event }) {
      return api.base()
        .post('/clientStatus/handleEvent/' + id+'/'+event)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

  }

};
