import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    name: other.name || null,
    website: other.website || null,
    address: other.address || null,
    contact: other.contact || null,
    phone: other.phone || null,
    email: other.email || null,
    countryCode: other.countryCode || null,
    cur: other.cur || null,
    type: other.type || null,
    //Search
    search: other.search || null,
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
