<template>
  <div>
    <!--open prices-->
    <b-row class="mt-1">
      <b-col cols="6">
        <b-form-group class="m-0">
          <b-input-group size="sm" :prepend="$t('trade.openPrice')" style="width: 100%">
            <b-input v-model="tradeDetails.openPrice"></b-input>
            <template #append>
              <b-button variant="outline-warning" size="sm" @click="syncPrice('openPrice')" v-b-tooltip
                        :title="$t('operations.sync')"
              >
                <i class="fa-solid fa-sync"></i>
              </b-button>
            </template>
          </b-input-group>
          <template #description>
             <span>
              Price: {{tradeDetails.openPrice}},   Profit: {{ profit.trade | numberFormatter }} {{ $t(`currencies.${account.currency}.symbol`) }}
             </span>
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-input-group size="sm" :prepend="$t('trade.openTime')" style="width: 100%">
          <b-input v-model="tradeDetails.openTime"></b-input>
        </b-input-group>
      </b-col>
    </b-row>
    <!--sl/tp-->
    <b-row class="mt-1">
      <!--sl-->
      <b-col v-bind:cols="isFutureTrade ? 4 : 6">
        <b-form-group class="m-0">
          <template #description>
             <span v-if="profit.sl != null && stopPricesValidation.sl">
               {{$t('trade.profit')}}: {{ profit.sl }} {{ $t(`currencies.${account.currency}.symbol`) }} ({{ profit.slPercentage }}%)
             </span>
          </template>
          <b-input-group size="sm" prepend="sl" style="width: 100%">
            <b-form-input :state="profit.validSl ? null: false" v-model="tradeDetails.sl"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--tp-->
      <b-col v-bind:cols="isFutureTrade ? 4 : 6">
        <b-form-group class="m-0">
          <template #description>
                     <span v-if="profit.tp != null && stopPricesValidation.tp">
                       {{$t('trade.profit')}}: {{ profit.tp }} {{ $t(`currencies.${account.currency}.symbol`) }} ({{ profit.tpPercentage }}%)
                     </span>
          </template>
          <b-input-group size="sm" prepend="tp" style="width: 100%">
            <b-form-input :state="profit.validTp ? null: false" v-model="tradeDetails.tp"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4" class="m-0" v-if="isFutureTrade">
        <b-form-group class="m-0">
          <b-input-group size="sm" prepend="price" style="width: 100%">
            <b-form-input v-model="tradeDetails.openAtPrice"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!--Closed prices-->
    <b-row class="mt-1" v-if="trade.isOpen !== true">
      <b-col cols="6">
        <b-form-group class="m-0">
          <b-input-group size="sm" :prepend="$t('trade.closedPrice')" style="width: 100%">
            <b-input v-model="tradeDetails.closePrice"></b-input>
            <template #append>
              <b-button variant="outline-warning" size="sm" @click="syncPrice('closePrice')" v-b-tooltip
                        :title="$t('operations.sync')"
              >
                <i class="fa-solid fa-sync"></i>
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-input-group size="sm" :prepend="$t('trade.closeTime')" style="width: 100%">
          <b-input v-model="tradeDetails.closeTime"></b-input>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { TradeCmdEnum } from '@/model/trade/TradeCmdEnum'
import { calculateProfit, simulateCalculationProfit } from '@/model/TradingCalculation'
import { mapGetters } from 'vuex'

export default {
  name: 'tradeOrderEditPricePart',
  props:{
    value:{},
    trade:{},
    lastTick:{},
    symbols:{},
    stopPricesValidation:{},
    account:{},
  },
  watch:{
    lastTick:{
      handler(){
        this.recalculateProfit()
      },
      immediate:true
    },
    ['value']:{
      handler(){
        this.recalculateProfit()
      },
      deep:true,
      immediate:true
    },
  },
  data:()=>({
    profit:{},
  }),
  computed:{
    ...mapGetters('symbol', ['tickersMap']),
    tradeDetails:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    },
    commands() {
      return Object.keys(TradeCmdEnum);
    },
    isFutureTrade(){
      return this.tradeDetails.command != null && this.tradeDetails.command !== TradeCmdEnum.BUY && this.tradeDetails.command !== TradeCmdEnum.SELL
    },
  },
  methods:{
    syncPrice(){
      this.tradeDetails.openPrice = this.lastTick.midPrice;
      this.recalculateProfit()
    },
    recalculateProfit() {

      let profit = {validTp: true, validSl: true }
      let symbol = this.symbols.find(symbol => symbol.id === this.tradeDetails.symbolId)
      this.tradeDetails.openPrice = isNaN(this.tradeDetails.openPrice) ? 0 : parseFloat(this.tradeDetails.openPrice);
      let currentPrice = this.tradeDetails.openPrice
      if(symbol == null){
        return this.profit = profit
      }
      if(this.tradeDetails.tp != null && this.tradeDetails.tp !== "" && !isNaN(this.tradeDetails.tp)) {
        profit.tp = simulateCalculationProfit(this.tickersMap, this.lastTick, symbol, this.tradeDetails.command, this.tradeDetails.lots, this.account.currency, currentPrice, this.tradeDetails.tp)
        profit.tpPercentage = (((this.tradeDetails.tp / currentPrice) - 1 ) * 100).toFixed(2)
        profit.validTp = profit.tp > 0
      }
      if(this.tradeDetails.sl != null && this.tradeDetails.sl !== "" && !isNaN(this.tradeDetails.sl)) {
        profit.sl = simulateCalculationProfit(this.tickersMap, this.lastTick, symbol, this.tradeDetails.command, this.tradeDetails.lots, this.account.currency, currentPrice, this.tradeDetails.sl)
        profit.slPercentage = (((this.tradeDetails.sl / currentPrice) - 1) * 100).toFixed(2)
        profit.validSl = profit.sl < 0
      }
      if(profit.tp != null){
        profit.tp = profit.tp.toFixed(2)
      }
      if(profit.sl != null){
        profit.sl = profit.sl.toFixed(2)
      }
      profit.trade = calculateProfit(this.lastTick, symbol, { ...this.tradeDetails });
      return this.profit = profit
    },
  }
}
</script>


<style scoped>

</style>
