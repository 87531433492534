import { logUnusedKeys } from '@/model/Utils';

export default function (other) {
  other = other || {};
  let result = {
    range: {
      from: other.range?.from || null,
      until: other.range?.until || null,
    },
    orgIds: other.orgIds || [],
    buIds: other.buIds || [],
    buTypes: other.buTypes || [],
    affiliateId: other.affiliateId || null,
    banner: other.banner || null,
    campaign: other.campaign || null,
  };
  logUnusedKeys('DashboardSearchRequest', result, other);
  return result;
}
