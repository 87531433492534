import Vue from 'vue'
import Vuex from 'vuex'
// App Modules
import app from './theme/app'
import appConfig from './theme/app-config'
import verticalMenu from './theme/vertical-menu'
//System Modules
import translator from '@/store/system/translator'
import user  from '@/store/system/user'
import data  from '@/store/system/data'
import users  from '@/store/system/users'
import clients  from '@/store/system/clients'
import account  from '@/store/system/account'
import notes  from '@/store/system/notes'
import affiliate from '@/store/system/affiliate';
import smtp from '@/store/system/smtp';
import mailTemplate from '@/store/system/mailTemplate';
import trade from '@/store/system/trade';
import monetary from '@/store/system/monetary';
import kyc from '@/store/system/kyc';
import calendar from '@/store/system/calendar';
import notification from '@/store/system/notification';
import document from '@/store/system/document';
import symbol from '@/store/system/symbol';
import symbolGroup from '@/store/system/symbolGroup';
import tradingGroup from '@/store/system/tradingGroup'
import dashboard from '@/store/system/dashboard';
import organizations from '@/store/system/organizations';
import email from '@/store/system/email';
import role from '@/store/system/Role'
import businessesUnits from '@/store/system/businessesUnits';
import bugReports from '@/store/system/bugReports';
import clientStatus from '@/store/system/clientStatus';
import tracker from '@/store/system/tracker';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app, appConfig, verticalMenu, notes, translator,
    user, data, users, clients,affiliate,smtp,mailTemplate,email,
     trade,account,monetary,kyc, calendar, notification,document,symbol,symbolGroup,dashboard,
    tradingGroup,role, organizations, businessesUnits,clientStatus,tracker,bugReports
  },
  strict: process.env.DEV,
})
