import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    ids: other.ids || [],
    docNum: other.docNum || null,
    firstName: other.firstName || null,
    lastName: other.lastName || null,
    account: other.account || null,
    email: other.email || null,
    phone: other.phone || null,
    orgIds: other.orgIds || [],
    buIds: other.buIds || [],
    buTypes: other.buTypes || [],
    presaleUserIds: other.presaleUserIds || [],
    representativeUserIds: other.representativeUserIds || [],
    retentionUserIds: other.retentionUserIds || [],
    statusList: other.statusList || [],
    country: other.country || [],
    city: other.city || null,
    lang: other.lang || [],
    comment: other.comment || null,
    creationTime: other.creationTime || {start: null, end: null},
    ftdTimeStart: other.ftdTimeStart || null,
    ftdTimeEnd: other.ftdTimeEnd || null,
    lastAssignationTime: other.lastAssignationTime || null,
    online: other.online || null,
    marginCall: other.marginCall || null,
    //Affiliates

    banner: other.banner || null,
    campaign: other.campaign || null,
    tag: other.tag || null,
    affiliateId: other.affiliateId || [],

    //Search
    search: other.search || null,
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
