import Vue from 'vue'
import numberFormatter from 'number-formatter'
import i18n from './i18n'

Vue.use(numberFormatter)
//https://www.npmjs.com/package/number-formatter
Vue.filter('numberFormatter', function (value, format) {
  if (value == null) {
    return ''
  }
  return numberFormatter(format || i18n.t('number.format'), value)
});
