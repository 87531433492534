import api from '@/api';
import SymbolGroupSearchRequest from '@/model/symbolGroup/SymbolGroupSearchRequest';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return Promise.resolve();
    /*   return api.base()
        .get('/symbolGroup/data')
        .then(
          response => {
            commit('updateData', response.data.data);
          }
        ); */
    },
    getAllSymbolGroup(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/trading/symbolGroup/get/all',
          SymbolGroupSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getSymbolGroupById({ commit }, id) {
      console.log("getSymbolGroupById", id)
      return api.base()
        .get('/trading/symbolGroup/get/' + id)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateSymbolGroup({ commit }, {
      symbolGroup,
      id
    }) {
      return api.base()
        .put('/trading/symbolGroup/update/' + id, symbolGroup)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    createSymbolGroup({ commit }, { symbolGroup }) {
      return api.base()
        .post('/trading/symbolGroup/add', symbolGroup)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    handleEvent({ commit }, { id, event }) {
      return api.base()
        .post('/trading/symbolGroup/handleEvent/' + id+'/'+event)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

  }

};
