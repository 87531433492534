import api from '@/api';
import DashboardSearchRequest from '@/model/Dashboard/DashboardSearchRequest';
import AffiliateDashboardSearchRequest from '@/model/Dashboard/AffiliateDashboardSearchRequest';
import ClientSearchRequest from '@/model/client/ClientSearchRequest';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSalesDashboard(actions, filter) {
      return api.base()
        .post('/dashboard/sales', DashboardSearchRequest(filter))
        .then(
          response => {
            return response.data.data;
          },
          err => Promise.reject(err)
        );
    },
    getAffiliateDashboard(actions, { pagination, sort, filter }) {
      return api.base()
        .post('/dashboard/affiliate',  AffiliateDashboardSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(
          response => ({
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              pages: response.data.metadata.pages,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },

  }
};
