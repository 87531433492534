import api from '@/api'
import {vue} from '@/main.js'

export default {
  namespaced: true,
  state: {
    connection: null,
    connected: false,
    unreaded: 0,
    subscription:{
      tickers: false,
      trades: [],
      accounts: [],
    }
  },
  getters: {
    isConnected(state) {
      return state.connected === true
    },
    totalOfUnreaded(state) {
      return state.unreaded
    }
  },
  mutations: {
    setConnection(state, connection) {
      if (connection != null) {
        state.connection = connection
        state.connected = true
      } else {
        state.connection = null
        state.connected = false
      }
    },
    setUnreaded(state, unreaded) {
      if(unreaded == null){
        return
      }
      state.unreaded = unreaded
    },
    addUnreaded(state, unreaded) {
      state.unreaded = state.unreaded + unreaded
    },
    subscribeToTickers(state, toggle) {
      if (toggle) {
        state.subscription.tickers = true
      } else {
        state.subscription.tickers = false
      }
    },
    subscribeToTrades(state, { toggle, accountId }) {
        if (toggle) {
            state.subscription.trades.push(accountId)
        } else {
            state.subscription.trades = state.subscription.trades.filter(id => id !== accountId)
        }
    },
    subscribeToAccount(state, { toggle, accountId }) {
        if (toggle) {
            state.subscription.accounts.push(accountId)
        } else {
            state.subscription.accounts = state.subscription.accounts.filter(id => id !== accountId)
        }
    },
  },
  actions: {
    getToken() {
      return api.base()
        .get('/user/notification/token')
        .then(response => response.data.data)
    },
    async connect({ dispatch, commit, rootGetters, state }) {
      if(state.connected){
        return
      }
      try {
        let token = await dispatch('getToken')
        let ws = new WebSocket(window.config.apiUrl.replaceAll('http', 'ws') + '/notifications?token=' + token)
        ws.onopen = () => commit('setConnection', ws)
        ws.onclose = () => dispatch('onDisconnect')
        ws.onmessage = data => dispatch('onMessage', data)
      } catch (e) {
        setTimeout(() => dispatch('connect'), 5000)
      }
    },
    onDisconnect({ dispatch, commit }) {
      commit('setConnection', null)
      setTimeout(() => dispatch('connect'), 5000)
    },
    onMessage({ dispatch, commit, state, rootGetters }, data) {
      let message = JSON.parse(data.data)
      if (message.error) {
        /* dispatch('notifications/error', { title: 'Chat', message: message.message }, { root: true });*/
        return
      }
      switch (message.type) {
        case 'CONNECTION':
          dispatch("resubscribeToListeners")
          return
        case 'NOTIFICATION':
          dispatch('onNewNotification', message.notification)
          return
        case "TICKER_UPDATE":
          commit('symbol/tickerUpdate', message.body, {root: true})
          return
        case "ACCOUNT_UPDATE":
          vue.$root.$emit('system::account::update', message.body)
          return
        case 'TRADE_UPDATE':
          vue.$root.$emit('system::trade::update', message.body)
          return

      }
    },
    resubscribeToListeners({ state,dispatch }){
      if (state.subscription.tickers === true) {
        dispatch('subscribeToTickers', true)
      }
      state.subscription.trades.forEach(accountId => dispatch('subscribeToTrades', {toggle: true, accountId}))
      state.subscription.accounts.forEach(accountId => dispatch('subscribeToAccount', {toggle: true, accountId}))
    },
    onNewNotification({  commit, getters, rootGetters }, notification) {
      if(notification.content !== 'OBJECT_UPDATE'){
        commit('addUnreaded', 1);
        vue.$root.$emit('system::notification::notify', notification)
      }else {
        vue.$root.$emit(`system::object_update::${(notification.source || '').toLowerCase()}`, notification.params)
      }
    },
    getNotifications(actions, pagination) {
      return api.base()
        .post('/user/notification/all', { pagination })
        .then(
          response => {
            actions.commit('setUnreaded', response.data.metadata.unreaded)
            return ({
              data: response.data.data,
              pagination: {
                page: response.data.metadata.page,
                pages: response.data.metadata.pages,
                total: response.data.metadata.total
              },
              sort: response.data.metadata.sort
            })
          },
          err => ({ error: err.data }),
        )
    },
    setAllNotificationsAsRead({ commit }) {
        return api.base()
            .put('/user/notification/all')
            .then(
            response => {
                commit('setUnreaded', 0)
                return response.data.data
            },
            err => ({ error: err.data }),
            )
    },
    subscribeToTickers({state, commit}, toggle){
      commit('subscribeToTickers', toggle)
      if (state.connection == null) {
        return
      }
      state.connection.send(JSON.stringify({
        message: toggle ? 'TICKER_SUBSCRIBE' : 'TICKER_UNSUBSCRIBE',
      }))

    },
    subscribeToTrades({state, commit}, { toggle, accountId }){
      if (state.connection == null) {
        return
      }
      state.connection.send(JSON.stringify({
        message: toggle ? 'TRADE_SUBSCRIBE' : 'TRADE_UNSUBSCRIBE',
        accountId: accountId,
      }))
    },
    subscribeToAccount({state, commit}, { toggle, accountId }){
      if (state.connection == null) {
        return
      }
      state.connection.send(JSON.stringify({
        message: toggle ? 'ACCOUNT_SUBSCRIBE' : 'ACCOUNT_UNSUBSCRIBE',
        accountId: accountId,
      }))
    },
  }
}
