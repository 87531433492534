import api from '@/api';

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    addNewReport({ commit }, { bugReport }) {
      console.log('report to add', bugReport.bodyReport);
      let report = {
        title: bugReport.titleReport,
        body: bugReport.bodyReport,
        reportTyp: bugReport.type
      };

      return api.base()
        .post('/support/add', report)
        .then(
          response => {
            console.log(response.data);
            return response.data.data;
          }
        );
    },
    addNewReport_withImage({ commit }, {
      bugReport,
      file,
      data
    }) {
      let report = {
        title: bugReport.titleReport,
        body: bugReport.bodyReport,
        reportTyp: bugReport.type
      };
      return api.base()
        .post('/support/add-with-image?title=' + String(report.title) + '&body=' + String(report.body) +
          '&reportType=' + String(report.reportTyp) + '/', data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(
          response => {
            console.log(response.data);
            return response.data.data;
          }
        );
    }

  },
};
