import api from '@/api'
import SmtpRequest from '@/model/smtp/SmtpRequest'
import SmtpSearchRequest from '@/model/smtp/SmtpSearchRequest'
import AffiliateRequest from '@/model/affiliate/AffiliateRequest';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return api.base()
        .get('/smtp/data')
        .then(
          response => {
            commit('updateData', response.data.data);
          }
        );
    },
    getAllSmtps(actions, {filter,pagination,sort}) {
      return api.base()
        .post('/smtp/get/all',
          SmtpSearchRequest( { ...(filter || {}), pagination, sort, })
        )
        .then(response => ({
            data: response.data.data,
            pagination: { page: response.data.metadata.page, total: response.data.metadata.total},
            sort: response.data.metadata.sort
          })
        )
    },
    getSmtpById({ commit }, id) {
      return api.base()
        .get('/smtp/id/' + id)
        .then(
          response => {
            return response.data.data
          }
        )
    },

    createSmtp({ commit }, { smtp }) {
      return api.base()
        .post('/smtp/add', SmtpRequest(smtp))
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateSmtp({ commit }, { smtp, id }) {
      return api.base()
        .post('/smtp/update/'+id, SmtpRequest(smtp))
        .then(
          response => {
            return response.data.data
          }
        )
    }
  },
}
