import api from '@/api';
import ClientRequest from '@/model/client/ClientRequest';
import ClientSearchRequest from '@/model/client/ClientSearchRequest';

export default {
  namespaced: true,
  state: {
    allStatus: []
  },
  getters: {
    allStatuses: state => state.allStatus,
    statusIds: state => state.allStatus,
    statusById: state => id => state.allStatus.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
      state.allStatus = data;
    }
  },
  actions: {
    refreshData({ commit }) {
      return api.base()
        .get('/clients/data')
        .then(
          response => {
            commit('updateData', response.data.data);
          }
        );
    },
    getAllClients(actions, {
      filter,
      pagination,
      sort
    }) {
      // console.log('getAllClients', { filter, pagination, sort })
      return api.base()
        .post('/clients/get/all',
          ClientSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(
          response => ({
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              pages: response.data.metadata.pages,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getAllClientsSearch(actions, { filter }) {

      return api.base()
        .post('/clients/get/all', filter)
        .then(
          response => ({
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              pages: response.data.metadata.pages,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getClientById({ commit }, id) {
      return api.base()
        .get('/clients/id/' + id)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    duplicateClient({ commit }, {
      id,
      newEmail
    }) {
      console.log('duplicateClient', id, newEmail);
      const encodedEmail = encodeURIComponent(newEmail);
      return api.base()
        .post(`/clients/copy?id=${id}&email=${encodedEmail}`)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    promoteClient({ commit }, {
      id,
      userId,
      branchId
    }) {
      return api.base()
        .post(`/clients/promote?id=${id}&userId=${userId}&branchId=${branchId}`)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    getClientToken({ commit }, id) {
      return api.base()
        .get('/clients/token/' + id)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    handlePasswordEvent({ commit }, {
      clientId,
      password,
      sendEmail
    }) {
      return api.base()
        .post('/clients/passwordEvent/' + clientId, {
          password,
          sendEmail
        })
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateClient({ commit }, {
      client,
      id
    }) {
      return api.base()
        .post('/clients/update/' + id, ClientRequest(client))
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateClientPassword({ commit }, {
      clientId,
      password,
      sendEmail
    }) {
      return api.base()
        .post('/clients/update/password/' + clientId, {
          password,
          sendEmail
        })
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateClientStatus({ commit }, {
      clientId,
      statusId
    }) {
      return api.base()
        .post(`/clients/update/status?clientId=${clientId}&statusId=${statusId}`)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateClientUsers({ commit }, {
      clientId,
      userId,
      userType
    }) {
      return api.base()
        .post(`/clients/update/user?clientId=${clientId}&userId=${userId}&userType=${userType}`)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateStatusOfClient({ commit }, {
      clientsId,
      newStatus
    }) {
      console.log('in the update', clientsId);
      console.log('the new status', newStatus);
      let link = '/clients/update/statusOfClient/' + newStatus.toString();
      return api.base()
        .post(link, clientsId)
        .then(
          response => {
            return response.data;
          }
        );
    },
    updateRepresentativeOfClient({ commit }, {
      clientsId,
      newRepresentative
    }) {
      let link = '/clients/update/representativeOfClient/' + newRepresentative.toString();
      return api.base()
        .post(link, clientsId)
        .then(
          response => {
            return response.data;
          },
          err => {
          }
        );
    },
    updateToRandomRep({ commit }, body) {

      return api.base()
        .post('/clients/update/randomRepresentativeClient', body)
        .then(
          response => {
            return response.data;
          }
        );
    },
    updateBusinessUnitOfClient({ commit }, {
      clientsId,
      newBusiUnit
    }) {

      let link = '/clients/update/businessUnitOfClient/' + newBusiUnit.toString();
      return api.base()
        .post(link, clientsId)
        .then(
          response => {
            return response.data;
          }
        );
    },
    createClient({ commit }, { client }) {
      return api.base()
        .post('/clients/add', ClientRequest(client))
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    exportClientDataExcel(actions, {
      filter,
      pagination,
      sort,
      fields
    }) {
      let request = {
        searchRequest: {
          ...(filter || {}),
          pagination,
          sort
        },
        fields
      };
      return api.base()
        .post('/clients/report/file/download', request, { responseType: 'blob' })
        .then(
          response => {
            console.log('after', response);
            const url = URL.createObjectURL(new Blob([response.data],
              { type: response.headers['content-type'] }
            ));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers['file-name']);
            document.body.appendChild(link);
            link.click();

            return response.data;
          })
        .catch(console.error);

    },
    importClientDataExcel(actions, { formData }) {
      return api.base()
        .post('/api/excel/uploadFileAndInsertData', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(
          response => {
            return response.data.data;
          }
        );
    },
  },
};
