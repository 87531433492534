<template>
  <v-select
      v-model="model"
      :options="options"
      label="name"
      :reduce="(country) => country.code"
      v-bind="selectProps"
  />
</template>

<script>
import { vue } from '@/main'

export default {
  name: 'CountriesSelectBox',
  props: {
    value: {
      required: true,
    },
    selectProps:{
      default: () => ({})
    }
  },
  computed:{
    options:{

      get(){

        return vue.$t(`countryList`)
      }
    },
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    }
  }
}
</script>

<style scoped>

</style>
