import { logUnusedKeys } from '@/model/Utils'

export default function (other) {
  other = other || {}
  let result = {
    from: other.from || null,
    until: other.until || null,
    usersId: other.usersId || [],
  }
  logUnusedKeys('CalendarSearchRequest', result, other)
  return result
}
