import api from '@/api'
import MonetarySearchRequest from '@/model/monetary/MonetarySearchRequest';
import AccountSearchRequest from '@/model/account/AccountSearchRequest';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAccountsByClient({ commit }, clientId) {
      return api.base()
        .get('/account/client/' + clientId)
        .then(
          response => {
            return response.data.data
          }
        )
    },
    getAllAccounts(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/account/get/all',
          AccountSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getAccountDataByLogin({ commit }, account) {
      return api.base()
        .get('/account/data/' + account)
        .then(
          response => {
            return response.data.data
          }
        )
    },
    createAccount({ commit }, account) {
        return api.base()
            .post('/account/add', account)
            .then(
            response => {
                return response.data.data
            }
            )
    },
    setActive({ commit }, { account, active }) {
      return api.base()
        .put('/account/set/active/' + account, null, { params: { active } })
        .then(
          response => {
            return response.data.data
          })
    },
    syncAccount({ commit }, account) {
      return api.base()
        .put('/account/sync/' + account)
        .then(
          response => {
            return response.data.data
          })
    }
  }
}
