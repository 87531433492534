<template>
  <div id="client-events">
    <div v-if="loaded">
      <div style="position: absolute; margin-top: 3px; right: 160px">
        <b-spinner small v-if="loading">{{ $t('operations.loading') }}</b-spinner>
        <b-link v-else><i class="fa-solid fa-rotate-right" @click="loadData"></i></b-link>
      </div>
      <b-button variant="primary" size="sm" class="mb-1" style="float: right" @click="openModal()">
        {{ $t('operations.addNewEvent') }}
      </b-button>
      <h4 class="text-primary text-center text-uppercase">
        <i class="fa-solid fa-calendar"></i>
        {{ $t('tabs.clients.events') }}
      </h4>
      <b-table striped small hover :items="events"
               v-handy-scroll responsive
               :fields="fields"
      >

        <template #cell(actions)="data">

          <b-button size="sm" @click="onDeleteEvent(data.item.id)" variant="danger">{{ $t('operations.delete') }}
          </b-button>
        </template>

        <template #cell(body)="data">
          <div>{{ data.value }}</div>
        </template>


        <template #cell(duration)="data">
          <span style="white-space: nowrap">{{formatDuration(data.value)}}</span>
        </template>

        <template #cell(type)="data">
          <b-badge :variant="$t(`calendarTypes.${data.value}.color`)"> {{ data.value }}
          </b-badge>
        </template>


        <template #cell(start)="data">
          <span style="white-space: nowrap"> {{ data.value | moment($t('timeFormat.dateTime.format')) }}</span>
        </template>
      </b-table>

      <b-modal v-model="showModal" title="New Event" no-close-on-backdrop hide-footer>
        <b-form @submit.prevent="save">

          <!-- Time field -->
          <b-form-group label="Time">
            <b-form-datepicker v-model="newEvent.time.date" :start-date="new Date()" placeholder="Select date"
            ></b-form-datepicker>
            <b-form-timepicker v-model="newEvent.time.time" placeholder="Select time"></b-form-timepicker>
          </b-form-group>

          <!-- Type field -->
          <b-form-group label="Type">
            <v-select
                v-model="newEvent.type"
                :options="types"
                :clearable="false"
                label="label"
                :reduce="(user) => user.name"
            ></v-select>
          </b-form-group>

          <!-- Duration field -->
          <b-form-group label="Duration">
            <v-select
                v-model="newEvent.duration"
                :options="durationOptions"
                :clearable="false"
                label="text"
                :reduce="(user) => user.value"
            ></v-select>
          </b-form-group>

          <!-- Comment field -->
          <b-form-group label="Comment">
            <b-form-textarea v-model="newEvent.body" rows="4" placeholder="Add a comment..."></b-form-textarea>
          </b-form-group>

          <b-button style="float: right" type="submit" variant="primary">{{ $t('operations.save') }}</b-button>
        </b-form>
      </b-modal>

    </div>
    <div v-else-if="error !== ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="text-align: center;height: 100%;padding: 30% 20px 20px;">
        <b-spinner label="Spinning"/>
        {{ $t('operations.loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinValidation from '@/components/mixin/mixinValidation';

export default {
  name: 'ClientEvents',
  props: {
    autoload: {
      default: false,
      type: Boolean
    },
    clientId: String,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      durationOptions: [
        {
          value: 5,
          text: '5 minutes'
        },
        {
          value: 10,
          text: '10 minutes'
        },
        {
          value: 15,
          text: '15 minutes'
        },
        {
          value: 30,
          text: '30 minutes'
        },
        {
          value: 45,
          text: '45 minutes'
        },
        {
          value: 60,
          text: '1 hour'
        },
        {
          value: 120,
          text: '2 hours'
        },
        {
          value: 180,
          text: '3 hours'
        },
        {
          value: 360,
          text: '6 hours'
        }
      ],
      error: '',
      showModal: false,
      newEvent: this.defaultNewEvent(),
      events: [],
      fields: [
        {
          key: 'type',
          label: 'Event'
        },

        {
          key: 'start',
          label: 'Time'
        },
        {
          key: 'duration',
          label: 'Duration'
        },
        {
          key: 'body',
          label: 'Comment'
        },

        {
          key: 'actions',
          label: 'Actions'
        },
      ],
    };
  },
  watch: {
    clientId() {
      this.reset();
      if (this.autoload) {
        this.loadData();
      }
    }
  },
  created() {
    this.loadData();
  },
  mixins: [mixinBase, mixinNotifications, mixinPermissions, mixinValidation],

  computed: {
    types() {
      let types = this.$t('calendarTypes');
      return Object.keys(types)
          .map((key) => {
            return {
              name: key,
              label: this.$t(`calendarTypes.${key}.name`)
            };
          });
    },
  },
  methods: {
    ...mapActions('calendar', ['addCalendar', 'cancelEvent', 'getEventsByClient']),
    formatDuration(minutes) {
      if (minutes < 60) {
        return `${minutes} minutes`;
      } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours} hour${hours > 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} minutes` : ''}`;
      }
    },
    defaultNewEvent() {
      return {
        clientIds: [],
        time: {
          date: null,
          time: null,
        },
        duration: null,
        type: 'CALL',
        body: '',
      };
    },
    openModal() {
      this.newEvent = this.defaultNewEvent();
      this.showModal = true;
    },
    reset() {
      this.loaded = false;
      this.error = '';
      this.newEvent = this.defaultNewEvent();
      this.events = [];
    },
    loadData() {
      if (this.loading) return;
      this.loading = true;
      this.getEventsByClient(this.clientId)
          .then(eventData => {
            this.events = eventData;
            this.loaded = true;
          })
          .catch(e => {
            this.error = e.toString();
          })
          .finally(() => {
            this.loading = false;
          });
    },
    loadEvent(id) {
      this.get({ id })
          .then(event => {
            this.newEvent = event;
            this.showModal = true;
          });
    },
    onDeleteEvent(id) {
      this.cancelEvent(id)
          .then(() => {
            this.loadData();
            this.showSuccessNotification(this.$t('globalMessages.deleteDataSuccess', { system: this.$t('event') }));

          })
          .catch(e => {
            this.error = e.toString();
            this.showErrorNotification(this.$t('globalMessages.deleteDataError', { system: this.$t('event') }), 'Error deleting event');

          });
    },
    save() {
      const timeWithoutSeconds = this.newEvent.time.time.split(':')
          .slice(0, 2)
          .join(':');
      this.newEvent.start = `${this.newEvent.time.date} ${timeWithoutSeconds}`;

      this.newEvent.clientsIds = [this.clientId];
      this.addCalendar(this.newEvent)
          .then(() => {
            this.reset();
            this.loadData();
            this.showModal = false;
            this.showSuccessNotification(this.$t('globalMessages.createDataSuccess', { system: this.$t('event') }));

          })
          .catch(e => {
            this.error = e.toString();
            this.showErrorNotification(this.$t('globalMessages.createDataError', { system: this.$t('event') }), 'Error deleting event');

          });
    },
  }
};
</script>

<style>
#client-events {
  min-height: 400px;
}

.fixed-width-cell {
  width: 100vh; /* Adjust this value according to your desired width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* Optional: This truncates the text with an ellipsis if it overflows */
}
</style>
