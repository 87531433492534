import {toObject} from './Utils'


export function recalculateAllProfits(account, trades, symbols, tickersMap) {
  // console.log('recalculateAllProfits', { account, trades, symbols, tickersMap })

  if (!Array.isArray(trades) || trades.length === 0) return
  if (tickersMap == null) return
  if (!Array.isArray(symbols) || symbols.length === 0) return
  let symbolMap = toObject(symbols, 'id')
  let totalProfit = 0
  trades.forEach(trade => {
    let ticker = tickersMap[trade.symbolId]
    let symbol = symbolMap[trade.symbolId]
    if (ticker == null || ticker.bid == null || ticker.ask == null || symbol == null) {
      console.warn('failed to recalculate for trade', { ticker, symbol, trade })
      trade.notUpdated = true
      return
    }
    let profit = calculateProfit(ticker, symbol, trade);
    trade.baseProfit = parseFloat(profit.toFixed(2));
    profit = convert(trade, tickersMap, symbol.currency, account.currency, profit)
    trade.profit = parseFloat(profit.toFixed(2));
    if(trade.command === 'SELL' || trade.command === 'BUY') {
      totalProfit += trade.profit
    }
  })
  return parseFloat(totalProfit.toFixed(2));
}

export function simulateCalculationProfit(tickersMap, currentTicker, symbol, command, lots, accountCurrency, openPrice, closePrice, convRates) {
  // console.log('simulateCalculationProfit', { tickersMap, currentTicker, symbol, command, lots, accountCurrency, openPrice, closePrice, convRates })
  let currentPrice = openPrice || (command.includes('BUY') ? currentTicker.ask : currentTicker.bid)
  let trade = { openPrice: currentPrice, command, lots }
  let ticker = currentTicker
  if (closePrice != null) {
    ticker = { ...currentTicker, bid: closePrice, ask: closePrice, convRates }
  }
  let profit = calculateProfit(ticker, symbol, trade)
  return convert(trade, tickersMap, symbol.currency, accountCurrency, profit)
}

export function calculateProfit(ticker, symbol, trade) {

  // console.log('calculateProfit', { ticker, symbol, trade })
  let close = 0

  let open = trade.openPrice
  let lots = trade.lots
  let type = symbol.profitMode
  let side = trade.command
  let spread = ((symbol.spreadRate || 0) / (2 * Math.pow(10, symbol.digits)))
  let contract_size = symbol.contractSize

  // console.log('calculateProfit', { ticker, symbol, trade, calculation: {close, open, lots, type, side, spread, contract_size} })
  let profit = 0
  if (type === "PROFIT_CALC_FOREX") {
    if (side.toUpperCase() === 'SELL') {
      trade.closePrice = ticker.ask
      close = ticker.ask + spread
      profit = ((open - close) * (contract_size * lots) / close)
    } else {
      trade.closePrice = ticker.bid
      close = ticker.bid - spread
      profit = ((close - open) * (contract_size * lots) / close)
    }
  } else if (type === "PROFIT_CALC_CFD") {
    if (side.toUpperCase() === 'SELL') {
      trade.closePrice = ticker.ask
      close = ticker.ask + spread
      profit = (open - close) * (contract_size * lots)
    } else {
      trade.closePrice = ticker.bid
      close = ticker.bid - spread
      profit = (close - open) * (contract_size * lots)
    }
  }else if (type === "PROFIT_CALC_FUTURES") {
    lots = lots / (symbol.tickSize * symbol.tickValue)
    if (side.toUpperCase() === 'SELL') {
      trade.closePrice = ticker.ask
      close = ticker.ask + spread
      profit = (open - close) * (symbol.tickValue) / (symbol.tickSize * lots)
    } else {
      trade.closePrice = ticker.bid
      close = ticker.bid - spread
      profit = (close - open) * (symbol.tickValue) / (symbol.tickSize * lots)
    }
  }else {
    console.error('invalid profit mode ' + type)
    return NaN
  }
  // console.log('calculateProfit result', { ticker, symbol, trade, calculation: {close, open, lots, type, side, spread, contract_size, profit} })
  return profit;
}

export function convert(trade, tickersList, from, to, amount) {
  // console.log('calculateRate convert', { trade, tickersList, from, to, amount })
  if (tickersList == null || from === to) {
    return amount
  }
  if(trade != null && trade.convRates != null && trade.convRates.rate != null && trade.convRates.rate > 0) {
    return amount * trade.convRates.rate
  }
  var t = tickersList[from + to]
  if (t != null) {
    return amount * Math.abs((t.ask + t.bid) / 2)
  }

  t = tickersList[to + from]
  if (t != null) {
    return amount / Math.abs((t.ask + t.bid) / 2)
  }

  var total = 0
  t = tickersList[from + 'USD']
  if (t != null) {
    total = amount * Math.abs((t.ask + t.bid) / 2)

    t = tickersList['USD' + to]
    if (t != null) {
      return total * Math.abs((t.ask + t.bid) / 2)
    }

    t = tickersList[to + 'USD']
    if (t != null) {
      return total / Math.abs((t.ask + t.bid) / 2)
    }

  }
  t = tickersList['USD' + from]
  if (t != null) {
    total = amount / Math.abs((t.ask + t.bid) / 2)
    t = tickersList['USD' + to]
    if (t != null) {
      return total * Math.abs((t.ask + t.bid) / 2)
    }

    t = tickersList[to + 'USD']
    if (t != null) {
      return total / Math.abs((t.ask + t.bid) / 2)
    }
  }
  console.error('invalid conversion from ' + from + ' to ' + to)
  return amount
}

export function calculateMargin(symbol, ticker, lots, account) {
  if (symbol == null || lots == null || account == null) {
    // console.log('Failed to calculate margin', { symbol, ticker, lots, account })
    return 0
  }
  // console.log('calculateMargin', { ticker, symbol, lots, account })
  let leverage = symbol.leverage != null && symbol.leverage > 0 ? symbol.leverage : account.defaultLeverage || 1
  switch (symbol.marginMode) {
    case 'MARGIN_CALC_FOREX':
      //Volume in lots * Contract size / Leverage
      return lots * symbol.contractSize / leverage
    case 'MARGIN_CALC_CFD':
      //Volume in lots * Contract size * Open market price
      return lots * symbol.contractSize * ticker.midPrice
    case 'MARGIN_CALC_CFD_INDEX':
      //Volume in lots * Contract size * Open market price * (Tick price / Tick size)
      return lots * symbol.contractSize * ticker.midPrice * (symbol.tickPrice / symbol.tickSize)
    case 'MARGIN_CALC_CFD_LEVERAGE' :
      //Volume in lots * Contract size * Open market price / Leverage
      return (lots * symbol.contractSize * ticker.midPrice) / leverage
    case 'MARGIN_CALC_FUTURES' :
      //Max(Volume in lots * Initial margin, Volume in lots * Maintenance margin)
      return Math.max(lots * symbol.initialMargin, lots * symbol.maintenanceMargin)
  }
  console.error('invalid margin mode ' + symbol.marginMode)
  return 0
}


