<template>
  <v-select
      v-model="model"
      v-bind:multiple="isMultiple"
      :options="$store.getters['data/allOrganizations']"
      label="name"
      :reduce="(org) => org.id"
      v-bind="selectProps"
      :clearable="clearable"
      v-bind:readonly="readonly"
      v-bind:disabled="disabled || !hasMany"
  >
    <template #selected-option="org">
      <div class="text-center" v-if="acronym != null && acronym !== false">
        <b-avatar size="25" class="d-inline-block" v-b-tooltip :title="org.name">{{ initialName(org.name) }}</b-avatar>
      </div>
      <span v-else>{{ org.name }}</span>
    </template>
  </v-select>
</template>

<script>
import { initialName } from '@/model/Utils'

export default {
  name: 'OrganizationSelectBox',
  props: {
    value: {
      required: true,
    },
    acronym: {
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    clearable: {},
    selectProps: {
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value(){
      this.setDefaultOrg()
    }
  },
  created() {
    this.setDefaultOrg()
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isMultiple(){
      return this.multiple || this.selectProps?.multiple
    },
    hasMany(){
      return this.$store.getters['data/allOrganizations'].length > 1
    }
  },
  methods: {
    initialName,
    setDefaultOrg(){
      if(!this.isMultiple && this.value != null){
        return
      }
      if(this.isMultiple && Array.isArray(this.value) && this.value.length > 0){
        return
      }
      let orgs = this.$store.getters['data/allOrganizations'];
      if(orgs.length === 0 || orgs.length > 1){
        return
      }
      if (this.multiple || this.selectProps?.multiple) {
        this.model = [orgs[0].id]
      } else {
        this.model = orgs[0].id
      }
    }
  }
}
</script>

<style scoped>

</style>
