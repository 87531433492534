<template>
<div>
  <div class="text-right">
    <b-dropdown variant="outline-warning" size="sm" class="mx-1" v-b-tooltip :title="$t('operations.sortBy')">
      <template #button-content>
        <i v-if="sortedTradesBy[sortBy].order === 1" class="fa-solid fa-sort-up mr-1"></i>
        <i v-else class="fa-solid fa-sort-down mr-1"></i>
        <b>{{ $t(`trade.${sortedTradesBy[sortBy].objKey}`) }}</b>
      </template>
      <template v-for="sortKey in Object.keys(sortedTradesBy)" >
        <b-dropdown-divider v-if="sortKey === 'none'" v-bind:key="sortKey"></b-dropdown-divider>
        <b-dropdown-item v-else v-bind:key="sortKey" @click="()=> sortBy=sortKey">
          <i v-if="sortedTradesBy[sortKey].order === 1" class="fa-solid fa-sort-up mr-1"></i>
          <i v-else class="fa-solid fa-sort-down mr-1"></i>
          {{ sortedTradesBy[sortKey].objKey }}
        </b-dropdown-item>
      </template>
    </b-dropdown>
    <b-button variant="outline-primary" size="sm" class="mx-1" @click="newTradeRequest">
      <i class="fa-sharp fa-solid fa-plus"></i>
      {{ $t('trade.newTrade') }}
    </b-button>
  </div>
  <!--Open Trades-->
  <perfect-scrollbar class="position-relative" style="max-height: 500px;padding: 5px; ">
    <b-card  class="m-0 trade-card" style="padding-bottom: 15px;" no-body v-for="(trade,index) in sortedTrades" :key="index">
      <b-card-body class="trade-details-container my-0 py-0" style="background-color: rgba(54,97,187,0.12)!important;">
        <b-row class="trade-details" style="padding: 5px 0">
          <b-col style="white-space: nowrap">
            <i class="mr-1" :class="{'fa-solid fa-arrow-trend-up': trade.command === 'BUY', 'fa-solid fa-arrow-trend-down': trade.command === 'SELL'}"></i>
            <b>{{ trade.symbolName }}</b> ({{ trade.order }})
          </b-col>
          <b-col style="white-space: nowrap">
            <span><b>{{$t('trade.time') +': '}}</b> {{ trade.openTime | momentDateTime }} </span>
            <span v-if="!trade.isOpen">- {{ trade.closeTime | momentDateTime }}</span>
          </b-col>
          <b-col style="white-space: nowrap">
            <span ><b>{{$t('trade.lots') +': '}}</b> {{trade.lots }}</span>
          </b-col>

          <b-col style="white-space: nowrap"><b>{{ $t('trade.commission') }}:</b> {{ trade.commission }}</b-col>
          <b-col style="white-space: nowrap"><b>{{ $t('trade.margin') }}:</b> {{ trade.margin | numberFormatter }}</b-col>
          <b-col style="white-space: nowrap"><b>{{ $t('trade.taxes') }}:</b> {{ trade.taxes }}</b-col>
          <b-col cols="12" style="display: none"><b>{{ $t('trade.comment') }}:</b> {{ trade.comment }}</b-col>
          <b-col style="white-space: nowrap">
            <span><b>{{ $t('trade.openPrice') + ': ' }}</b> {{trade.openPrice }}</span> |
            <span ><b>
              {{trade.isOpen ? $t('trade.currentPrice') : $t('trade.closePrice')}}:
            </b>
              {{trade.closePrice}}</span></b-col>
          <b-col style="white-space: nowrap">
            <b-badge v-bind:variant="trade.profit >= 0 ? 'success' : 'danger'">
              <b>{{ $t('trade.profit') + ': ' }}</b> {{ trade.profit | numberFormatter() }} {{$t(`currencies.${account.currency}.symbol`)}}
              <i v-if="trade.notUpdated" v-b-tooltip v-bind:title="`Profit not updated`" class="fa-regular fa-circle-xmark"></i>
            </b-badge>
          </b-col>
        </b-row>
        <b-row cols="2">

          <template v-if="isHistory">
            <b-col cols="8" class="mt-1"></b-col>
            <b-col cols="2" class="mt-1 mb-1">
              <b-button size="sm" block variant="outline-warning" @click="editTradeRequest(trade)">{{ $t('operations.edit') }}</b-button>
            </b-col>
            <b-col cols="2" class="mt-1">
              <b-button size="sm" block variant="outline-danger"
                        v-bind:readonly="loading.closingTrade[trade.orderId] === true"
                        @click="()=>deleteTradeRequest(trade)">
                <i class="fas fa-cog fa-spin" v-if="loading.closingTrade[trade.orderId] === true"></i>
                {{ $t('operations.delete') }}
              </b-button>
            </b-col>
          </template>
          <template v-else >
            <b-col cols="6">
            </b-col>
            <b-col cols="2" class="mt-1 mb-1">
              <b-button size="sm" block variant="outline-warning" @click="editTradeRequest(trade)">{{ $t('operations.edit') }}</b-button>
            </b-col>
            <b-col cols="2" class="mt-1">
              <b-button size="sm" block variant="outline-danger"
                        v-bind:readonly="loading.closingTrade[trade.orderId] === true"
                        @click="()=>deleteTradeRequest(trade)">
                <i class="fas fa-cog fa-spin" v-if="loading.closingTrade[trade.orderId] === true"></i>
                {{ $t('operations.delete') }}
              </b-button>
            </b-col>
            <b-col cols="2" class="mt-1">
              <b-button size="sm" block variant="outline-success"
                        v-bind:readonly="loading.closingTrade[trade.orderId] === true"
                        @click="()=>closeTradeRequest(trade)">
                <i class="fas fa-cog fa-spin" v-if="loading.closingTrade[trade.orderId] === true"></i>
                {{ $t('operations.close') }}
              </b-button>
            </b-col>
          </template>
        </b-row>
        <!--          {{trade}}-->
      </b-card-body>
    </b-card>
  </perfect-scrollbar>
</div>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'

export default {
  name: 'TradeDetailsTab',
  props:['trades','account', 'isHistory'],
  mixins:[mixinBase],
  data(){
    return {
      sortBy: 'orderId-asc',
      loading:{
        closingTrade:{}
      }
    }
  },
  computed:{
    sortedTradesBy(){
      return {
        'orderId-asc':   { objKey: 'orderId',    order: 1 ,   key:'orderId-asc'   },
        'openTime-asc':  { objKey: 'openTime',   order: 1 ,   key:'openTime-asc'  },
        'symbol-asc':    { objKey: 'symbol',     order: 1 ,   key:'symbol-asc'    },
        'profit-asc':    { objKey: 'profit',     order: 1 ,   key:'profit-asc'    },
        'none':{},
        'orderId-desc':  { objKey: 'orderId',    order: -1 ,  key:'orderId-desc'  },
        'openTime-desc': { objKey: 'openTime',   order: -1 ,  key:'openTime-desc' },
        'symbol-desc':   { objKey: 'symbol',     order: -1 ,  key:'symbol-desc'   },
        'profit-desc':   { objKey: 'profit',     order: -1 ,  key:'profit-desc'   },
      }
    },
    sortedTrades() {
      let sort = this.sortedTradesBy[this.sortBy]
      return (this.trades || []).sort((a, b) => {

        if (a[sort.objKey] < b[sort.objKey]) {
          return (-1 * sort.order)
        }
        if (a[sort.objKey] > b[sort.objKey]) {
          return (1 * sort.order)
        }
        return 0
      })
    },
  },
  methods:{
    newTradeRequest(){
      this.$emit('newTradeRequest')
    },
    editTradeRequest(trade){
      let $this = this;
      // setTimeout(()=>this.$refs.closedTradesTab.$el.scrollTop = 0, 100)
      setTimeout(()=>$this.$emit('editTradeRequest',trade), 100)

    },
    closeTradeRequest(trade){
      let $this = this
      this.loading.closingTrade[trade.order] = false
      this.$emit('closeTradeRequest', { trade, callback: () => $this.loading.closingTrade[trade.order] = false })
    },
    deleteTradeRequest(trade){
      let $this = this
      this.showPrompt(this.$t('trade.deleteTrade'), this.$t('trade.deleteTradeConfirmation', {symbol:trade.symbolId, orderId:trade.order}), () => {
        $this.loading.closingTrade[trade.order] = true
        $this.$emit('deleteTradeRequest', { trade, callback: () => $this.loading.closingTrade[trade.order] = false })
      })
      this.loading.closingTrade[trade.order] = false
      // this.$emit('closeTradeRequest', { trade, callback: () => $this.loading.closingTrade[trade.orderId] = false })
    },
  }
}
</script>

<style lang="scss">
.trade-card {
  //border: 1px #CCC solid;
  //margin-bottom: 5px !important;
  .trade-details-container {
    overflow: hidden;
    .trade-details-expand {
      max-height: 0;
      inset: 0;
      transition: max-height 0.5s ease-out;
      transition-delay: 250ms;
      overflow-x: hidden;
      overflow-y: hidden;
    }
    &:hover {
      .trade-details-expand {
        transition: max-height 0.5s ease-in;
        transition-delay: 250ms;
        max-height: 200px;
      //padding-bottom: 5px;
      }
    }
  }
}
.dark-layout {
  .trade-card{
    border-bottom: 1px #FFF solid;
  }
}
</style>
