import { logUnusedKeys } from '@/model/Utils';
import Pagination from '@/model/Pagination';
import Sort from '@/model/SortReq';

export default function (other) {
  other = other || {};
  let result= {
    range: {
      from: other.range?.from || null,
      until: other.range?.until || null,
    },

    affiliateIds: other.affiliateIds || null,
    //Search
    search: other.search || '',
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  };
  logUnusedKeys('AffiliateDashboardSearchRequest', result, other);
  return result;
}
