import api from '@/api';
import TrackerSearchRequest from '@/model/tracker/TrackerSearchRequest';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return Promise.resolve();

    },
    getAllTracker(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/tracker/get/all',
          TrackerSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },

  }

};
