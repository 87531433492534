import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    registrationTime: other.registrationTime || null,
    action: other.action || null,
    module: other.module || null,
    executorIds: other.executorIds || null,
    //Search
    search: other.search || '',
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
