import api from '@/api'

export default {
  namespaced: true,
  state: {
    user: null,
    loggedIn: false,
    role: null,
    permissions: null,
    pingInterval: null
  },
  getters: {
    loggedIn(state) {
        return state.loggedIn
    },
    user(state) {
        return state.user || {}
    },
    myId(state) {
      return state.user?.id
    },
    myRole(state) {
        return state.role
    },
    isAdminUser(state, vm) {
      return vm.user.isAdmin || false;
    },
    hasPermission: (state, getters) => (permission) => {
      return getters.isAdminUser || state.permissions != null && state.permissions.has(permission)
    },
    userFullName(state) {
      if(state.user == null){
        return '';
      }
      return state.user.firstName + ' ' + (state.user.lastName || '')
    }
  },
  mutations:{
    updateUser(state, data) {
        state.user = data
        state.loggedIn = data != null && data.id != null
    },
    updateRole(state, {role, permissions}) {
      state.role = role
      state.permissions = new Set(permissions)
    },
    setPingInterval(state, interval) {
      state.pingInterval = interval
    }
  },
  actions: {
    checkConnection({commit, state, dispatch}) {
        return api.base().get('/user/profile', {params:{data:!state.loggedIn}}).then(
          response => {
            let wasLoggedIn = state.loggedIn;
            let user = response.data.data
            let metadata = response.data.metadata
            commit('updateUser', user)
            commit('updateRole', metadata)
            commit('notification/setUnreaded', metadata.notifications, {root: true})
            dispatch('startPingSystem')
            commit('data/update',
              {
                // serverTimeDiff: (Date.parse(response.data.metadata.serverTime) - Date.now()),
                users: metadata.users, //metadata.users,
                userId: user.id,
                countries: metadata.countries,
                mailEvents: metadata.mailEvents,
                smtpProvider: metadata.smtpProvider,
                monetaryStatus: metadata.monetaryStatus,
                monetaryAction: metadata.monetaryAction,
                monetaryType: metadata.monetaryType,
                monetaryRealType: metadata.monetaryRealType,
                affiliates: (metadata.affiliates || []).reduce((acc, org) => Object.assign(acc, { [org.id]: org }),{}),
                organizations: (metadata.organizations || []).reduce((acc, org) => Object.assign(acc, { [org.id]: org }),{}),
                businessUnits: (metadata.businessUnits|| []).reduce((acc, bu) => Object.assign(acc, { [bu.id]: bu }),{}),
                role: metadata.role,
                admin: metadata.admin,
              },
              { root: true }
            )
            if(!wasLoggedIn){
                dispatch('notification/connect', null, {root: true})
            }
            return user
          });
    },
    login({commit, dispatch}, {username, password}) {
        return api.base().post('/user/login', { username, password })
          .then(dispatch('checkConnection'))
    },
    startPingSystem({state, commit}) {
      if(state.pingInterval == null && state.loggedIn) {
        let interval = setInterval(() => api.base().get('/user/profile'), 30*1000);
        commit("setPingInterval",interval)
      }
    },
  }
}
