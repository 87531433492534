export default function (other) {
  other = other || {}
  return {
    clientId: other.clientId || null,
    group: other.group || null,
    currency: other.currency || null,
    active: other.active || true,
    sendMail: other.sendMail || true,
  }
}
