export default function (other) {
  other = other || {};
  return {
    //Details fields

    email: other.email,
    password: other.password,
    provider: other.provider,
    orgId: other.orgId,
    userId: other.userId,
  };
}
