import api from '@/api';
import TradeRequest from '@/model/trade/TradeRequest';
import TradeSearchRequest from '@/model/trade/TradeSearchRequest';

export default {
  namespaced: true,
  state: {
    symbols: [],
    lastUpdated: null
  },
  getters: {
    symbols: state => state.symbols
  },
  mutations: {
    setSymbols(state, symbols) {
      state.symbols = symbols;
      state.lastUpdated = Date.now();
    }
  },
  actions: {
    getHistory({ commit }, {account, pagination, sort}){

      return api.base()
        .post(`/trade/${account}/history`, {
          pagination,
          sort
        })
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getAllTrades(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/trade/get/all',
          TradeSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },

    getLastTick({ commit }, {
      account,
      symbols
    }) {
      return api.base()
        .get(`/trade/${account}/last-ticker`, { params: { symbols: (symbols || []).join(',') } })
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    placeOrder({ commit }, {
      account,
      order
    }) {
      return api.base()
        .post(`/trade/${account}/place-order`, TradeRequest(order))
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    requestReopenOrder({ commit }, { account, orderId, order }) {
      return api.base()
        .post(`/trade/${account}/reopen-order/${orderId}`, TradeRequest(order))
        .then(
          response => {
            return response.data.data
          }
        )
    },
    editOrder({ commit }, {
      account,
      orderId,
      order
    }) {
      return api.base()
        .post(`/trade/${account}/edit-order/${orderId}`, TradeRequest(order))
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    getTradeById({ commit }, {account,orderId}) {
      return api.base()
        .get(`/trade/${account}/get/${orderId}`)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    closeOrder({ commit }, {
      account,
      orderId,
      order
    }) {
      return api.base()
        .post(`/trade/${account}/close-order/${orderId}`, TradeRequest(order))
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    deleteOrder({ commit }, {account, orderId }) {
        return api.base()
            .delete(`/trade/${account}/delete-order/${orderId}`)
            .then(
            response => {
                return response.data.data;
            }
            );
    }
  }
};
