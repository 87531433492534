<template>
  <div>
    <!--sl/tp-->
    <b-row class="mt-1">
      <!--sl-->
      <b-col cols="6">
        <b-form-group class="m-0">
          <template #description>
            <span v-if="profit.sl != null && stopPricesValidation.sl">
              Profit: {{ profit.sl }} {{ $t(`currencies.${account.currency}.symbol`) }}
            </span>
          </template>
          <b-input-group size="sm" prepend="sl"  style="width: 100%">
            <b-form-input  :state="profit.validSl ? null: false" v-model="tradeDetails.sl"></b-form-input>
          </b-input-group>
          <template #description>
             <span v-if="profit.sl != null && stopPricesValidation.sl">
               Profit: {{ profit.sl }} {{ $t(`currencies.${account.currency}.symbol`) }} ({{ profit.slPercentage }}%)
             </span>
          </template>
        </b-form-group>
      </b-col>
      <!--tp-->
      <b-col cols="6">
        <b-form-group class="m-0">
          <b-input-group size="sm" prepend="tp"  style="width: 100%">
            <b-form-input :state="profit.validTp ? null: false" v-model="tradeDetails.tp"></b-form-input>
          </b-input-group>
          <template #description>
                     <span v-if="profit.tp != null && stopPricesValidation.tp">
                       Profit: {{ profit.tp }} {{ $t(`currencies.${account.currency}.symbol`) }} ({{ profit.tpPercentage }}%)
                     </span>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
    <!--prices-->
    <b-row class="mt-1">
      <b-col v-bind:cols="isFutureTrade ? 5 : 4">
        <b-form-group class="m-0">
          <b-input-group size="sm" :prepend="$t('trade.price')" style="width: 100%">
            <b-input v-model="tradeDetails.openPrice" v-bind:disabled="tradeDetails.currentOpenPrice"></b-input>
            <b-spinner small  v-if="tradeDetails.currentOpenPrice !== false && loadingTicker" class="position-absolute position-right-0" style="margin-top: 8px"/>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="2" style="margin-top: 5px" v-if="!isFutureTrade">
        <b-checkbox v-model="tradeDetails.currentOpenPrice">{{ $t('operations.current') }}</b-checkbox>
      </b-col>
      <b-col v-bind:cols="isFutureTrade ? 5 : 4">
        <b-input-group size="sm" :prepend="$t('trade.time')" style="width: 100%">
          <b-input v-model="tradeDetails.openTime" v-bind:disabled="tradeDetails.currentOpenTime"></b-input>
        </b-input-group>
      </b-col>
      <b-col cols="2" style="margin-top: 5px">
        <b-checkbox v-model="tradeDetails.currentOpenTime">Current</b-checkbox>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { isSell, TradeCmdEnum } from '@/model/trade/TradeCmdEnum'
import { simulateCalculationProfit, calculateMargin, convert } from '@/model/TradingCalculation'
import { mapGetters } from 'vuex'
import { toObject } from '@/model/Utils'

export default {
  name: 'TradeOrderNewPricesPart',
  props:{
    value:{},
    lastTick:{},
    symbols:{},
    account:{},
    stopPricesValidation:{}
  },
  watch:{
    lastTick:{
      handler(){
        this.onTickUpdated()
      },
      immediate:true
    },
    ['value']:{
      handler(){
        this.recalculateProfit()
      },
      deep:true,
    },
  },
  data:()=>({
    profit:{},
  }),
  computed:{
    ...mapGetters('symbol', ['tickersMap']),
    allTickers() {
      if (this.tickersMap != null) {
        return toObject(Object.values(this.tickersMap), 'symbol')
      }
      return toObject((this.symbols || {}).map(s => s.tickDetails), 'symbol')
    },
    tradeDetails:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    },
    loadingTicker(){
      return this.lastTick == null || this.lastTick.bid == null || this.lastTick.symbolId !== this.tradeDetails.symbolId
    },
    commands() {
      return Object.keys(TradeCmdEnum);
    },
    isFutureTrade(){
      return this.tradeDetails.command != null && this.tradeDetails.command !== TradeCmdEnum.BUY && this.tradeDetails.command !== TradeCmdEnum.SELL
    },
  },
  methods:{
    syncPrice(){
      this.$emit('syncPrice')
    },
    onTickUpdated(){
      if(this.tradeDetails.currentOpenPrice) {
        this.tradeDetails.openPrice = isSell(this.tradeDetails.command) ? this.lastTick.ask : this.lastTick.bid
      }
      this.recalculateProfit()
    },
    recalculateProfit() {
      console.log("recalculateProfit")
      let profit = {validTp: true, validSl: true }
      let symbol = this.symbols.find(symbol => symbol.id === this.tradeDetails.symbolId)
      let currentPrice = isNaN(this.tradeDetails.openPrice) ? 0 : this.tradeDetails.openPrice
      if(symbol == null){
        return this.profit = profit
      }
      // profit.trade = simulateCalculationProfit(this.lastTick, symbol, { ...this.tradeDetails });
      if(this.tradeDetails.tp != null && this.tradeDetails.tp !== "" && !isNaN(this.tradeDetails.tp)) {
        profit.tp = simulateCalculationProfit(this.allTickers, this.lastTick, symbol, this.tradeDetails.command, this.tradeDetails.lots, this.account.currency, currentPrice, parseFloat(this.tradeDetails.tp))
        profit.tpPercentage = (((this.tradeDetails.tp / currentPrice) - 1 ) * 100).toFixed(2)
        profit.validTp = profit.tp > 0
      }
      if(this.tradeDetails.sl != null && this.tradeDetails.sl !== "" &&!isNaN(this.tradeDetails.sl)) {
        profit.sl = simulateCalculationProfit(this.allTickers, this.lastTick, symbol, this.tradeDetails.command, this.tradeDetails.lots, this.account.currency, currentPrice, parseFloat(this.tradeDetails.sl))
        profit.slPercentage = (((this.tradeDetails.sl / currentPrice) - 1) * 100).toFixed(2)
        profit.validSl = profit.sl < 0
      }
      if(profit.tp != null){
        profit.tp = profit.tp.toFixed(0)
      }
      if(profit.sl != null){
        profit.sl = profit.sl.toFixed(0)
      }
      return this.profit = profit
    },
  }
}
</script>

<style scoped>

</style>
