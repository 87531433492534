import Vue from 'vue'
import Toast from 'vue-toastification'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
const filterBeforeCreate = (toast, toasts) => {
  console.log("filterBeforeCreate", { id: toast.id, toast, toasts })
  if (toast.id != null && toast.id !== '' && toasts.findIndex(t => t.id === toast.id) >= 0) {
    // Returning false discards the toast
    console.log("filterBeforeCreate", "Returning false discards the toast")
    return false;
  }
  // You can modify the toast if you want
  return toast;
}
Vue.use(Toast, {
  hideProgressBar: false,
  closeOnClick: true,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
  filterBeforeCreate
})
