import api from '@/api'
import UserRequest from '@/model/user/UserRequest'
import UserSearchRequest from '@/model/user/UserSearchRequest'

export default {
  namespaced: true,
  state: {
    roles: []
  },
  getters: {
    allRoles: state => state.roles,
    roleById: state => id => state.roles.find(role => role.id === id)
  },
  mutations: {
    updateData(state, data) {
      state.roles = data.roles
    }
  },
  actions: {
    refreshData({ commit }) {
      return api.base()
        .get('/users/data')
        .then(
          response => {
            commit('updateData', response.data.data)
          }
        )
    },
    getAllUsers(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/users/get/all',
          UserSearchRequest( { ...(filter || {}), pagination, sort, })
        )
        .then(
          response => ({
            data: response.data.data,
            pagination: { page: response.data.metadata.page, total: response.data.metadata.total},
            sort: response.data.metadata.sort
          })
        )
    },
    getUserById({ commit }, id) {
      return api.base()
        .get('/users/id/' + id)
        .then(
          response => {
            return response.data.data
          }
        )
    },
    updateUser({ commit }, { user, id }) {
      return api.base()
        .post('/users/update/'+id, UserRequest(user))
        .then(
          response => {
            return response.data.data
          }
        )
    }, createUser({ commit }, { user }) {
      return api.base()
        .post('/users/add', UserRequest(user))
        .then(
          response => {
            return response.data.data
          }
        )
    }
  },
}
