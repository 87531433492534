import Vue from 'vue'
import numberFormatter from 'number-formatter'
import i18n from '@/libs/i18n'

import store from '@/store'

/*Vue.filter('numberFormatter', function (value, format) {
  if (value == null) {
    return ''
  }
  return numberFormatter(format || i18n.t('number.format'), value)
});*/

Vue.filter("organizationName",function (value) {
  if (value == null) {
    return ''
  }
  let organization = store.getters['data/getOrganizationById'](value);
  if(organization){
    return organization.name;
  }
  return '';
});

Vue.filter("organizationCurrency",function (value) {
  if (value == null) {
    return ''
  }
  let organization = store.getters['data/getOrganizationById'](value);
  if(organization){
    return organization.currency || 'EUR';
  }
  return '';
});

Vue.filter("orgCurrency",function (value, organizationId) {
  if (value == null || isNaN(value)) {
    return value
  }
  let organization = store.getters['data/getOrganizationById'](organizationId);
  let currency = organization.currency || 'EUR';
  return new Intl.NumberFormat("de-AT", {
    style: "currency",
    currency: currency,
  }).format(value);
});



