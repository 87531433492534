<template>
  <form-wizard ref="trade-wizard" title="New Trade" stepSize="sm" v-bind:busy="form.busy"
               :finish-button-text="isNew ? $t('trade.newTrade') : $t('trade.editTrade')"
               @on-complete="placeNewOrder"
               validate-on-back lazy
  >
    <!--Personal details step  -->
    <tab-content :title="isNew ? $t('trade.newTrade') : $t('trade.editTrade')" :before-change="validateTrade">
      <slot name="newTrade"></slot>
    </tab-content>
    <tab-content :title="$t('operations.revision')">
      <slot name="revision"></slot>
    </tab-content>

     
    <template #custom-buttons-right="data">
        <b-button class="mr-1" size="sm" v-if="!isNew && data.isLastStep"
                variant="warning" :disabled="form.busy"
                @click="reopenOrder">
        {{ $t('operations.reopen') }}
      </b-button>
    </template>
  </form-wizard>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'

export default {
  name: 'tradeOrderFormPart',
  mixins: [mixinBase, mixinNotifications],
  props: {
    isNew: {
      default: () => true
    },
    isClosed: {
      default: () => false
    },
    value: {},
    stopPricesValidation: {},
  },
  data: () => ({
    form: {
      busy: false,
    },
  }),
  computed: {
    tradeDetails: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    validateTrade() {
      if (this.tradeDetails.symbolId == null || this.tradeDetails.symbolId === '') {
        this.showErrorNotification('Symbol', 'Symbol is required')
        return false
      }
      if (this.tradeDetails.lots == null || this.tradeDetails.lots === '' || isNaN(this.tradeDetails.lots) || this.tradeDetails.lots <= 0) {
        this.showErrorNotification('Lots must be greater than 0')
        return false
      }
      if (this.tradeDetails.openTime == null || this.tradeDetails.openTime === '') {
        this.showErrorNotification('Open time is required')
        return false
      }
      if (this.tradeDetails.openPrice == null || this.tradeDetails.openPrice === '' || isNaN(this.tradeDetails.openPrice) || this.tradeDetails.openPrice <= 0) {
        this.showErrorNotification('Open price must be greater than 0')
        return false
      }
      if (this.stopPricesValidation.tp === false) {
        this.showErrorNotification('Take profit', 'Take profit must be greater than current price')
        return false
      }
      if (this.stopPricesValidation.sl === false) {
        this.showErrorNotification('Stop loss', 'Stop loss must be less than current price')
        return false
      }
      this.$emit('showGraph', false)
      return true
    },
    placeNewOrder() {
      if (!this.validateTrade()) {
        this.$refs['trade-wizard'].changeToTab(0)
        return
      }
      this.form.busy = true
      this.$emit('placeNewOrder', this.form)
    },
    reopenOrder() {
      if (!this.validateTrade()) {
        this.$refs['trade-wizard'].changeToTab(0)
        return
      }
      this.form.busy = true
      this.$emit('reopen', this.form)
    },

  }
}
</script>

<style scoped>

</style>
