<template>
  <v-select
      v-bind:multiple="multi || selectProps.multiple"
      :clearable="clearable"
      v-model="model"
      :options="$store.getters['data/allUsers']"
      label="username"
      :reduce="(user) => user.id"
      v-bind="selectProps"
      class="full-width-select"

  />
</template>

<script>
export default {
  name: 'UserSelectBox',
  props: {
    value: {
      required: true,
    },
    multi: {
      required: false,
    },
    clearable: {
      required: false,
    },
    selectProps:{
      default: () => ({})
    }
  },
  computed:{
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    }
  }
}
</script>

<style scoped>
.full-width-select {
  width: 100%; /* Set the width to 100% using a CSS class */
}
</style>
