import Pagination from '@/model/Pagination';
import Sort from '@/model/SortReq';

export default function (other) {
  other = other || {}
  return {
    //Details fields
    email: other.email,
    password: other.password,
    provider: other.provider,
    userId: other.userId,
    //Search
    search: other.search || '',
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
