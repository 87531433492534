import api from '@/api';
export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return Promise.resolve();

    },

    downloadFile(actions, {fileId,clientId}) {
      return new Promise(function (resolve, reject) {
        api.downloadApi().get('documents/download/file/'+fileId, {params: {client: clientId},withCredentials: true})
          .then(
            (response) => {

              let contentType = response.headers["content-type"] || "";
              let contentDisposition = response.headers["Content-disposition"] || "";
              let fileName = "file";
              if (contentDisposition.includes("filename=")){
                fileName = contentDisposition.substr(contentDisposition.toString().indexOf("filename=") + 9);
              }
              if (fileName === "file" && contentType.includes("filename=")) {
                fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
              }
              resolve({data: new Blob([response.data], {type: contentType}), fileName, contentType});
            }, (error) => {

              reject(error)
            })
      })
    },

    downloadFilePreview(actions, { fileId, clientId }) {
      let $this = this;
      return new Promise(function (resolve, reject) {
        api.downloadApi()
          .get('documents/download/file/' + fileId, {
            params: { client: clientId },
            withCredentials: true,
            responseType: 'blob', // Specify responseType as 'blob' to receive a Blob directly
          })
          .then((response) => {
            resolve({
              data: response.data, // Directly pass the Blob
              contentType: response.headers["content-type"] || "",
              fileName: response,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },


  }

};
