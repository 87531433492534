<template>
  <b-row>
    <b-col cols="2"><h5>{{$t('trade.symbol')}}:</h5></b-col>
    <b-col cols="9">{{tradeDetails.symbolId}}</b-col>

    <b-col cols="2"><h5>{{$t('trade.side')}}:</h5></b-col>
    <b-col v-bind:cols="isFutureTrade ? 4 : 10">{{tradeDetails.command}}</b-col>

    <b-col cols="2" v-if="isFutureTrade"><h5>{{$t('trade.openAtPrice')}}:</h5></b-col>
    <b-col cols="4" v-if="isFutureTrade">{{tradeDetails.openAtPrice}}</b-col>

    <b-col cols="2"><h5>{{$t('trade.lots')}}:</h5></b-col>
    <b-col cols="9">{{tradeDetails.lots}}</b-col>

    <b-col cols="2"><h5>{{$t('trade.openPrice')}}:</h5></b-col>
    <b-col cols="4">{{tradeDetails.openPrice}} ({{tradeDetails.currentOpenPrice ? $t('trade.market') : $t('trade.specific')}})</b-col>

    <b-col cols="2"><h5>{{$t('trade.openTime')}}:</h5></b-col>
    <b-col cols="4">{{tradeDetails.openTime}} ({{tradeDetails.currentOpenTime ? $t('trade.market') : $t('trade.specific')}})</b-col>

    <b-col cols="2"><h5>{{$t('trade.sl')}}:</h5></b-col>
    <b-col cols="4">{{tradeDetails.sl || $t("operations.none")}}</b-col>

    <b-col cols="2"><h5>{{$t('trade.tp')}}:</h5></b-col>
    <b-col cols="4">{{tradeDetails.tp || $t("operations.none")}}</b-col>
    <b-col cols="2"><h5>{{$t('trade.comment')}}:</h5></b-col>
    <b-col cols="6">{{tradeDetails.comment}}</b-col>
  </b-row>
</template>

<script>
import { TradeCmdEnum } from '@/model/trade/TradeCmdEnum'

export default {
  name: 'TradeOrderNewRevisionPart',
  props: {
    value: {},
  },
  computed: {
    tradeDetails: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isFutureTrade(){
      return this.tradeDetails.command != null && this.tradeDetails.command !== TradeCmdEnum.BUY && this.tradeDetails.command !== TradeCmdEnum.SELL
    },
  }
}
</script>



<style scoped>

</style>
