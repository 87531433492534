<template>
  <b-modal no-fade v-model="dialog.show" v-bind:size="id==null ? 'lg' : 'xl'" :ref="`madal-client-${uniqueId}`"
           :id="`madal-client-${uniqueId}`"
           :hide-footer="true" centered @show="closeAllPopovers" @close="onDialogClose"
           no-close-on-backdrop
  >
    <template #modal-title>
     <span v-if="loading !== true && error == null && client != null">
       {{ client.firstName }}  {{ client.lastName }}
     </span>
      <span v-if="id==null">
        {{ $t('tabs.clients.newClient') }}
      </span>
    </template>
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <b-tabs v-if="client != null && client.id != null" v-model="dialog.tab" content-class="" align="center">
          <b-tab :title="$t('tabs.clients.details')" active @click="refreshData">
            <template #title>
              <span>
                <i class="fas fa-circle-notch fa-spin" v-if="reLoading === true"></i>
                <i class="fa-sharp fa-solid fa-user" v-else></i>
                {{ $t('tabs.clients.details') }}
              </span>
            </template>
            <client-details @input="dialog.show=false" v-model="client"/>
          </b-tab>
          <!--          <b-tab :title="$t('tabs.clients.notes')" @click="()=>$refs.notes.loadData()">
                      <template #title>
                        <span>
                          <i class="fas fa-circle-notch fa-spin" v-if="$refs.notes.loading === true"></i>
                          <i class="fa-sharp fa-solid fa-sticky-note" v-else></i>
                          {{ $t('tabs.clients.notes') }}
                        </span>
                      </template>
                      <notes ref="notes" v-bind:client-id="client.id" autoload/>
                    </b-tab>-->
          <b-tab :title="$t('tabs.clients.accounts')" @click="()=>$refs.accounts.refresh()">
            <template #title>
              <span>
                <i class="fas fa-circle-notch fa-spin" v-if="$refs.accounts.loading === true"></i>
                <i class="fa-sharp fa-solid fa-wallet" v-else></i>
                {{ $t('tabs.clients.accounts') }}
              </span>
            </template>
            <client-accounts ref="accounts" :client-id="client.id" v-bind:organization-id="client.orgId"/>
          </b-tab>
          <b-tab :title="$t('tabs.clients.monetaryTransaction')" @click="()=>$refs.monetary.refresh()">
            <template #title>
              <span>
                <i class="fas fa-circle-notch fa-spin" v-if="$refs.monetary.loading === true"></i>
                <i class="fa-sharp fa-solid fa-credit-card" v-else></i>
                {{ $t('tabs.clients.monetaryTransaction') }}
              </span>
            </template>
            <client-monetary-table :is-client-table="true" ref="monetary" :client-id="client.id"
                                   :columns="monetaryColumnsNames"
            />

          </b-tab>
          <b-tab :title="$t('tabs.clients.documents')" @click="()=>$refs.kycTable.refresh()">
            <template #title>
              <span>
                <i class="fas fa-circle-notch fa-spin" v-if="$refs.kycTable.loading === true"></i>
                <i class="fa-sharp fa-solid fa-credit-card" v-else></i>
                {{ $t('tabs.clients.documents') }}
              </span>
            </template>
            <kyc-table :is-client-table="true" ref="kycTable" v-bind:filters="kycFilter" v-bind:columns="kycColumns"
                       :has-selection="false"
            />

          </b-tab>
          <b-tab :title="$t('tabs.clients.logs')">
            <template #title>
              <span>
                <i class="fas fa-circle-notch fa-spin" v-if="$refs.kycTable.loading === true"></i>
                <i class="fa-sharp fa-solid fa-newspaper" v-else></i>
                {{ $t('tabs.clients.logs') }}
              </span>
            </template>
          </b-tab>
          <template #tabs-end>
            <b-nav-item href="#" role="copy" @click="openCopyClientModal">
              <i style="color: #007bff" class="fa-solid fa-copy"></i>
              <span style="color: #007bff">{{ $t('columns.copyClient') }}</span>
            </b-nav-item>

            <b-nav-item href="#" role="copy" @click="openPromoteClientModal">
              <i style="color: #007bff" class="fa-solid fa-copy"></i>
              <span style="color: #007bff"
              >{{
                  (client.isSale == null || client.isSale) ? $t('columns.promoteToClient') : $t('columns.demoteToLead')
                }}</span>
            </b-nav-item>

            <b-nav-item href="#" role="presentation" @click="openMyAccount" v-bind:disabled="loadingToken">
              <i style="color: #007bff" class="fa-solid fa-arrow-right-to-bracket"></i>
              <span style="color: #007bff">{{ $t('columns.login') }}</span>
            </b-nav-item>

          </template>
        </b-tabs>
        <div v-else>
          <client-new-details v-model="client"/>
        </div>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%"
           v-else-if="loading !== true && error != null"
      >
        <b-alert show variant="danger">
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{ $t('operations.loading') }}</b-spinner>
      </div>
    </div>

    <!--modals-->
    <div v-if="client != null">
      <b-modal no-close-on-backdrop ref="copyClientModal" :title="'Copy '+client.fullName" hide-footer>
        <div>
          <b-form-group label="New Email" label-for="newEmail">
            <b-form-input v-model="newEmail" id="newEmail" required></b-form-input>
          </b-form-group>
          <div class="text-center mt-2">
            <b-button class="d-inline-block mr-1" size="sm" variant="outline-success" @click="confirmCopyClient">Confirm
            </b-button>
            <b-button class="d-inline-block" size="sm" variant="outline-warning" @click="cancelCopyClient">Cancel
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal no-close-on-backdrop ref="promoteClientModal" :title="client.fullName" hide-footer>
        <b-row>

          <b-col cols="12">
            <b-form-group label="Branch">
              <v-select v-model="selectedBranch" :clearable="true"
                        :options="allBranchesFiltered()"
                        label="name"
                        :reduce="(type) => type.id"
                        @option:selected="filterUserByBranch"

              ></v-select>
            </b-form-group>
          </b-col>
          <b-col v-if="selectedBranch!=null && users!=null" cols="12">
            <b-form-group label="Manager">
              <v-select v-model="selectedManager" :clearable="true"
                        :options="users"
                        label="username"
                        :reduce="(type) => type.id"

              ></v-select>
            </b-form-group>
          </b-col>


        </b-row>
        <b-row>
          <b-col cols="3" class="text-center">
          </b-col>
          <b-col cols="3" class="text-center">
            <b-button :disabled="selectedOptionIsEmpty()" class="" size="sm" variant="outline-success"
                      @click="confirmPromoteClient"
            >Confirm
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button size="sm" variant="outline-warning" @click="cancelPromoteClient">Cancel
            </b-button>
          </b-col>
          <b-col cols="3" class="text-center">
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </b-modal>


</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import ClientDetails from '@/components/partial/clients/ClientEditDetails';
import mixinBase from '@/components/mixin/mixinBase';
import Notes from '@/components/partial/notes/notes';
import ClientAccounts from '@/components/partial/accounts/ClientAccounts';
import ClientMonetaryTable from '@/components/tables/ClientMonetariesTable';
import mixinPopover from '@/components/mixin/mixinPopover';
import ClientNewDetails from '@/components/partial/clients/ClientNewDetails.vue';
import KycTable from '@/components/tables/KycTable';

export default {
  name: 'clientModal',
  mixins: [mixinBase, mixinNotifications, mixinPopover],
  components: {
    KycTable,
    ClientNewDetails,
    ClientMonetaryTable,
    ClientAccounts,
    Notes,
    ClientDetails
  },
  data: () => ({
    dialog: {
      show: false,
      tab: 0,
    },

    selectedBranch: null,
    selectedManager: null,
    newEmail: '',
    kycFilter: {},
    kycColumns: [],
    uniqueId: 0,
    loading: true,
    reLoading: true,
    loadingToken: false,
    error: null,
    id: null,
    client: null,
    branches: null,
    users: null,

  }),
  watch: {
    ['dialog.tab']() {
      if (this.client != null && this.loading !== true && this.queryParams.tab !== this.dialog.tab) {
        this.pushState({ tab: this.dialog.tab });
      }
    },
    queryParams: {
      handler() {
        if (this.queryParams.clientId == null) {
          this.closeModal();
          return;
        }
        if (this.queryParams.clientId !== this.id) {
          this.showModal(this.queryParams.clientId);
        } else if (this.queryParams.tab !== this.dialog.tab) {
          this.dialog.tab = !isNaN(this.queryParams.tab) ? parseInt(this.queryParams.tab) : 0;
        }

      },
      deep: true
    },
    ['client.id']() {
      if (this.client != null && this.client.id != null) {
        this.pushState({ clientId: this.client.id });
        this.id = this.client.id;

        this.kycFilter = {
          clientId: this.client.id
        };
      }
    }
  },
  computed: {
    ...mapGetters('data', ['allUsers', 'allBusinessUnits']),
    monetaryColumnsNames() {
      return [
        {
          'key': 'type',
          'locked': false,
          'enable': true
        },
        {
          'key': 'clientDetails.accountId',
          'label': 'account',
          'locked': false,
          'enable': true
        },
        {
          'key': 'amount',
          'locked': false,
          'enable': true
        },
        {
          'key': 'currency',
          'locked': false,
          'enable': true
        },
        {
          'key': 'registrationTime',
          'locked': false,
          'enable': true
        },
        {
          'key': 'wallet',
          'locked': false,
          'enable': true
        },
        {
          'key': 'realType',
          'locked': false,
          'enable': true
        }
      ];
    },
  },
  created() {
    this.uniqueId = this._uid;
    if (this.queryParams.clientId != null) {
      this.showModal(this.queryParams.clientId);
    }
    this.setKycClientFields();
  },
  methods: {
    ...mapActions('clients', ['getClientById', 'duplicateClient', 'getClientToken', 'promoteClient']),
    selectedOptionIsEmpty() {
      return this.selectedBranch === null || this.selectedManager === null;
    },
    openCopyClientModal() {
      this.$refs.copyClientModal.show();
    },
    openPromoteClientModal() {
      this.selectedBranch = null;
      this.selectedManager = null;
      this.$refs.promoteClientModal.show();
    },
    confirmCopyClient() {
      if (this.newEmail === '') {
        return;
      }
      let $this = this;
      this.duplicateClient({
        id: this.id,
        newEmail: this.newEmail
      })
          .then(client => {
            $this.client = client;
            $this.error = null;
            $this.loading = false;
            $this.$refs.copyClientModal.hide();
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
    confirmPromoteClient() {
      if (this.selectedBranch == null || this.selectedManager == null) {
        return;
      }
      let $this = this;
      this.promoteClient({
        id: this.id,
        userId: this.selectedManager,
        branchId: this.selectedBranch,
      })
          .then(client => {
            $this.client = client;
            $this.error = null;
            $this.loading = false;
            $this.selectedBranch = null;
            $this.selectedManager = null;
            $this.users = null;
            $this.$refs.promoteClientModal.hide();
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
    cancelCopyClient() {
      this.newEmail = '';
      this.$refs.copyClientModal.hide();
    },
    cancelPromoteClient() {
      this.selectedBranch = null;
      this.users = null;
      this.$refs.promoteClientModal.hide();
    },
    showModal(id, tabName) {
      console.log('showModal-> client', {
        id,
        tabName
      });
      this.id = id;
      if (id) {
        this.loading = true;
        this.dialog.show = true;
        if (tabName != null) {
          this.dialog.tab = tabName === 'monetary' ? 3 : 0;
          this.pushState({
            clientId: id,
            tab: this.dialog.tab,
          });
        } else {
          this.dialog.tab = !isNaN(this.queryParams.tab) ? parseInt(this.queryParams.tab) : 0;
        }
        this.refreshData();
        this.loading = true;
        this.pushState({
          clientId: id,
          tab: this.dialog.tab,
        });
      } else {
        this.client = {};
        this.error = null;
        this.loading = false;
        this.dialog.show = true;
      }
    },
    closeModal() {
      this.dialog.show = false;
      this.client = {};
      this.onDialogClose();
    },
    onDialogClose() {
      this.id = null;
      this.pushState({
        clientId: null,
        tab: null,
      });
    },
    refreshData() {
      let $this = this;
      this.reLoading = true;
      let currentTab = !isNaN(this.queryParams.tab) ? parseInt(this.queryParams.tab) : 0;
      this.getClientById(this.id)
          .then(client => {
            $this.client = client;
            $this.error = null;
            $this.loading = false;
            $this.reLoading = false;
            setTimeout(() => {
              $this.dialog.tab = currentTab;
            }, 500);
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
            $this.reLoading = false;
          });
    },

    setKycClientFields() {

      this.kycColumns = [
        {
          'key': 'status',
          'locked': false,
          'enable': true
        },
        {
          'key': 'type',
          'locked': false,
          'enable': true
        },
        {
          'key': 'file.source',
          'locked': false,
          'enable': true
        },
        {
          'key': 'info',
          'locked': false,
          'enable': false
        },
        {
          'key': 'registrationTime',
          'locked': false,
          'enable': true
        }, {
          'key': 'operationTime',
          'locked': false,
          'enable': true
        }
      ];
    },
    allBranchesFiltered() {
      if (this.client.isSale == null || this.client.isSale) {
        return this.allBusinessUnits.filter(branch => {
          return branch.type === 'RETENTION';
        });
      } else {
        return this.allBusinessUnits.filter(branch => {
          return branch.type === 'SALES';
        });
      }
    },

    filterUserByBranch() {
      this.users = this.allUsers.filter(user => {
        return user.businessUnits && user.businessUnits.some(branch => branch === this.selectedBranch);
      });
    },
    async openMyAccount() {
      this.loadingToken = true;
      try {
        let token = await this.getClientToken(this.client.id);
        window.open(token.accountPath, '_blank');
      } catch (e) {
        this.showServerErrorNotification('connectionError', null, e);
      } finally {
        this.loadingToken = false;
      }
    }
  },
};
</script>

<style scoped>

</style>
