<template>
  <div>
    <div v-if="hasAccounts">
      <div class="overflow-hidden" style="max-width: 100%;">
        <b-row no-gutters>
          <b-col align-self="center" md="1" class="text-center center">
            <i class="fa-sharp fa-solid fa-wallet center" style="font-size: 40px"></i>
          </b-col>
          <b-col md="11">
            <b-card-body class="overflow-visible">
              <div>
                <b-button size="sm" id="account-button-event" variant="outline-primary" style="margin-right: 5px; min-width: 150px">
                  {{$t('columns.account')}} {{ selectedAccount.id }} {{selectedAccount.isDemo ? ' - DEMO' : ''}}
                </b-button>
                <b-button size="sm" v-bind:variant=" selectedAccount.active ? `outline-success` : `outline-danger`"
                          style="margin-right: 5px; min-width: 150px" @click="accountActiveToggle">
                  <i class="fa-solid" :class="{'fa-check':selectedAccount.active, 'fa-times':!selectedAccount.active}"></i>
                  {{selectedAccount.active ? $t('columns.active') : $t('columns.inactive')}}
                </b-button>
                <b-button size="sm" variant="outline-warning" style="margin-right: 5px; min-width: 150px" @click="trySyncAccount"><i class="fa-solid fa-rotate"></i>
                  {{ $t('columns.sync') }} </b-button>
              </div>
              <b-card-text class="mt-1">
                <div>
                  <b-badge class="d-inline-block" style="margin-right: 5px; min-width: 150px;"
                           v-bind:variant="isDarkSkin ? 'light' : 'dark'"
                  >
                    <span class="mr-1">CURRENCY: {{ selectedAccount.currency }}</span>
                    <span class="mr-1">LEVERAGE: 1:{{ selectedAccount.defaultLeverage || '200' }}</span>
                    <span class="mr-1"> BALANCE: {{ selectedAccount.balance | numberFormatter() }}</span>
                    <span class="mr-1">CREDIT: {{ selectedAccount.credit | numberFormatter() }}</span>
                    <p class="d-inline-block my-0 mr-1" v-if="selectedAccount.margin > 0">
                      Margin: {{ (selectedAccount.margin || 0).toFixed(2) }} {{ $t(`currencies.${selectedAccount.currency}.symbol`) }}
                     <span v-if="selectedAccount.marginFreePercentage <= 1500">
                       ({{ selectedAccount.marginFreePercentage }}%)
                     </span>
                      {{ selectedAccount.marginCall ? 'CALL' : '' }}
                    </p>
                    <p class="d-inline-block my-0 mr-1" v-if="selectedAccount.margin > 0">
                      Equity: {{ (selectedAccount.equity || 0) | numberFormatter }}
                    </p>
                    <p class="d-inline my-0" v-if="selectedAccount.profit != null">
                      PROFIT:
                      <span :class="{'text-danger':selectedAccount.profit<0,'text-success':selectedAccount.profit>0}">
                        {{ selectedAccount.profit | numberFormatter }} {{ $t(`currencies.${selectedAccount.currency}.symbol`) }}
                      </span>
                    </p>
                    <span v-else-if="selectedAccount.totalOpenTrades > 0">
                       <b-spinner small/>
                    </span>
                  </b-badge>
                </div>
              </b-card-text>
<!--              {{selectedAccount}}-->
            </b-card-body>
          </b-col>
        </b-row>
      </div>
      <trade-account-details ref="trade-account-details" v-if="selectedAccount.id !== '0'" v-model="selectedAccount" />
    </div>
    <div v-else-if="!loading">
      <b-card-body>
        <div class="text-center">
          <i class="fa-sharp fa-solid fa-wallet" style="font-size: 40px"></i>
          <p class="mt-2">{{ $t('account.noAccounts') }}</p>
          <b-button size="sm" variant="outline-primary" @click="newAccount">
            <i class="fa-sharp fa-solid fa-plus"></i>
            {{ $t('account.newAccount') }}
          </b-button>
        </div>
      </b-card-body>
    </div>
    <b-popover v-if="hasAccounts" ref="popover" custom-class="account-list-popover"  placement="bottom" target="account-button-event">
      <b-list-group class="m-0 p-0">
        <b-list-group-item class="cursor-pointer" v-for="acc in accounts" v-bind:key="acc.id" @click="onAccountSelect(acc.id)" >{{acc.id}} {{acc.isDemo ? ' - Demo' : ' - Real'}}</b-list-group-item>
        <b-list-group-item class="cursor-pointer" @click="newAccount">
          <i class="fa-sharp fa-solid fa-plus"></i>
          {{$t('account.newAccount')}}
        </b-list-group-item>
      </b-list-group>
    </b-popover>
    <new-account-modal ref="account-modal" @created="onAccountCreated"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TradeAccountDetails from '@/components/partial/accounts/TradeAccountDetails'
import NewAccountModal from '@/components/modal/newAccountModal.vue'
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import mixinPopover from '@/components/mixin/mixinPopover'
import account from '@/store/system/account'

export default {
  name: 'ClientAccounts',
  mixins: [mixinBase, mixinNotifications, mixinPopover],
  components: { NewAccountModal, TradeAccountDetails },
  data: () => ({
    loading: true,
    error: null,
    accounts: [],
    selectedAccount: { id: '0', active:false }
  }),
  props: {
    clientId: String,
    organizationId: String
  },
  watch: {
    clientId: {
      handler() {
        this.refresh()
      },
      immediate: true
    }
  },
  created() {
    this.$root.$on('system::account::update', this.onAccountUpdate)
  },
  beforeDestroy() {
    this.$root.$off('system::account::update', this.onAccountUpdate)
  },
  computed: {
    ...mapGetters('appConfig', ['isDarkSkin']),
    hasAccounts(){
      return Array.isArray(this.accounts) && this.accounts.length > 0;
    },
    account() {
      return account
    },
  },
  methods: {
    ...mapActions('account', ['getAccountsByClient', 'setActive', 'syncAccount']),
    async refresh() {
      try {
        this.loading = true;
        this.accounts = await this.getAccountsByClient(this.clientId);
        this.setDefaultSelectedAccount()

        this.$refs['trade-account-details'].refresh();
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false;
      }
    },
    setDefaultSelectedAccount(){
      if(this.selectedAccount != null && this.selectedAccount.id !== '0') {
        let acc = this.accounts.find(x => x.id === this.selectedAccount.id);
        if(acc != null) {
          this.selectedAccount = { profit: null, ...acc};
          return;
        }
      }
      if (Array.isArray(this.accounts) && this.accounts.length > 0) {
        this.selectedAccount = { profit: null, ...this.accounts[0] };
      } else {
        this.selectedAccount = { profit: null, id: '0' }
      }
    },
    onAccountSelect(id) {
      try {
        if (this.selectedAccount.id === id) {
          return
        }
        this.selectedAccount = this.accounts.find(x => x.id === id)
      } finally {
        this.closeAllPopovers();
      }
    },
    onAccountUpdate(account) {
      console.log('onAccountUpdate', account)
      const index = this.accounts.findIndex(x => x.id === account.id);
      if (index !== -1) {
        this.accounts[index] = account;
      }
      if (this.selectedAccount.id === account.id) {
        this.selectedAccount = account;
      }
    },
    newAccount() {
      this.$refs['account-modal'].showModal(this.clientId, this.organizationId)
    },
    onAccountCreated(account) {
      if(!Array.isArray(this.accounts)) {
        this.accounts = []
      }
      this.accounts.push(account)
      this.selectedAccount = account
    },
    accountActiveToggle(){
      this.setActive({account: this.selectedAccount.id, active: !this.selectedAccount.active})
          .then(this.refresh)
    },
    trySyncAccount(){
      let $this = this;
      this.syncAccount(this.selectedAccount.id)
          .then(() => {
            $this.showSuccessNotification('Account sync')
            $this.refresh()
          }, err=> $this.showServerErrorNotification('updateDataError','account', err))
    }
  }
}
</script>

<style lang="scss">
.account-list-popover{
  .popover-body{
    padding: 0;
  }
}
</style>
