import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    businessUnit: other.businessUnit || null,
    orgId: other.orgId || null,
    clientId: other.clientId || null,
    info: other.info || null,
    currency: other.currency || null,
    registrationTime: other.registrationTime || null,
    operationTime: other.operationTime || null,
    type: other.type || null,
    //Search
    search: other.search || '',
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
