<template>
  <b-modal v-model="active" centered hide-footer :title="modalTitle" size="sm" no-close-on-backdrop
           @cancel="closeModal" cancel-variant="outline-danger"
  >
    <b-row>


      <b-col cols="12">
        <b-form-group label="Type">
          <v-select v-model="type" :clearable="false"
                    :options="allTypes"
                    label="label"
                    :reduce="(type) => type.id"
                    @option:selected="onValueUpdated"

          ></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Status">
          <v-select v-model="status" :clearable="false"
                    :options="allStatus"
                    label="label"
                    :reduce="(type) => type.id"
                    @option:selected="onValueUpdated"

          ></v-select>
        </b-form-group>
      </b-col>

      <b-col v-if="type !=null && status!=null" cols="12">
        <b-form-group label="File">
          <div style="border: 1px solid red; position: relative;">

            <vue-dropzone ref="myVueDropzone" id="dropzone"     @vdropzone-success="onDropzoneSuccess" :options="dropzoneOptions"></vue-dropzone>
          </div>

        </b-form-group>
      </b-col>

    </b-row>
    <hr/>
    <!--    <b-row>
          <b-col cols="12">
            <b-button block variant="success" @click="uploadKyc">{{ $t('operations.upload') }}
            </b-button>
          </b-col>

        </b-row>-->
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { vue } from '@/main';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'clientKycModal',
  mixins: [mixinBase, mixinNotifications],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data: () => ({
    modalTitle: 'Add Document',
    status: null,
    type: null,
    allStatus: [],
    allTypes: [],
    active: false,
    fileUploadUrl: null,
    clientId: null,
    dropzoneOptions: {
      url: '', // Replace with your actual upload endpoint
      withCredentials: true,
      method: 'POST', // Set the method to POST
      maxFilesize: 10, // Maximum file size in MB
      dictDefaultMessage: 'Drop files here or click to upload',
      dictMaxFilesExceeded: 'Only one file is allowed to upload',
      addRemoveLinks: true, // Show remove button for uploaded files

    },
  }),
  props: {
    clientNumber: {
      default: () => ({})
    }
  },
  computed: {},

  created() {
    let status = vue.$t(`kyc.status`);
    let type = vue.$t(`kyc.type`);
    this.clientId = this.clientNumber;
    this.allStatus = Object.entries(status)
        .map(([id, label]) => ({
          id,
          label
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    this.allTypes = Object.entries(type)
        .map(([id, label]) => ({
          id,
          label
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
  },
  methods: {
    ...mapActions('kyc', ['createKyc']),

    onValueUpdated() {

      if (this.status !== null && this.type !== null) {
        console.log("status", this.status)
        console.log("type", this.type)
        this.dropzoneOptions.url = `http://localhost:8080/kyc/upload?type=${encodeURIComponent(String(this.type))}&status=${encodeURIComponent(String(this.status))}&clientId=${encodeURIComponent(String(this.clientId))}`;
      }
    },
    showModal(clientId) {
      this.active = true;
    },
    onDropzoneSuccess(file, response) {
      this.showSuccessNotification(this.$t('globalMessages.addDataSuccess', { system: this.$t('menu.kyc') }));
      this.refreshTables();
      // Close the modal after successful upload
      this.closeModal();
    },
    /*  async uploadKyc(type) {
       let $this = this;
       this.newTransaction.type = type;
       this.createKyc({
         kyc: this.newKyc
       })
           .then(() => {
             $this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.monetaries') }));
             $this.refreshTables();
             $this.closeModal();
           }, err => {
             this.showErrorNotification(this.$t('globalMessages.fetchDataError', { system: this.$t('menu.monetaries') }), err.response.data.error);
           });

     }, */
    closeModal() {
      this.status = null;
      this.type = null;
      this.active = false;
    },
  }
};
</script>


<style scoped>

</style>
