export default function (other) {
  other = other || {}
  return {
    //Details fields
    firstName: other.firstName,
    lastName: other.lastName,
    phone: other.phone,
    mobile: other.mobile,
    country: other.country,
    city: other.city,
    address: other.address,
    zip: other.zip,
    lang: other.lang,
    email: other.email,
    email2: other.email2,
    birthday: other.birthday,
    gender: other.gender,
    companyClient: other.companyClient,
    password: other.password,

    //Document fields
    docNum: other.docNum,
    docType: other.docType,
    docCountry: other.docCountry,
    statusTime: other.statusTime,
    statusId: other.statusId,

    //Affiliate fields
    affiliateId: other.affiliateId,
    campaign: other.campaign,
    banner: other.banner,
    tag: other.tag,

    //Representative fields
    presaleUserId: other.presaleUserId,
    representativeUserId: other.representativeUserId,
    retentionUserId: other.retentionUserId,

    //Company fields
    companyNumber: other.companyNumber,
    activity: other.activity,
    position: other.position,
    companyName: other.companyName,

    comment: other.comment,
    active: other.active,

    orgId: other.orgId,
    businessUnitId: other.businessUnitId,

    lastUpdate: other.lastUpdate,

  }
}
