import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    created: other.created || null,
    description: other.description || null,
    lastUpdated: other.lastUpdated || null,
    metaIndex: other.metaIndex || null,
    name: other.name || null,
    source: other.source || null,
    symbols: other.symbols || null,
    index: other.index || null,
    //Search
    search: other.search || '',
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
