import api from '@/api';
import MonetaryRequest from '@/model/monetary/MonetaryRequest';
import MonetarySearchRequest from '@/model/monetary/MonetarySearchRequest';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return Promise.resolve();
    /*   return api.base()
        .get('/monetaryTransaction/data')
        .then(
          response => {
            commit('updateData', response.data.data);
          }
        ); */
    },
    getAllMonetary(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/monetaryTransaction/get/all',
          MonetarySearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getMonetaryById({ commit }, id) {
      return api.base()
        .get('/monetaryTransaction/id/' + id)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    handleEvent({ commit }, { id, event }) {
      return api.base()
        .post('/monetaryTransaction/handleEvent/' + id+'/'+event)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateMonetary({ commit }, {
      monetary,
      id
    }) {
      return api.base()
        .post('/monetaryTransaction/update/' + id, monetary)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    createMonetary({ commit }, { monetary }) {
      return api.base()
        .post('/monetaryTransaction/add', monetary)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    transferMonetary({ commit }, { monetary }) {
      return api.base()
        .post('/monetaryTransaction/transfer', monetary)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    exportMonetaryDataExcel(actions, { filter, pagination, sort, fields }){
      let request = {searchRequest:  { ...(filter || {}), pagination, sort }, fields };
      return api.base()
        .post('/monetaryTransaction/report/file/download', request, { responseType: 'blob' })
        .then(
          response => {
            console.log("after", response);
            const url = URL.createObjectURL(new Blob([response.data],
              { type: response.headers["content-type"] }
            ))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', response.headers["file-name"])
            document.body.appendChild(link)
            link.click()

            return response.data
          }).catch(console.error)

    },

  }

};
