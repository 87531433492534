import { safeParseFloat, safeParseInt } from '@/model/NumberUtils'

export default function (other) {
  other = other || {}
  return {
    ...other,
    login: other.login || null,
    flags: other.flags || null,
    command: other.command || null,
    order: other.order || null,
    symbolId: other.symbolId || null,
    volume: safeParseInt(other.volume || null),
    lots: safeParseFloat(other.lots || null),
    openPrice: safeParseFloat(other.openPrice || null),
    openAtPrice: safeParseFloat(other.openAtPrice || null),
    currentOpenPrice: other.currentOpenPrice || null,
    closePrice: safeParseFloat(other.closePrice || null),
    currentClosePrice: other.currentClosePrice || null,
    openTime: other.openTime || null,
    currentOpenTime: other.currentOpenTime || null,
    currentCloseTime: other.currentCloseTime || null,
    closeTime: other.closeTime || null,
    tp: safeParseFloat(other.tp || null),
    sl: safeParseFloat(other.sl || null),
    comment: other.comment || null,
    reopen: other.reopen || null,
    swap: other.swap || null,
    commissionAgent: other.commissionAgent || null,
    commission: other.commission || null,
    taxes: other.taxes || null,
    transactionId: other.transactionId || null,
  }
}
