<template>
  <div id="client-notes">
    <div v-if="loaded">

      <div style="position: absolute; margin-top: 3px; right: 160px">
        <b-spinner small v-if="loading">{{ $t('operations.loading') }}</b-spinner>
        <b-link v-else><i class="fa-solid fa-rotate-right" @click="loadData"></i></b-link>
      </div>
      <b-button variant="primary" size="sm" class="mb-1" style="float: right" @click="openModal()">
        {{ $t('operations.addNewNote') }}
      </b-button>


      <h4 class="text-primary text-center text-uppercase">
        <i class="fa-solid fa-sticky-note"></i>
        {{ $t('tabs.clients.notes') }}
      </h4>
      <b-table striped small hover :items="notes"
               v-handy-scroll responsive
               :fields="fields"
      >
        <template #cell(actions)="data">
          <b-button size="sm" @click="loadNote(data.item.id)" variant="primary" class="mr-1">
            {{ $t('operations.edit') }}
          </b-button>
          <b-button size="sm" @click="onDeleteNote(data.item.id)" variant="danger">{{ $t('operations.delete') }}
          </b-button>
        </template>

        <template #cell(text)="data">
          <div class="fixed-width-cell"><span>{{ data.value }}</span></div>
        </template>


        <template #cell(isPublic)="data">
          <div v-if="data.value">
            <i class="fas fa-lock-open" style="color: green;"></i>
          </div>
          <div v-else>
            <i class="fas fa-lock" style="color: red;"></i>
          </div>
        </template>

        <template #cell(time)="data">
          {{ data.value | moment($t('timeFormat.dateTime.format')) }}
        </template>
      </b-table>

      <b-modal v-model="showModal" title="New Note" no-close-on-backdrop hide-footer>
        <b-form @submit.prevent="save">
          <b-form-group label="Note">
            <b-form-textarea v-model="newNote.text" rows="4"></b-form-textarea>
          </b-form-group>
          <b-form-checkbox v-model="newNote.isPublic">Public</b-form-checkbox>
          <b-button style="float: right" type="submit" variant="primary">{{ $t('operations.save') }}</b-button>
        </b-form>
      </b-modal>
    </div>
    <div v-else-if="error !== ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="text-align: center;height: 100%;padding: 30% 20px 20px;">
        <b-spinner label="Spinning"/>
        {{ $t('operations.loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ClientNotes',
  props: {
    autoload: {
      default: false,
      type: Boolean
    },
    clientId: String,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      error: '',
      showModal: false,
      newNote: this.defaultNewNote(),
      notes: [],
      fields: [
        {
          key: 'text',
          label: 'Note'
        },
        {
          key: 'isPublic',
          label: 'Public'
        },
        {
          key: 'time',
          label: 'Time'
        },
        {
          key: 'modificationTime',
          label: 'Updated Time'
        },
        {
          key: 'actions',
          label: 'Actions'
        },
      ],
    };
  },
  watch: {
    clientId() {
      this.reset();
      if (this.autoload) {
        this.loadData();
      }
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions('notes', ['getNotes', 'get', 'update', 'register', 'deleteNote']),
    defaultNewNote() {
      return {
        clientId: this.clientId,
        text: '',
        isPublic: true,
      };
    },
    openModal() {
      this.newNote = this.defaultNewNote();
      this.showModal = true;
    },
    reset() {
      this.loaded = false;
      this.error = '';
      this.newNote = this.defaultNewNote();
      this.notes = [];
    },
    loadData() {
      if (this.loading) return;
      this.loading = true;
      this.getNotes({ clientId: this.clientId })
          .then(noteData => {
            this.notes = noteData.data;
            this.loaded = true;
          })
          .catch(e => {
            this.error = e.toString();
          })
          .finally(() => {
            this.loading = false;
          });
    },
    loadNote(id) {
      this.get({ id })
          .then(note => {
            this.newNote = note;
            this.showModal = true;
          });
    },
    onDeleteNote(id) {
      this.deleteNote(id)
          .then(() => {
            this.loadData();
          });
    },
    save() {
      const action = this.newNote.id ? 'update' : 'register';
      this[action]({ note: this.newNote })
          .then(() => {
            this.reset();
            this.loadData();
            this.showModal = false;
          })
          .catch(e => {
            this.error = e.toString();
          });
    },
  }
};
</script>

<style scoped>
#client-notes {
  min-height: 400px;
}

.fixed-width-cell {
  width: 150px; /* Adjust this value according to your desired width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* Optional: This truncates the text with an ellipsis if it overflows */
}
</style>
