import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import 'font-awesome/css/font-awesome.css';
import router from './router'
import store from './store/index'
import App from './App.vue'
import vClickAway from 'vue-clickaway';
// Global Components
import './global-components'
import 'vue-orgchart/dist/style.min.css'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vSelect'
import '@/libs/fonts'
import '@/libs/vue2-perfect-scrollbar'
import '@/libs/vue-moment'
import i18n from '@/libs/i18n/index'
import '@/libs/v-calendar'
import '@/libs/quill-editor'
import '@/libs/vue-form-wizard'
import '@/libs/number-formatter'
import '@/libs/system-filters'
import '@/libs/handy-scroll'
// BSV Plugin Registration
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

//flags
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import CanvasJSChart from '@canvasjs/vue-charts';
Vue.use(CanvasJSChart);

import {BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false
Vue.use(vClickAway);
export const vue  = new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

window.onerror = function (message, source, line, column, error) {
  console.log('ONE ERROR HANDLER TO RULE THEM ALL:', {message, source, line, column, error});
}

