<template>
  <div>
    <b-overlay :show="loading.page" rounded="sm">
      <b-tabs fill vertical v-model="activeTab">
        <b-tab active title="OPEN TRADES" @click="refresh">
          <trade-details-tab v-if="activeTab===0" v-bind:account="account" v-bind:trades="openTrades" ref="openTradesTab"
                             @newTradeRequest="newTradeRequest"
                             @editTradeRequest="editTradeRequest"
                              @closeTradeRequest="closeTradeRequest"
                             @deleteTradeRequest="deleteTradeRequest"
          />
        </b-tab>
        <!--Closed Trades-->
        <b-tab title="CLOSED TRADES" @click="refreshHistory()">
          <trade-details-tab v-if="activeTab===1" v-bind:account="account" v-bind:trades="history.data" ref="closedTradesTab"
                             :is-history="true"

                             @editTradeRequest="editTradeRequest"
                             @deleteTradeRequest="deleteTradeRequest"
          />
        </b-tab>
      </b-tabs>
      <div>
        <!--      {{tickers}}-->
      </div>
      <div>
        <!--      {{history}}-->
      </div>
    </b-overlay>
    <trade-order-modal ref="tradeOrderModal"  @order-placed="refresh"  @order-reopened="refreshHistory" @close="onOrderModalClose"></trade-order-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { toObject } from '@/model/Utils'
import { recalculateAllProfits } from '@/model/TradingCalculation'
import TradeDetailsTab from '@/components/partial/accounts/TradeDetailsTab'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import mixinBase from '@/components/mixin/mixinBase'
import TradeOrderModal from '@/components/modal/tradeOrderModal.vue'

export default {
  name: 'TradeAccountDetails',
  mixins: [mixinBase, mixinNotifications],
  components: { TradeOrderModal, TradeDetailsTab},
  data: () => ({
    loading: { page: true},
    error: null,
    accountLogin: null,
    symbols:[],
    tickers: {},
    openTrades: [],
    history: {
      data: [],
      pagination: { page: 1, total: 0, size: 10 },
      sort: { direction: 'DESC', field: null },
    },
    intervalId: null,
    activeTab: 0,
  }),
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler() {
        if (this.accountLogin !== this.account.id) {
          this.loading.page = true;
          this.subscribeToWs(this.accountLogin, false)
          this.refresh()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('symbol', ['getTicker']),
    account: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

  },
  created() {
   // this.intervalId = setInterval(this.refresh, 10 * 1000)
    this.subscribeToTickers(true)
    this.$root.$on("system::tickers::updated", this.refreshTickers)
    this.$root.$on("system::trade::update", this.onTradeUpdated)

  },
  beforeDestroy() {
    //clearInterval(this.intervalId)
    this.subscribeToTickers(false)
    this.$root.$off("system::tickers::updated", this.refreshTickers)
    this.$root.$off("system::trade::update", this.onTradeUpdated)
  },
  methods: {
    ...mapActions('notification', ['subscribeToTickers', 'subscribeToAccount', 'subscribeToTrades']),
    ...mapActions('account', ['getAccountDataByLogin']),
    ...mapActions('trade', ['closeOrder', 'getHistory', 'deleteOrder']),
    async refresh() {
      try {
        if(this.account == null){
          return;
        }
        this.accountLogin = this.account.id
        const { symbols, account, openTrades, tickers } =  await this.getAccountDataByLogin(this.account.id)
        this.subscribeToWs( this.accountLogin, true)
        this.symbols = symbols
        this.openTrades = openTrades || []
        this.tickers = toObject((tickers || []), 'symbolId')
        this.recalculateProfit();
      } catch (e) {
        this.error = e
        this.showServerErrorNotification('fetchDataError', 'account', e)
      } finally {
        this.loading.page = false
      }
    },
    refreshHistory() {
      let $this = this;
      this.getHistory({account:this.accountLogin, pagination:$this.history.pagination, sort:$this.history.sort})
          .then((res) => {
            $this.history.data = res.data
          })
          .catch((e) => {
            this.showServerErrorNotification('fetchDataError', 'account', e)
          })
    },
    subscribeToWs(accountLogin, toggle){
      if(accountLogin == null || accountLogin === 'none'){
        return;
      }
      this.subscribeToAccount({accountId: accountLogin, toggle: toggle})
      this.subscribeToTrades({accountId: accountLogin, toggle: toggle})
    },
    refreshTickers() {
      let $this = this
      let tickers = Object.keys(this.tickers)
      tickers.forEach((ticker) => {
        $this.tickers[ticker] = $this.getTicker(ticker) || $this.tickers[ticker]
      })
      this.recalculateProfit();
    },
    recalculateProfit(){
      let profit = recalculateAllProfits(this.account, this.openTrades, this.symbols, this.tickers)
      let marginFreePercentage = this.calculateMarginFreePercentage();
      console.log('recalculateProfit', profit, marginFreePercentage)
      this.$emit('input',{ ...this.account, profit,marginFreePercentage: marginFreePercentage})
    },
    calculateMarginFreePercentage(){
      if(this.account == null){
        return 0;
      }
      let balance = this.account.balance || 0;
      let credit = this.account.credit || 0;
      this.account.equity = balance + credit;
      this.account.equity += this.openTrades.reduce((acc, trade) => acc + (trade.profit || 0), 0);
      let margin = this.account.margin || 0;
      // let marginFree = equity - margin;
      if(this.account.equity === 0){
        return 0;
      }
      if(margin === 0){
        margin = 1;
      }
      return ((this.account.equity / margin) * 100).toFixed(2);
    },
    onTradeUpdated(trade){
      console.log('onTradeUpdated', trade)
      let index = this.openTrades.findIndex((t) => t.order === trade.order)
      if (index < 0) {
        return
      }
      if(trade.state !== 'OPEN'){
        this.openTrades.splice(index, 1)
      }else {
        this.openTrades[index] = trade
      }
      this.recalculateProfit()
    },
    newTradeRequest() {
      this.$refs.tradeOrderModal.showModal(this.account)
    },
    editTradeRequest(trade) {
      this.$refs.tradeOrderModal.showModal(this.account, trade)
    },
    closeTradeRequest(data) {
      let $this = this
      this.closeOrder({
        account: this.accountLogin,
        orderId: data.trade.order,
        order: {}
      })
          .then(() => {
            $this.openTrades = $this.openTrades.filter((t) => t.order !== data.trade.order)
            $this.refresh()
          }, error => $this.showServerErrorNotification('placeOrderError',null, error))
          .finally(() => {
            data.callback()
          })
    },
    onOrderModalClose(){
      console.log('onOrderModalClose')
      //scroll to top all class .trade-card
      setTimeout(() => {
        document.getElementsByClassName("trade-details-expand").forEach((el) => {
          el.scrollTop = 0;
        })
      }, 1000)
    },
    deleteTradeRequest(form) {
      this.deleteOrder({
        account: this.accountLogin,
        orderId: form.trade.order,
      })
          .then(() => {
            this.refresh()
          }, error => this.showServerErrorNotification('placeOrderError', null, error))
          .finally(() => {
            form.callback()
          })
    }
  }
}
</script>

<style lang="scss">


</style>
