import mixinBase from '@/components/mixin/mixinBase'

export default {
  mixins: [mixinBase],
  data() {
    return {
      showModal: false,
      loadingTime: null,
      pagination: { page: 1, total: 0, size: 20 },
      sort: { direction: 'DESC', field: null },
    }
  },
  created: function () {
    if(this.refresh != null) {
      this.$root.$on('system::refresh-tables', this.refresh)
    }
  },
  beforeDestroy() {
    if(this.refresh != null) {
      this.$root.$off("system::refresh-tables", this.refresh);
    }
  },
  computed:{
    loading(){
        return this.loadingTime != null;
    },
    sortIsDesc:{
        get(){
          return this.sort.direction === 'DESC'
        },
        set(value){
          this.sort.direction = value ? 'DESC' : 'ASC'
        }
    }
  },
  methods: {
    setLoading(){
       return this.loadingTime = Date.now();
    },
    setLoaded(token){
      if (this.loadingTime === token){
        this.loadingTime = null;
      }
    },
    setPagination(page, total, size){
      if(this.pagination.page != null) {
        this.pagination.page = page
      }
      if(this.pagination.page != null) {
        this.pagination.total = total;
      }
      if(this.pagination.page != null) {
        this.pagination.size = size;
      }
    },
    setSort(field, direction){
        this.sort.field = field;
        this.sort.direction = direction;
    },
    setMetaDataFromResult(result){
      this.pagination = { ...this.pagination, ...result.pagination }
      this.setSort(result.sort.field, result.sort.direction)
    },
    onRowClicked(row, index, ev) {
      //console.log("onRowClicked", { row, index, ev })
      if(this.noRowClicked(row, index, ev) !== false){
        return
      }
      this.$emit('selected', row.id)
    },
    noRowClicked(row, index, ev) {
      console.log("noRowClicked", { row, index, ev })
      if(ev == null){
        console.error("noRowClicked: event is null")
        return false
      }
      if(ev.target.className.includes('no-click') || ev.target.className.includes('no-table-click')){
        console.log("noRowClicked: return true - no-click found")
        return true
      }
      if(ev.target.localName === "td"){
        console.log("noRowClicked: return false - td found")
        return false
      }
      ev = { ...ev, target: ev.target.parentNode }
      return this.onRowClicked(row, index, ev)
    },
    onRowClickedCustom(row) {
      this.$emit('selected', row.id)
    },
    onPageClick(event, page) {
        // console.log("onPageChanged", page)
        // event.preventDefault();
        this.pagination.page = page;
        this.refresh();
    },
    onSortChanged(data) {
      // console.log("onSortChanged", data)
      this.setSort(data.sortBy, data.sortDesc ? 'DESC' : 'ASC');
      this.pagination.page = 1;
      this.refresh();
    },
    toggleRow(item, value){
      let selectedObjects = this.selected
      if(value !== true){
        delete selectedObjects[item.id];
        selectedObjects.all = false
      }else {
        selectedObjects[item.id] = {active:true,id:item.id,item}
        selectedObjects.all = Object.keys(selectedObjects).filter(k => k !== 'all').length === this.rows.length //TODO: MULTIPLE PAGES
      }
      if(this.forceUpdate != null)
        this.forceUpdate();
      return selectedObjects;
    },
    toggleAllRow(value){
      let selectedObjects = this.selected
      console.log("_toggleAllRow",{value})
      let $this = this;
      if (value !== true) {
        Object.keys(selectedObjects)
          .forEach(k => delete selectedObjects[k])
      } else {
        this.rows.forEach(item => {
          $this.toggleRow(item, true)
        })
      }
      selectedObjects.all = value
      if(this.forceUpdate != null)
        this.forceUpdate();
      this.selected = selectedObjects;
    },
    isIndeterminate(selectedObjects) {
      return selectedObjects.all !== true && Object.keys(selectedObjects).filter(k => k !== 'all').length > 0
    }
  },
}
