<template>
  <div v-bind:key="refreshKey">
    <b-button v-if="isClientTable" class="mr-1 mb-2" variant="primary" size="sm" @click="openModal">
      <i class="fa-solid fa-file-arrow-up"></i>
      {{ $t('monetary.addDocument') }}
    </b-button>
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <b-table responsive
             v-bind:items="kycs"
             v-bind:fields="fields"
             no-local-sorting
             v-bind:sort-by.sync="sort.field"
             v-bind:sort-desc.sync="sortIsDesc"
             striped
             hover
             @sort-changed="onSortChanged"
             @row-clicked="downloadAndShowDocument"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline
        ></b-form-checkbox>
      </template>

      <template #cell(clientId)="data">
        <ClientCell @open-client-monetary="handleOpenClientMonetary" :client="clients[data.value]"/>
      </template>


      <template #cell(orgId)="data">
        {{ getOrganizationNameById(data.value) }}
      </template>
      <template #cell(registrationTime)="data">
        {{ data.value| momentDateTime }}
      </template>
      <template #cell(operationTime)="data">
        {{ data.value| momentDateTime }}

      </template>
      <template #cell(file.source)="data">
        <b-badge variant="primary">{{ data.value }}</b-badge>

      </template>

      <template #cell(businessUnit)="data">
        {{ getBusinessUnitNameById(data.value) }}
      </template>

      <template #cell(type)="data">
        <b-badge variant="dark">{{ data.value.replace(/_/g, ' ') }}</b-badge>
      </template>

      <template #cell(status)="data">
        <span class="font-weight-bold" :style="getStatusColor(data.value)">{{ data.value }}</span>
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"
        ></b-form-checkbox>
      </template>

      <template #cell(actions)="data">
        <div v-if="data.item.status === 'PENDING'" class="btn-group">
          <div class="btn-container">
            <b-button class="btn btn-success" size="sm" @click="handleKycEvent(data.item.id,'approve')"
                      v-b-tooltip.hover
                      title="Approve"
            >
              <i class="fa fa-check"></i>
            </b-button>

            <b-button class="btn btn-danger" size="sm" @click="handleKycEvent(data.item.id,'decline')" v-b-tooltip.hover
                      title="Decline"
            >
              <i class="fa fa-ban"></i>
            </b-button>

            <b-button class="btn btn-danger" size="sm" @click="handleKycEvent(data.item.id,'delete')" v-b-tooltip.hover
                      title="Delete"
            >
              <i class="fa fa-trash"></i>
            </b-button>

            <b-button class="btn btn-success" size="sm" @click="declineAction(data.item)" v-b-tooltip.hover
                      title="Download"
            >
              <i class="fas fa-download"></i>
            </b-button>
          </div>
        </div>

        <div v-else class="btn-group">
          <div class="btn-container">
            <!-- Buttons for other status -->
            <b-button class="btn btn-success" size="sm" @click="openCommentModal(data.item)" v-b-tooltip.hover
                      title="Edit"
            >
              <i class="fa fa-edit"></i>
            </b-button>

            <b-button class="btn btn-danger" size="sm" @click="handleKycEvent(data.item.id,'delete')" v-b-tooltip.hover
                      title="Delete"
            >
              <i class="fa fa-trash"></i>
            </b-button>

            <b-button class="btn btn-success" size="sm" @click="downloadDocument(data.item)" v-b-tooltip.hover
                      title="Download"
            >
              <i class="fas fa-download"></i>
            </b-button>
          </div>
        </div>
      </template>


    </b-table>

    <file-preview-modal
        :fileUrl="fileUrl"
        :fileType="fileType"
        :showModal="showModal"
        @close="closeModal"
    ></file-preview-modal>

    <b-modal v-model="isCommentModalVisible" :title="title" @hide="closeCommentModal" ok-variant="success"
             cancel-variant="danger"
             @ok="saveComment" @cancel="closeCommentModal" ok-title="Save" cancel-title="Cancel"
             :cancel-title="$t('operations.cancel')" :ok-title="$t('operations.save')"
    >
      <div class="modal-body">
        <textarea v-model="comment" class="form-control" rows="4"></textarea>
      </div>

    </b-modal>


    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size"  @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.kycs') }}

      </div>

    </div>
    <client-kyc-modal ref="kyc-modal" v-bind:client-number="filters.clientId"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import ClientCell from '@/components/partial/clients/ClientCell';
import UserSelectBox from '@/components/widget/select/UserSelectBox';
import FilePreviewModal from '@/components/modal/filePreviewModal';
import ClientKycModal from '@/components/modal/clientKycModal';

export default {
  name: 'KycTable',
  components: {
    FilePreviewModal,
    ClientCell,
    ClientKycModal,
    UserSelectBox,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    kycs: [],
    comment: '',
    isCommentModalVisible: false,
    isClientKycModalVisible: false,
    title: '',
    showModal: false,
    fileUrl: '', // URL of the downloaded file content
    fileType: '', // Content type of the file
    selectedItem: {}, // Store the selected item
    selectedClientId: null, // Store the selected item
    clients: {},
    selected: { all: false },
  }),
  props: {
    isClientTable: {
      default: () => false
    },
    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 10);
    this.refresh();

  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById']),

    fields() {
      let $this = this;

      let fields = (this.columns || []).map(column => {
        let label = column.key === 'clientId' ? $this.$t('columns.client') : $this.$t(`columns.${column.key}`);
        return {
          key: column.key,
          label: label,
          sortable: true,
          active: column.enable,
        };
      });

      fields = fields.filter(field => !field.key.startsWith('id'));

      fields.push({
        key: 'file.source',
        label: 'Source',
        class: 'text-center',
        sortable: false,
        active: true,
      });

      fields.push({
        key: 'actions',
        label: 'Actions',
        class: 'text-center',
        sortable: false,
        active: true,
      });

      if (this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }
      return fields.filter(f => f.active === true);
    },
    selectedIds() {
      this.refreshKey; // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected)
          .filter(k => k !== 'all')
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },
  methods: {
    ...mapActions('kyc', ['getAllKyc', 'handleEvent', 'updateKyc']),
    ...mapActions('document', ['downloadFile', 'downloadFilePreview']),
    openModal() {
      this.$refs['kyc-modal'].showModal(this.clientId);
    },

    downloadDocument(item) {

      let $this = this;
      this.downloadFile({
        fileId: item.file.id,
        clientId: item.clientId
      })
          .then(file => {
            $this.asyncDownloadFile(file);
            $this.showSuccessNotification($this.$t('globalMessages.downloadFileSuccess', { system: $this.$t('File') }));

          });
    },
    stringEndsWithPDF(inputString) {
      return inputString.endsWith('.pdf');
    },

    downloadAndShowDocument(item, index, ev ) {
     /*  if (this.stringEndsWithPDF(item.file.filename)) {
        console.log('The string ends with ".pdf"');
      } */
      console.log("item", { item, ev, isicon: ev.srcElement.classList.contains('client-action-icon') })
      let $this = this;
      if(ev.srcElement.classList.contains('client-action-icon')) {
        return;
      }
      this.downloadFilePreview({
        fileId: item.file.id,
        clientId: item.clientId,
      })
          .then((file) => {
            $this.fileUrl = URL.createObjectURL(file.data);
            $this.fileType = file.contentType;
            $this.showModal = true; // Open the modal
          })
          .catch((error) => {
            console.error('Download Error:', error);
          });
    },

    closeModal() {
      this.showModal = false;
    },

    /*  downloadAndShowDocument(item) {

       let $this = this;
       this.downloadFile({
         fileId: item.file.id,
         clientId: item.clientId
       })
           .then(file => {
             $this.asyncDownloadFile(file);
           });
     }, */
    async handleKycEvent(id, event) {
      const $this = this;

      await this.handleEvent({
        id,
        event
      })
          .then((updatedKyc) => {
            const kycToUpdate = $this.kycs.find(kyc => kyc.id === updatedKyc.id);
            if (kycToUpdate) {
              // Update the properties of the found Kyc object
              Object.assign(kycToUpdate, updatedKyc);
              $this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.kycs') }));
              $this.$emit('input', $this.kycs); // Emit the updated this.kycs
            }
          })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.kycs') }), err.response.data.error);
          });
    },

    async saveComment() {
      const $this = this;

      this.selectedItem.kyc.info = this.comment;
      await this.updateKyc({
        kyc: this.selectedItem.kyc,
        id: this.selectedItem.kyc.id
      })
          .then(updatedKyc => {
            // Find the Kyc object in the this.kycs by its ID and update it
            const kycToUpdate = this.kycs.find(kyc => kyc.id === updatedKyc.id);
            if (kycToUpdate) {
              // Update the properties of the found Kyc object
              Object.assign(kycToUpdate, updatedKyc);
              $this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.kycs') }));
              $this.$emit('input', this.kycs); // Emit the updated this.kycs
              $this.isCommentModalVisible = false;
              $this.selectedItem = {}; // Clear the selected item
              $this.comment = ''; // Clear the comment field
            }
          })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.kycs') }), err.response.data.error);
          });
    }
    ,

    handleOpenClientMonetary(client) {

      this.selectedClientId = client.id;

      console.log('selectedClientId :', this.selectedClientId);
      this.isClientMonetaryModalVisible = true;
    },

    openCommentModal(item) {
      this.selectedItem = {
        kyc: item,
        client: this.clients[item.clientId]
      }; // Store the selected item
      this.title = this.getTitle();

      this.isCommentModalVisible = true;
    },

    closeCommentModal() {
      this.selectedItem = {}; // Clear the selected item
      this.isCommentModalVisible = false;
      this.title = '';
      this.comment = ''; // Clear the comment field
    },

    getTitle() {
      let client = { ...this.selectedItem.client };
      return 'Edit document for ' + client.firstName + ' ' + client.lastName;

    },

    approveAction(item) {
      // Handle the Approve action here
      console.log('Approve clicked for item:', item);
    },
    fakeApproveAction(item) {
      // Handle the Fake Approve action here
      console.log('Fake Approve clicked for item:', item);
    },
    declineAction(item) {
      // Handle the Decline action here
      console.log('Decline clicked for item:', item);
    },
    getStatusColor(value) {
      if (value === 'APPROVED') {
        return { color: 'green' }; // Apply green text color for success
      } else if (value === 'PENDING') {
        return { color: 'orange' }; // Apply orange text color for warning
      } else if (value === 'DECLINED') {
        return { color: 'red' }; // Apply red text color for danger
      } else {
        return {}; // No inline style for the default text color
      }
    },
    getStatusVariant(status) {
      switch (status) {
        case 'PENDING':
          return 'warning';
        case 'APPROVED':
          return 'success';
        case 'DECLINED':
          return 'danger';
        default:
          return 'primary';
      }
    },
    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }

      console.log('refresh kyc filter: ', this.filters);
      // console.log("refresh()", {  pagination: this.pagination, sort})
      const result = await this.getAllKyc(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.kycs') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.kycs = result.data['kycs'];
      this.clients = result.data['clients'].reduce((map, obj) => (map[obj.id] = obj, map), {});
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    }
  }
};
</script>

<style scoped>
.custom-modal {
  min-width: 500px; /* Adjust the width as needed */
}

.btn-container .btn {
  margin-right: 2px;
}
</style>
