import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    branchId: other.branchId || null,
    orgId: other.orgId || null,
    active: other.active || null,
    language: other.language || null,
    subject: other.subject || null,
    mailEvent: other.mailEvent || null,
    //Search
    search: other.search || '',
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
