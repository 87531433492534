<template>
  <b-modal v-model="active" centered hide-footer :title="modalTitle"  size="lg" @ok="saveTransaction"
           @cancel="closeModal" cancel-variant="outline-danger" @close="closeModal">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Account">
          <v-select v-model="newTransaction.accountId" :clearable="false"
                    v-bind:options="defaultAndAccounts"
                    :reduce="s=>s.id"
                    label="displayLabel"
                    @input="onAccountSelected"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Amount">
          <b-form-input v-model="newTransaction.amount" type="number" v-bind:disabled="!accountIsSet"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Currency">
          <b-form-input v-bind:value="newTransaction.currency" disabled></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group >
          <b-form-checkbox  v-model="newTransaction.action" class="mt-3"  value="CREDIT" unchecked-value="PAYMENT" v-bind:disabled="!accountIsSet">
           {{$t('monetary.credit')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Status">
          <v-select v-model="newTransaction.status" :clearable="false"
                    :options="$store.getters['data/allMonetaryStatus']"
                    v-bind:disabled="!accountIsSet || isNotRegisteredToClient"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="PSP">
          <v-select v-model="newTransaction.paymentDetails.processorName" :clearable="false"
                    :options="pspOptions" v-bind:disabled="!accountIsSet || isNotRegisteredToClient"
          ></v-select>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              id="radio-group-2"
              v-model="newTransaction.realType"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
          >
          <b-form-radio value="REAL" :title="$t('monetary.realRegisterDesc')" v-b-tooltip>{{$t('monetary.realRegister')}}</b-form-radio>
          <b-form-radio value="FAKE" :title="$t('monetary.notRegisterDesc')"  v-b-tooltip>{{$t('monetary.notRegister')}}</b-form-radio>
          <b-form-radio value="CORE_ONLY" :title="$t('monetary.tradeOnlyDesc')"  v-b-tooltip>{{$t('monetary.tradeOnly')}}</b-form-radio>
          <b-form-radio value="SYNC_CORE_ONLY" :title="$t('monetary.tradeSyncOnlyDesc')"  v-b-tooltip>{{$t('monetary.tradeSyncOnly')}}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Description">
          <b-form-textarea v-model="newTransaction.description" v-bind:disabled="!accountIsSet"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col cols="6">
        <b-button block variant="danger" @click="saveTransaction('WITHDRAWAL')"  v-bind:disabled="busy">{{$t('monetary.withdrawal')}} </b-button>
      </b-col>
      <b-col cols="6">
        <b-button block variant="success" @click="saveTransaction('DEPOSIT')" v-bind:disabled="busy">{{$t('monetary.deposit')}}</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'

const getNewTransaction = ()=>({
  clientId: null,
  accountId: null,
  paymentDetails: {processorName:"Credit & Debit Card"},
  realType: "REAL",
  action:"PAYMENT",
  status:"APPROVED",
  psp:"Credit & Debit Card",

})

export default {
  name: 'monetaryAccountModal',
  mixins:[mixinBase, mixinNotifications],
  data:()=>({
    clientId: null,
    busy: false,
    modalTitle: 'Add Transaction',
    accounts: [],
    active: false,
    typeOptions: ['DEPOSIT', 'WITHDRAWAL'],
    realOptions: ['REAL', 'FAKE'],
    pspOptions: ['Bank Transfer', 'Non registered PSP','Credit & Debit Card','BTC','ETH','Byond Byond Pay'], // Example options, replace with your options
    currencyOptions: ['USD', 'EUR', 'BTC'], // Example options, replace with your options
    newTransaction: getNewTransaction(),
  }),
  computed:{
    accountIsSet(){
      return  this.newTransaction.accountId != null;
    },
    isNotRegisteredToClient(){
      return this.newTransaction.realType !== 'REAL' && this.newTransaction.realType !== 'FAKE';
    },
    defaultAndAccounts(){
      if(!Array.isArray(this.accounts) || this.accounts.length <= 0){
        return [{id: "0", displayLabel: 'No Account', currency: "EUR"}]
      }
      return [{id: "0", displayLabel: 'No Account', currency: "EUR"}, ...this.accounts]
    }
  },
  methods:{
    ...mapActions('monetary', ['createMonetary']),

    ...mapActions('account', ['getAccountsByClient']),
    showModal(clientId){
      this.clientId = clientId;
      this.newTransaction['clientId'] = this.clientId;
      this.active = true;
      this.refresh();
    },
    async refresh() {
      let $this = this;
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }
      this.accounts = await this.getAccountsByClient(this.clientId);
      this.accounts = this.reGenerateAccounts(this.accounts);
    },
    reGenerateAccounts(accounts) {
      return accounts.map(account => {
        const label = account.isDemo ? `${account.id} Demo-` : `${account.id} Real-`;
        const displayLabel = `${label} ${account.balance} ${account.currency}`;
        return {
          ...account,
          displayLabel
        }; // Spread account fields and add displayLabel
      });
    },
    onAccountSelected(accId){
      let account = this.defaultAndAccounts.find(acc=>acc.id === accId);
      if(account == null){
        return
      }
      this.newTransaction.currency = account.currency
    },
    async saveTransaction(type) {
      this.busy = true;
      let $this = this
      let newTransaction = {...this.newTransaction};
      if(newTransaction.accountId === "0"){
        newTransaction.accountId = null;
      }
      newTransaction.type = type
      this.createMonetary({
        monetary: newTransaction
      })
          .then(() => {
            $this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.monetaries') }))
            $this.refreshTables()
            $this.closeModal()
          }, err => {
            $this.showServerErrorNotification('createDataError', 'monetary', err)
          }).finally(() => $this.busy = false);

    },
    onRealTypeChange(){
      if(this.newTransaction.realType === 'FAKE'){
        this.newTransaction.paymentDetails.processorName = null;
        this.newTransaction.status = 'APPROVED';
      }
    },
    closeModal() {
      this.active = false;
      this.newTransaction = getNewTransaction();
    },
  }
}
</script>



<style scoped>

</style>
