export default {
  namespaced: true,
  state: {
    users: {},
    serverTimeDiff: 0,
    userId: 0,
    countries: [],
    organizations: {},
    affiliates: {},
    businessUnits: {},
    mailEvents: [],
    smtpProvider: [],
    monetaryAction: [],
    monetaryType: [],
    monetaryRealType: [],
    monetaryStatus: []
  },
  getters: {
    allCountries: state => state.countries,
    allMailEvents: state => state.mailEvents,
    allSmtpProviders: state => state.smtpProvider,
    allMonetaryStatus: state => state.monetaryStatus.filter(field => field !== 'REQUEST'),
    allMonetaryActions: state => state.monetaryAction,
    allMonetaryTypes: state => state.monetaryType,
    allMonetaryRealTypes: state => state.monetaryRealType,
    getUserFullName: (state) => (uId) => {
      let u = state.users[uId]
      if (u == null) {
        return 'Unknown'
      }
      return ((u.firstName || '') + ' ' + (u.lastName || '')).trim()
    },
    getUserAvatar: (state) => (uId) => {
      let u = state.users[uId]
      if (u == null || u.avatar == null) {
        return null
      }
      return require(`@/assets/images/avatars/${u.avatar || '1'}.png`);
    },
    getUserAcronyms: (state) => (uId, points) => {
      let u = state.users[uId]
      if (u == null) {
        return 'U' + (points === true ? '.' : '')
      }
      let fn = (u.firstName || '').trim()
      let ln = u.lastName != null && u.lastName.trim() !== '' ? u.lastName.trim()
        .charAt(0)
        .toUpperCase() : ''
      if (ln !== '') {
        return fn.charAt(0)
          .toUpperCase() + (points === true ? '.' : '') + ln
      } else {
        return fn.charAt(0)
          .toUpperCase() + (points === true ? '.' : '')
      }
    },
    getOtherUsers(state) {
      return Object.values(state.users)
    },
    allOrganizations(state) {
      return Object.values(state.organizations || {})
    },
    anyOrganization(state) {
        return Object.values(state.organizations || {})[0] || {}
    },
    allAffiliates(state) {
      return Object.values(state.affiliates || {})
    },
    hasManyOrganizations(state) {
      return Object.values(state.organizations || {}).length > 1
    },
    hasManyBusinessUnits(state) {
        return Object.values(state.businessUnits || {}).length > 1
    },
    allUsers(state) {
      return Object.values(state.users || {})
    },
    getOrganizationById: (state) => (id) => {
      return state.organizations[id] || {}
    },
    allBusinessUnits(state) {
      return Object.values(state.businessUnits || {})
    },
    getBusinessUnitById: (state) => (id) => {
      return state.businessUnits[id] || {}
    } ,
    getBusinessUnitNameById: (state) => (id) => {
      return state.businessUnits[id].name || {}
    } ,
    getOrganizationNameById: (state) => (id) => {
      return state.organizations[id]?.name || ''
    } ,
    getOrganizationCrmId: (state) => (id) => {
      return state.organizations[id]?.crm || ''
    } ,
    getAffiliateNameById: (state) => (id) => {
      return state.affiliates[id]?.company || ''
    } ,
    getUserById: (state) => (id) => {
      return state.users[id] || {}
    },
  },
  mutations: {
    update(state, data) {
      let keys = ['serverTimeDiff', 'users', 'userId', 'countries',
        'organizations', 'businessUnits','affiliates', 'admin', 'mailEvents',
        'smtpProvider','monetaryStatus','monetaryAction','monetaryType', 'monetaryRealType']
      if (Array.isArray(data.users)) {
        data.users = data.users.reduce((acc, user) => Object.assign(acc, { [user.id]: user }), {})
      }
      keys.forEach(key => {
        if (data[key] != null) {
          state[key] = data[key]
        }
      })
      if (Array.isArray(data.permissions)) {
        state.permissions = new Set(data.permissions)
      }
    },
  },
  actions: {}

}
