export default function () {
  return {
    id: null,

    //Details fields
    firstName: null,
    lastName: null,
    phone: null,
    mobile: null,
    country: null,
    city: null,
    address: null,
    zip: null,
    lang: null,
    email: null,
    email2: null,
    birthday: null,
    gender: null,
    companyClient: null,
    password: null,
    time: null,
    isSale: null,

    //Manager fields
    orgId: null,
    businessUnitId: null,
    creationTime: null,
    interestTime: null,
    ftdTime: null,
    stdTime: null,
    ftdAmount: null,

    //Document fields
    docNum: null,
    docType: null,
    docCountry: null,
    statusTime: null,
    statusId: null,

    //Affiliate fields
    affiliateId: null,
    campaign: null,
    banner: null,
    tag: null,

    //Representative fields
    ftdOwnerId: null,
    stdOwnerId: null,
    representativeUserId: null,
    client2UserLoggers: null,
    lastAssignationTime: null,

    //Company fields
    companyNumber: null,
    activity: null,
    position: null,
    companyName: null,

    comment: null,
    active: null,
    lastNote: null,
    marginCall: null,
    accounts: null,
    account: null,
    hasAccount: null,

    lastUpdate: null,
  }
}

