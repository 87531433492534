import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/index'
import Permissions from '@/model/Permissions'

Vue.use(VueRouter)

const permissions = Permissions()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/MainDashboard.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/calendar/CalendarView.vue'),
      meta: {
        pageTitle: 'Clients',
        permissions: [permissions.CALENDAR_READ],
        breadcrumb: [
          {
            text: 'Clients',
            active: true,
          },
        ],
      },
    },
    {
      path: '/hierarchy',
      name: 'hierarchy',
      component: () => import('@/views/hierarchy/HierarchyView2.vue'),
      meta: {
        pageTitle: 'Hierarchy',
        breadcrumb: [
          {
            text: 'Hierarchy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/clients/ClientsView.vue'),
      meta: {
        pageTitle: 'Clients',
        pageKey: 'all',
        permissions: [permissions.CLIENT_READ_ALL],
        breadcrumb: [
          {
            text: 'Clients',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/sales',
      name: 'sales',
      component: () => import('@/views/clients/ClientsView.vue'),
      meta: {
        pageTitle: 'Sales',
        pageKey: 'sales',
        departments: ['SALES'],
        permissions: [permissions.CLIENT_READ],
        breadcrumb: [
          {
            text: 'Sales',
            active: true,
          },
        ],
      },
    },{
      path: '/clients/retention',
      name: 'retention',
      component: () => import('@/views/clients/ClientsView.vue'),
      meta: {
        pageTitle: 'Retentions',
        pageName: 'Retentions',
        pageKey: 'retentions',
        permissions: [permissions.CALENDAR_READ_RETENTION, permissions.CLIENT_READ_ALL],
        departments: ['RETENTION'],
        breadcrumb: [
          {
            text: 'Retentions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/affiliates',
      name: 'affiliates',
      component: () => import('@/views/affiliates/AffiliatesView.vue'),
      meta: {
        pageTitle: 'Affiliates',
        permissions: [permissions.AFFILIATE_READ_ALL],
        breadcrumb: [
          {
            text: 'Affiliates',
            active: true,
          },
        ],
      },
    },
    {
      path: '/monetaries',
      name: 'monetaries',
      component: () => import('@/views/monetaries/MonetaryView.vue'),
      meta: {
        pageTitle: 'Monetary transactions',
        permissions: [permissions.MONETARY_READ_ALL],
        breadcrumb: [
          {
            text: 'Monetary transactions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: () => import('@/views/accounts/AccountView.vue'),
      meta: {
        pageTitle: 'CFD Accounts',
        permissions: [permissions.ACCOUNT_READ_ALL],
        breadcrumb: [
          {
            text: 'CFD Accounts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/monetaries/withdrawalRequests',
      name: 'withdrawalRequests',
      component: () => import('@/views/monetaries/WithdrawView.vue'),
      meta: {
        pageTitle: 'Withdrawal Requests',
        permissions: [permissions.MONETARY_WITHDRAWAL_REQUEST_READ],
        breadcrumb: [
          {
            text: 'Withdrawal Requests',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/kycs',
      name: 'kycs',
      component: () => import('@/views/kyc/KycView.vue'),
      meta: {
        pageTitle: 'Kycs',
        permissions: [permissions.KYC_READ_ALL],
        breadcrumb: [
          {
            text: 'Kycs',
            active: true,
          },
        ],
      },
    },  {
      path: '/configurations/clientStatus',
      name: 'clientStatus',
      component: () => import('@/views/clientStatus/ClientStatusView.vue'),
      meta: {
        pageTitle: 'Client status',
        permissions: [permissions.CLIENT_STATUS_READ_ALL],
        breadcrumb: [
          {
            text: 'Client status',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/trading-system/symbols',
      name: 'symbols',
      component: () => import('@/views/symbol/SymbolView.vue'),
      meta: {
        pageTitle: 'Symbols',
        permissions: [permissions.TRADING_SYSTEM_SYMBOLS_UPDATE],
        breadcrumb: [
          {
            text: 'Symbols',
            active: true,
          },
        ],
      },
    }, {
      path: '/configurations/trading-system/symbolGroups',
      name: 'symbolGroups',
      component: () => import('@/views/symbolGroup/SymbolGroupView.vue'),
      meta: {
        pageTitle: 'Symbol groups',
        permissions: [permissions.TRADING_SYSTEM_SYMBOLS_GROUPS_UPDATE],
        breadcrumb: [
          {
            text: 'Symbol groups',
            active: true,
          },
        ],
      },
    }, {
      path: '/trades/closed',
      name: 'closedTrades',
      component: () => import('@/views/trades/ClosedTradesView.vue'),
      meta: {
        pageTitle: 'CFD Orders History',
        permissions: [permissions.TRADING_READ_ALL],
        breadcrumb: [
          {
            text: 'CFD Orders History',
            active: true,
          },
        ],
      },
    }, {
      path: '/trades/open',
      name: 'trades',
      component: () => import('@/views/trades/OpenTradesView.vue'),
      meta: {
        pageTitle: 'Open CFD Orders',
        permissions: [permissions.TRADING_READ_ALL],
        breadcrumb: [
          {
            text: 'Open CFD Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/smtps',
      name: 'smtps',
      component: () => import('@/views/smtps/SmtpsView.vue'),
      meta: {
        pageTitle: 'Smtps',
        permissions: [permissions.SMTP_READ_ALL],
        breadcrumb: [
          {
            text: 'Smtps',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/roles',
      name: 'roles',
      component: () => import('@/views/roles/RolesView.vue'),
      meta: {
        pageTitle: 'Roles',
        permissions: [permissions.USER_ROLES_READ_ALL],
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/users',
      name: 'users',
      component: () => import('@/views/users/UsersView.vue'),
      meta: {
        pageTitle: 'Users',
        permissions: [permissions.USER_READ_ALL],
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/organizations',
      name: 'organizations',
      component: () => import('@/views/organizations/OrganizationsView.vue'),
      meta: {
        pageTitle: 'Organizations',
        permissions: [permissions.ORG_READ_ALL],
        breadcrumb: [
          {
            text: 'organization',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/businessesUnits',
      name: 'businessUnit',
      component: () => import('@/views/businessUnits/BusinessUnitsView.vue'),
      meta: {
        pageTitle: 'Businesses Units',
        permissions: [permissions.BU_READ_ALL],
        breadcrumb: [
          {
            text: 'business Unit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/support',
      name: 'config-support',
      component: () => import('@/views/Support.vue'),
      meta: {
        pageTitle: 'Bug Reporting',
        permissions: [],//permissions.USER_READ_ALL
        breadcrumb: [
          {
            text: 'report all bugs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sales/dashboard',
      name: 'salesDashboard',
      component: () => import('@/views/reports/SalesDashboard.vue'),
      meta: {
        pageTitle: 'Sales Dashboard',
        breadcrumb: [
          {
            text: 'Sales Dashboard',
            active: true,
          },
        ],
        permissions: [permissions.MONETARY_REPORT_READ],
      },
    }, {
      path: '/affiliate/dashboard',
      name: 'affiliateDashboard',
      component: () => import('@/views/reports/AffiliateDashboard.vue'),
      meta: {
        pageTitle: 'Affiliate Dashboard',
        breadcrumb: [
          {
            text: 'Affiliate Dashboard',
            active: true,
          },
        ],
        permissions: [permissions.MONETARY_REPORT_READ],
      },
    },
    {
      path: '/activity/user',
      name: 'userActivity',
      component: () => import('@/views/tracker/UserActivityView.vue'),
      meta: {
        pageTitle: 'User activity',
        breadcrumb: [
          {
            text: 'User activity',
            active: true,
          },
        ],
        permissions: [permissions.MONETARY_REPORT_READ],
      },
    },
    {
      path: '/configurations/mails',
      name: 'mails',
      component: () => import('@/views/mails/MailTemplatesView.vue'),
      meta: {
        pageTitle: 'Email templates',
        permissions: [permissions.MAIL_TEMPLATE_READ_ALL],
        breadcrumb: [
          {
            text: 'Email templates',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configurations/about',
      name: 'config-about',
      component: () => import('@/views/config/About.vue'),
      meta: {
        pageTitle: 'About',
        permissions: [],
        breadcrumb: [
          {
            text: 'Email templates',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  try { // Redirect to login page if user is not logged in and trying to access a restricted page
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    if (!authRequired) {
      return next();
    }
    let loggedIn = store.getters['user/loggedIn']
    if (!loggedIn) {
      await store.dispatch('user/checkConnection')
      loggedIn = store.getters['user/loggedIn']
    }
    if (!loggedIn) {
      return next('/login?returnUrl=' + to.fullPath)
    }
    checkPermission(to, next)
  } catch (e) {
    console.log(e)
    return next('/login?returnUrl=' + to.fullPath)
  }
})

function checkPermission(to, next) {
  const permission = to.meta.permissions
  if (!Array.isArray(permission)) {
    return next();
  }
  if (permission.some(p => !store.getters['user/hasPermission'](p))) {
    return next('/error-404?notAuthorized=true')
  }
  next()
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none';
  }
})

export default router
