<template>
  <b-avatar
      v-bind:size="size"
      v-bind:variant="variant"
      v-bind:badge="badge"
      v-bind:badge-variant="badge ? badgeVariant : null"
      :src="getUserAvatar(userId)"
      class="badge-minimal"
      v-b-tooltip
      :text="getUserAcronyms(userId)"
      :title="getUserFullName(userId)"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserAvatar',
  props: {
    userId: {
      type: String,
      default: null
    },
    size: {
      type: [String, Number],
      default: '40'
    },
    variant: {
      type: String,
      default: ()=> 'light-primary'
    },
    badge:{
      type: Boolean,
      default: ()=> false
    },
    badgeVariant:{
      type: String,
      default: ()=> 'danger'
    }
  },
  computed: {
    ...mapGetters('data', ['getUserFullName', 'getUserAvatar', 'getUserAcronyms'])
  },
}
</script>

<style scoped>

</style>
