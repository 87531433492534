<template>
  <v-select v-bind:options="allStatus" :reduce="u=>u.id" label="status"
            v-model="selected" :disabled="disabled"
            v-bind:readonly="readonly"
  >
  </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserAvatar from '@/components/widget/UserAvatar';
import { vue } from '@/main';

export default {
  name: 'clientStatusPicker',
  components: { UserAvatar },
  props: {
    value: {
      required: true,
    },
    selectProps: {
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    excludeMe: {
      type: Boolean,
      default: () => false
    },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    search: '',
    allStatus: [],
  }),
  computed: {
    ...mapGetters('user', ['myId']),
    ...mapGetters('clients', ['allStatuses']),
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
  async created() {

    this.allStatus =this.allStatuses;

  },
  methods: {
    ...mapActions('clientStatus', ['getAllClientStatus']),
  }
};
</script>

<style scoped>

</style>
