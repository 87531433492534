import api from '@/api'
import UserSearchRequest from '@/model/user/UserSearchRequest';
import UserRequest from '@/model/user/UserRequest';
import BusinessUnitSearchRequest from '@/model/businessUnit/BusinessUnitSearchRequest';

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    refreshData({ commit }) {
      return api.base()
        .get('/business-unit/data')
        .then(
          response => {
            commit('updateData', response.data.data);
          }
        );
    },
    getAllBusinessesUnits(actions, {filter, pagination, sort }) {
      console.log("in the get data");
      return api.base()
        .post('/business-unit/get',BusinessUnitSearchRequest({ ...(filter || {}), pagination,
          sort, }))
        .then( response => ({
          data: response.data.data,
            pagination: {
            page: response.data.metadata.page,
              total: response.data.metadata.total
          },
          sort: response.data.metadata.sort
          })
        )
    },
    getBusinessUnitById({commit}, id){
      console.log("in bu get by id. the id", id);
      return api.base()
        .get('/business-unit/id/' + id)
        .then(
          response => {
            return response.data.data
          }
        )
    },
    getHierarchy({commit}){
      return api.base()
        .get('/business-unit/hierarchy')
        .then(
          response => {
            return response.data.data
          }
        )
    },
    deleteBusinessUnitById({commit}, id){
      console.log("in bu delete by id. the id", id);
      return api.base()
        .get('/business-unit/delete/' + id)
        .then(
          response => {
            return response.data.data
          }
        )
    },
    updateBusinessUnit({ commit }, { businessUnit, id }) {
      console.log("id", id);
      return api.base()
        .post('/business-unit/update/'+id, businessUnit)
        .then(
          response => {
            console.log("response", response.data);
            return response.data.data
          }
        )
    },
    addNewBusinessUnit({ commit }, { businessUnit }) {
      console.log("business to add", businessUnit);
      return api.base()
        .post('/business-unit/add', businessUnit)
        .then(
          response => {
            return response.data.data
          }
        )
    }


  },
}
