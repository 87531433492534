<template>
  <v-select ref="users-select" :class="{'small': small}" v-bind:options="users" :clearable="clearable"  :multiple="multiple" :reduce="u=>u.id" label="id"
            v-bind:appendToBody="appendToBody"
            v-model="selected" :disabled="disabled" :filterable="false" @search="onSearch"
  >
    <template #option="user">
      <div class="text-capitalize">
        <user-avatar size="25" variant="info" :userId="user.id"></user-avatar>
        {{ getUserFullName(user.id) }}
      </div>
    </template>
    <template #selected-option="user">
      <div class="text-capitalize">
        <user-avatar size="15" variant="info" :userId="user.id"></user-avatar>
        {{ getUserFullName(user.id) }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAvatar from '@/components/widget/UserAvatar';

export default {
  name: 'userPicker',
  components: { UserAvatar },
  props: {
    value: {
      required: true,
    },
    small: {
      type: Boolean,
      default: () => false
    },
    selectProps: {
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    excludeMe: {
      type: Boolean,
      default: () => false
    },
    clientId: {
      type: String,
      default: null
    },
    userType: {
      type: Number,
      default: null
    },
    appendToBody: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    search: '',
    users: [],
  }),
  watch: {
    selected(newValue, oldValue) {
      if (newValue !== oldValue && this.clientId) {
        if (!((newValue == null) && (oldValue == null))) {
          this.updateRepresentativeId(this.clientId, newValue);
        }
      }
    }
  },
  computed: {
    ...mapGetters('user', ['myId']),
    ...mapGetters('data', ['allUsers', 'getUserFullName']),
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        if (value !== this.value && value != null) {
          this.updateRepresentativeId(this.clientId, value);
        }
        this.$emit('input', value);
      }
    },
  },
  created() {
    this.refreshUsers();
  },
  methods: {
    refreshUsers() {
      let $this = this;
      let users = this.allUsers.map(u => ({
        ...u,
        name: u.firstName + ' ' + (u.lastName || ''),
        value: u.id
      }));
      if (this.excludeMe) {
        users = users.filter(u => u.id !== this.myId);
      }
      if (this.search != null && this.search.trim() !== '') {
        let words = this.search.trim()
            .toLowerCase()
            .split(' ');
        users = users.filter(u => {
          if (Array.isArray($this.selected) && $this.selected.includes(u.id)) {
            return true;
          }
          if (!Array.isArray($this.selected) && $this.selected === u.id) {
            return true;
          }
          return words.some(
              w => (u.firstName || '').toLowerCase()
                      .includes(w) ||
                  (u.lastName || '').toLowerCase()
                      .includes(w) ||
                  (u.email || '').toLowerCase()
                      .includes(w)
          );
        });
      }
      this.users = users;
    },
    updateRepresentativeId(clientId, userId) {
      let userType= this.userType;
      console.log(`Updating representative ID for client ${clientId} to ${userId}`);
      // Call the parent method to handle this update
      this.$emit('update', { clientId, userId,userType });
    },
    onSearch(search, loading) {
      loading(true);
      this.search = search;
      this.refreshUsers();
      loading(false);
    }
  }
};
</script>

<style scoped>

</style>
