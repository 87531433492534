export const parseJson = (json, defaultValue) => {
    let response = null;
    try {
        response = JSON.parse(json);
    } catch (e) {
        response = null;
    }
   return response || defaultValue;
}

export const camelize = function(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export function initialName(words) {
    if(words == null) return ''
    return words
      .replace(/\b(\w)\w+/g, '$1.')
      .replace(/\s/g, '')
      .replace(/\.$/, '')
      .toUpperCase();
}

export const toObject = (arr, keyField) =>{
    return arr.reduce((obj, item) => {
        obj[item[keyField]] = item
        return obj
    }, {})
}


export function findUnusedKeys(target, source) {
    let unused = [];
    for (let key in source) {
        if (target[key] === undefined) {
            unused.push(key);
        }
    }
    return unused;
}

export function logUnusedKeys(type, target, source) {
    let unused = findUnusedKeys(target, source);
    if (unused.length > 0) {
        console.warn(`Unused keys for type ${type}: `, unused);
    }
    return target;
}

export function isEmptyList(list) {
    return list == null || list.length === 0;
}


// Returns a function, that as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being executed for
// `wait` milliseconds.
// https://skilled.dev/course/debounce
export const Debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

