export const TradeCmdEnum = {
  BUY: 'BUY',
  SELL: 'SELL',
  BUY_LIMIT: 'BUY_LIMIT',
  SELL_LIMIT: 'SELL_LIMIT',
  BUY_STOP: 'BUY_STOP',
  SELL_STOP: 'SELL_STOP',
}

export const TradeUpdateCmdEnum = {
  BUY: 'BUY',
  SELL: 'SELL'
}

export const TradeCmdEnrichedEnum = {
  BUY: { label: 'BUY', textClass: 'text-success', icon: "fa-solid fa-up-long" },
  SELL: { label: 'SELL', textClass: 'text-danger', icon: "fa-solid fa-down-long" },
  BUY_LIMIT: { label: 'BUY_LIMIT', textClass: 'text-success', icon: "fa-solid fa-arrows-up-to-line" },
  SELL_LIMIT: { label: 'SELL_LIMIT', textClass: 'text-danger', icon: "fa-solid fa-arrows-down-to-line" },
  BUY_STOP: { label: 'BUY_STOP', textClass: 'text-success', icon: "fa-solid fa-arrow-trend-up" },
  SELL_STOP: { label: 'SELL_STOP', textClass: 'text-danger', icon: "fa-solid fa-arrow-trend-down" },
}

export const getTradeCmdEnrichedEnum = (cmd) => {
  return TradeCmdEnrichedEnum[cmd]
}

export const isSell = (cmd) => {
  return cmd === TradeCmdEnum.SELL || cmd === TradeCmdEnum.SELL_LIMIT || cmd === TradeCmdEnum.SELL_STOP
}
export const isBuy = (cmd) => {
    return cmd === TradeCmdEnum.BUY || cmd === TradeCmdEnum.BUY_LIMIT || cmd === TradeCmdEnum.BUY_STOP
}
