<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-user"></i>
          {{ $t('tabs.clients.details') }}
        </h4>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.firstName') }}</label>
        <b-form-input v-model="client.firstName" :state="stateFirstName" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.lastName') }}</label>
        <b-form-input v-model="client.lastName" :state="stateLastName" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4">
        <b-form-group :state="stateCountry">
        <label>{{ $t('columns.country') }}</label>
        <countries-select-box v-model="client.country" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.phone') }}</label>
        <b-input-group>
          <b-form-input v-model="client.phone" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.mobile') }}</label>
        <b-input-group>
          <b-form-input v-model="client.mobile" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.birthday') }}</label>
        <b-form-input v-model="client.birthday" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.email') }}</label>
        <b-input-group>
          <b-form-input :state="stateEmail" v-model="client.email" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.email2') }}</label>
        <b-form-input v-model="client.email2" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.address') }}</label>
        <b-form-input v-model="client.address" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.zip') }}</label>
        <b-form-input v-model="client.zip" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.city') }}</label>
        <b-form-input v-model="client.city" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>

      <b-col cols="4">
        <label>{{ $t('columns.gender') }}</label>
        <b-form-input v-model="client.gender" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="12">
        <label>{{ $t('columns.AdditionalInfoAndExportNote') }}</label>
        <b-form-input v-model="client.statusId" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-building-circle-exclamation"></i>
          {{ $t('tabs.clients.management') }}
        </h4>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.businessUnitId') }}</label>
        <business-unit-select-box v-model="client.businessUnitId" :disabled="!hasPermission(permissions.CLIENT_UPDATE_BU)"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.orgId') }}</label>
        <organization-select-box v-model="client.orgId" :disabled="!hasPermission(permissions.CLIENT_UPDATE_ORG)"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.statusId') }}</label>
        <b-form-input v-model="client.statusId" :disabled="!hasPermission(permissions.CLIENT_UPDATE_DETAILS)"/>
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="hasPermission(permissions.CLIENT_READ_AFFILIATE)">
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-person-shelter"></i>
          {{ $t('tabs.clients.affiliate') }}
        </h4>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.affiliateId') }}</label>
        <b-form-input v-model="client.affiliateId"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.campaign') }}</label>
        <b-form-input v-model="client.campaign"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.banner') }}</label>
        <b-form-input v-model="client.banner"/>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('columns.tag') }}</label>
        <b-form-input v-model="client.tag"/>
      </b-col>
    </b-row>
    <div class="text-center mt-2">
      <b-button block size="md" variant="primary" @click="update">{{$t('operations.save')}}</b-button>
    </div>
  </div>
</template>

<script>
import Client from '@/model/client/Client'
import mixinPermissions from '@/components/mixin/mixinPermissions'
import { mapActions } from 'vuex'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox'
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox'
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox'
import mixinBase from '@/components/mixin/mixinBase'
import UserPicker from '@/components/widget/picker/userPicker'

export default {
  name: 'ClientNewDetails',
  components: { UserPicker, CountriesSelectBox, BusinessUnitSelectBox, OrganizationSelectBox },
  mixins: [mixinBase, mixinPermissions, mixinNotifications],
  data: ()=>({
    client: Client(),
    mandatoryFieldState: {"stateFirstName": false, "stateLastName": false,
      "stateEmail": false, "stateCountry": false},
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    }
  },
  watch:{
    value:{
      handler(){
        this.client = Object.assign(Client(), this.value)
      },
      immediate: true
    }
  },
  computed: {
    stateFirstName() {
      if(this.client === null || this.client.firstName===null || this.client.firstName.length < 1) {
        this.mandatoryFieldState.stateFirstName = false;
        return false;
      }
      else {
        this.mandatoryFieldState.stateFirstName =true;
        return null;
      }
    },
    stateLastName() {
      if(this.client === null ||this.client.lastName===null || this.client.lastName.length < 1) {
        this.mandatoryFieldState.stateLastName = false;
        return false;
      }
      else {
        this.mandatoryFieldState.stateLastName = true
        return null;
      }
    },
    stateEmail() {
      if(this.client === null ||this.client.email===null ||
          !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.client.email))) {
        this.mandatoryFieldState.stateEmail = false;
        return false;
      }
      else {
        this.mandatoryFieldState.stateEmail = true;
        return null;
      }
    },
    stateCountry() {
      if(this.client === null || this.client.country == null) {
        this.mandatoryFieldState.stateCountry = false;
        return false;
      }
      this.mandatoryFieldState.stateCountry = true;
      return null;
    },
  },
  methods:{
    ...mapActions('clients', ['createClient']),
    async update(){
      let neededField = this.checkMandatory();
      if (!neededField)
        return;

      let $this = this;
      try{
        let client = await this.createClient({
          client: this.client,
        })
        this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', {system:$this.$t('menu.clients')}));
        this.$emit('input', client)
        this.$emit('updated', client.id)
        this.refreshTables();
      }catch (err){
        $this.showServerErrorNotification('addDataError', 'clients', err);
      }
    },
    checkMandatory(){
      for(let key in this.mandatoryFieldState){
        if (this.mandatoryFieldState[key]=== false) {
          console.log("state is false", this.mandatoryFieldState)
          console.log("the client", this.client)
          return false;
        }
      }
      console.log("state is true")
      return true;
    },

  }
}
</script>

<style scoped>

</style>
