export default function (other) {
  other = other || {}
  return {
    branchId: other.branchId || null,
    orgId: other.orgId || null,
    creationTime: other.creationTime || null,
    subject: other.subject || null,
    body: other.body || null,
    active: other.active || null,
    language: other.language || null,
    mailEvent: other.mailEvent || null,

  }
}
