export default function (other) {
  other = other || {}
  return {
    ids: other.ids,
    symbol: other.symbol,
    symbols: other.symbols,
    pagination: other.pagination,
    description: other.description,
    types: other.types,
    activeOnly: other.activeOnly,
  }
}
