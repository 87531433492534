import mixinBase from '@/components/mixin/mixinBase'

export default {
  mixins: [mixinBase],
  computed: {},
  methods: {
    showSuccessNotification(title, message, stickId) {
      this.$root.$emit('system::notification', {
        title: title,
        message: message,
        stickId: stickId,
        variant: 'success'
      })
    },
    showErrorNotification(title, message, stickId, error) {
      console.warn('showErrorNotification', { title, message, error })
      this.$root.$emit('system::notification', {
        title: title,
        message: message,
        stickId: stickId,
        variant: 'danger'
      })
    },
    showWarningNotification(title, message, stickId) {
        this.$root.$emit('system::notification', {
            title: title,
            message: message,
            stickId: stickId,
            variant: 'warning'
        })
    },
    showInfoNotification(title, message, stickId) {
      this.$root.$emit('system::notification', {
        title: title,
        message: message,
        stickId: stickId,
        variant: 'info'
      })
    },
    showServerSuccessNotification(operationType, system, stickId) {
      let systemName = system != null && system !== '' ? this.$t(`menu.${system}`) : ''
      let operation = this.$t(`globalMessages.${operationType}`, { system: systemName })
      this.showSuccessNotification(operation, null, stickId)
    },
    showServerErrorNotification(operationType, system, error, stickId) {
      let systemName = system != null && system !== '' ? this.$t(`menu.${system}`) : ''
      let operation = this.$t(`globalMessages.${operationType}`, { system: systemName })
      this.showErrorNotification(operation, this.getErrorDescription(error || {}), stickId, error)
    }
  }
}
