import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  setup(props) {

    const {
      refFormObserver,
      getValidationState,
    } = formValidation({}, props.clearEventData)


    return {
      // Form Validation
      refFormObserver,
      getValidationState,
    }
  },
}
