import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  // console.log('loadLocaleMessages()')
  const locales = require.context('./../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  // console.debug('loadLocaleMessages()', locales.keys())

  locales.keys().forEach(key => {
    const matched = key.split("/")
    const locale = matched[1]
    const fileName = matched[2]
    // console.debug('loadLocaleMessages()->', { matched, locale, fileName })
    if (messages[locale] == null) {
      messages[locale] = locales(key)
    } else {
      messages[locale] = { ...messages[locale], ...locales(key) }
    }
  })
  return messages
}


const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'en': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'de-AT': {
    currency: {
      style: 'currency', currency: 'EUR', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'fr-FR': {
    currency: {
      style: 'currency', currency: 'EUR', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY', useGrouping: true, currencyDisplay: 'symbol'
    },
    decimal: {
      style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  }
}

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  numberFormats: numberFormats,
  messages: loadLocaleMessages(),
})
