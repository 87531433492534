<template>
  <div>

    <div class="icon-container ">
      <i class="fa-solid fa-circle text-success client-action-icon" v-if="client.online" v-b-tooltip title="online"></i>
      <i class="fa-solid fa-circle text-danger client-action-icon" v-if="!client.online" v-b-tooltip title="offline"></i>
      <span class="icon" @mouseenter="fetchNotesDataFromDBAndOpenPopover">
        <i style="color: #dca20e" class="fa fa-note-sticky" :id="generateUniqueId"></i>
      </span>

      <span class="icon" @click="openClientMonetary()">
      <i  style="color: white; text-shadow: -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black, 1px 1px 0px black;" class="fa fa-credit-card client-action-icon"></i>
      </span>


      <a class="icon" :href="'https://api.whatsapp.com/send?phone=' + encodeURIComponent(client.phone)" target="_blank">
        <i style="color: #4CAF50" class="fab fa-whatsapp client-action-icon"></i>
      </a>

      <b-badge variant="primary">{{ client.firstName }} {{ client.lastName }}</b-badge>

    </div>

    <b-tooltip
        v-model="isPopoverOpen"
        title="Notes"
        triggers="hover"
        placement="auto"
        :target="generateUniqueId"
    >
      <div v-html="tooltipContent"></div>
    </b-tooltip>
  </div>
</template>

<script>
import VuePopover from 'vue-js-popover';
import { mapActions } from 'vuex';
import mixinBase from '@/components/mixin/mixinBase';

export default {
  name: 'ClientCell',
  components: {
    VuePopover
  },mixins:[mixinBase],
  props: {
    client: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isNotesPopoverVisible: false,
      tooltipContent: 'Loading...',
      notes: [],
      isPopoverOpen: false, // Control the visibility of the popover
    };
  },
  methods: {
    ...mapActions('notes', ['getNotes']),

    openClientMonetary(){
      this.showClient({id:this.client.id, tab:'monetary'})
      // this.$emit('open-client-monetary', this.client);

      },
    generateUniqueId() {
      return `popover-target-${Date.now()}`;
    },
    fetchNotesDataFromDBAndOpenPopover() {
      // Fetch data from the database
      console.log('fetchNotesDataFromDBAndOpenPopover');
      let $this=this;
      this.getNotes({
        clientId: this.client.id,
        pagination: {
          page: 1,
          size: 999
        }
      })
          .then((noteData) => {
            $this.notes = noteData.data;
            if ($this.notes && $this.notes.length > 0) {
              $this.tooltipContent = $this.notes
                  .map((note) => {
                    const formattedDate = new Date(note.time).toLocaleDateString(); // Format date as desired
                    return `<div style="text-align: center;">[${formattedDate}]</div><br>${note.text}`;
                  })
                  .join('<br><br><br>');
            } else {
              $this.tooltipContent = 'No notes available';
            }

            // Open the popover manually
            $this.isPopoverOpen = true;
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
    },
  },
};

</script>
<style lang="scss">
.icon-container {
  display: flex;
  gap: 10px;
  align-items: center; /* Vertically center the icons */

  .icon {
    cursor: pointer;
    font-size: 1.5rem;
    color: #6c757d;
    transition: color 0.2s ease-in-out;

    &:hover {
      transform: scale(1.3);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  }
}
</style>
