import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    order: other.order || null,
    accounts: other.accounts || null,
    symbolId: other.symbolId || null,
    command: other.command || null,
    states: other.states || null,
    lots: other.lots || null,
    openTime: other.openTime || null,
    openPrice: other.openPrice || null,
    closeTime: other.closeTime || null,
    expiration: other.expiration || null,
    sl: other.sl || null,
    tp: other.tp || null,
    convRates: other.convRates || null,
    commission: other.commission || null,
    commissionAgent: other.commissionAgent || null,
    swap: other.swap || null,
    swapSymbol: other.swapSymbol || null,
    closePrice: other.closePrice || null,
    profitSymbol: other.profitSymbol || null,
    profit: other.profit || null,
    taxes: other.taxes || null,
    comment: other.comment || null,
    externalData: other.externalData || null,
    transactionId: other.transactionId || null,
    totalProfit: other.totalProfit || null,
    //Search
    search: other.search || '',
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
