import api from '@/api';
import KycSearchRequest from '@/model/kyc/KycSearchRequest';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return Promise.resolve();

    },
    getAllKyc(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/kyc/get/all',
          KycSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getKycById({ commit }, id) {
      return api.base()
        .get('/kyc/id/' + id)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    updateKyc({ commit }, {
      kyc,
      id
    }) {
      return api.base()
        .post('/kyc/update/' + id, kyc)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    createKyc({ commit }, { kyc }) {
      return api.base()
        .post('/kyc/add', kyc)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    handleEvent({ commit }, { id, event }) {
      return api.base()
        .post('/kyc/handleEvent/' + id+'/'+event)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

  }

};
