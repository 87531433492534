import api from '@/api'

export default {
  namespaced: true,
  state: {
    statusIds: [],
    tickers: {},
    groups: [],
  },
  actions: {
    getAllRoles(actions){
        return api.base().get('/roles/get/all').then(response => response.data.data);
    },
    getById(actions, id){
          return api.base().get('/roles/id/'+id).then(response => response.data.data);
    },
    createRole(actions, role){
        return api.base().post('/roles/add', role).then(response => response.data.data);
    },
    updateRole(actions, { id, role }){
        return api.base().put('/roles/update/' + id, role).then(response => response.data.data);
    },
  }
}
