<template>
<div class="notifications"></div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'NotificationHandler',
  components: { ToastificationContent },
  created() {
    this.$root.$on('system::notification', this.showNotification);
    this.$root.$on('system::notification::notify', this.onNewNotification);
  },
  beforeDestroy() {
    this.$root.$off('system::notification', this.showNotification);
    this.$root.$off('system::notification::notify', this.onNewNotification);
  },
  methods: {
    onNewNotification(notification) {
      console.log("onNewNotification", notification);
      let $this = this;
      let base = `notifications.${notification.source}`;
      let systemNotification = {
        title: $this.$t(`${base}.${notification.content}.title`, notification.params),
        message: $this.$t(`${base}.${notification.content}.body`, notification.params),
      };
      if(this.$te(`${base}.color`)){
        systemNotification.variant = $this.$t(`${base}.color`);
      }
      if(this.$te(`${base}.icon`)){
        systemNotification.icon = $this.$t(`${base}.icon`);
      }
      if(this.$te(`${base}.iconType`)){
        systemNotification.iconType = $this.$t(`${base}.iconType`);
      }
      this.showNotification(systemNotification)
    },
    showNotification(notification) {
      let componentProps = {
        title: notification.title,
        text: notification.message,
        icon: notification.icon || (notification.variant === 'danger' ? 'AlertTriangleIcon' : 'EditIcon'),
        iconType: notification.iconType || 'feather',
        variant: notification.variant || 'success',
      }
      let toastOptions = { }
      if(notification.stickId != null && notification.stickId !== ''){
        toastOptions.id=  notification.stickId;
        toastOptions.timeout= false;
      }else {
        toastOptions.timeout= 3*1000;
      }
      console.log("showNotification", { componentProps,toastOptions });
      this.$toast({
        component: ToastificationContent,
        props:componentProps
      }, toastOptions )
    }
  }
}
</script>

<style scoped>

</style>
