<template>
<div class="global-client">
  <client-modal v-if="loggedIn" ref="client-modal" />
</div>
</template>

<script>
import clientModal from '@/components/modal/clientModal'
import { mapGetters } from 'vuex'

export default {
  name: 'globalModals',
  components: {clientModal},
  computed: {
    ...mapGetters('user', ['loggedIn']),
  },
  created() {
    this.$root.$on('system::show-client', this.showClientModal)
  },
  beforeDestroy() {
    this.$root.$off('system::show-client', this.showClientModal)
  },
  methods: {
    showClientModal(clientDetails) {
      console.log('showClientModal', clientDetails)
      if(clientDetails instanceof Object) {
        this.$refs['client-modal'].showModal(clientDetails.id, clientDetails.tab)
      } else {
        this.$refs['client-modal'].showModal(clientDetails)
      }
    },
  }
}
</script>

<style scoped>

</style>
