<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-user"></i>
          {{ $t('tabs.clients.details') }}
        </h4>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.id') }}</label>
        <b-form-input v-model="client.id" readonly/>
      </b-col>
      <b-col cols="4" xl="3">
        <b-form-group
            description="Let us know your name."
            :invalid-feedback="invalidFirstName"
            :state="stateFirstName"
        >
          <label>{{ $t('columns.firstName') }}</label>
          <b-form-input v-model="client.firstName" :state="stateFirstName" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
        </b-form-group>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.lastName') }}</label>
        <b-form-group
            description="Let us know your name."
            :invalid-feedback="invalidLastName"
            :state="stateLastName"
        >
        <b-form-input v-model="client.lastName" :state="stateLastName" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
        </b-form-group>
      </b-col>
      <b-col cols="4" xl="3">
        <b-form-group
            description="Let us know your country."
            :invalid-feedback="invalidCountry"
            :state="stateCountry"
        >
        <label>{{ $t('columns.country') }}</label>
        <countries-select-box v-model="client.country" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
        </b-form-group>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.phone') }}</label>
        <b-input-group>
          <b-form-input v-model="client.phone" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
          <b-input-group-append>
            <b-button variant="primary"
                      class="btn btn-outline-primary" target="_blank"
                      v-b-tooltip :title="$t('operations.call')"
            >
              <i class="fa-solid fa-phone-volume"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.mobile') }}</label>
        <b-input-group>
          <b-form-input v-model="client.mobile" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
          <b-input-group-append>
            <b-button variant="primary"
                      class="btn btn-outline-primary" target="_blank"
                      v-b-tooltip :title="$t('operations.call')"
            >
              <i class="fa-solid fa-phone-volume"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.email') }}</label>
        <b-form-group
            description="Let us know your email."
            :invalid-feedback="invalidEmail"
            :state="stateEmail"
        >
          <b-input-group>
          <b-form-input v-model="client.email" :state="stateEmail" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
          <b-input-group-append>
            <b-button variant="primary" @click="openEmailEditor"
                      v-b-tooltip :title="$t('operations.sendMail')"
            >
              <i class="fa-solid fa-envelope"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.email2') }}</label>
        <b-form-input v-model="client.email2" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.address') }}</label>
        <b-form-input v-model="client.address" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.zip') }}</label>
        <b-form-input v-model="client.zip" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.city') }}</label>
        <b-form-input v-model="client.city" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.birthday') }}</label>
        <b-form-input v-model="client.birthday" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.gender') }}</label>
        <b-form-input v-model="client.gender" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>

      <b-col cols="4" xl="3">
        <label>{{ $t('columns.password') }}</label>
        <b-input-group>
          <b-form-input v-model="client.password" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
          <b-input-group-append>
            <b-button-group>
              <b-button class="border-bottom" variant="outline-primary" @click="generatePassword" v-b-tooltip title="Generate Password">
                <i class="fa-solid fa-key"></i>
              </b-button>
              <b-dropdown variant="primary">
                <template #button-content>
                  <i class="fa-solid fa-envelope"></i>
                </template>
                <b-dropdown-item @click="passwordEvent(false)">Update</b-dropdown-item>
                <b-dropdown-item @click="passwordEvent(true)">Update and Send Email</b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </b-input-group-append>
        </b-input-group>
      </b-col>


    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-building-circle-exclamation"></i>
          {{ $t('tabs.clients.management') }}
        </h4>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.creationTime') }}</label>
        <b-form-input v-model="client.creationTime" readonly/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.ftdTime') }}</label>
        <b-form-input v-model="client.ftdTime" readonly/>
      </b-col>
      <b-col cols="4" xl="3" v-if="hasManyBusinessUnits">
        <label>{{ $t('columns.businessUnitId') }}</label>
        <business-unit-select-box v-model="client.businessUnitId"
                                  :disabled="!hasPermission(permissions.CLIENT_UPDATE_BU)"
        />
      </b-col>
      <b-col cols="4" xl="3" v-if="hasManyOrganizations">
        <label>{{ $t('columns.orgId') }}</label>
        <organization-select-box v-model="client.orgId" :disabled="!hasPermission(permissions.CLIENT_UPDATE_ORG)" readonly/>
      </b-col>
      <b-col cols="4" xl="3" >
        <label>{{ $t('columns.statusId') }}</label>
        <client-status-picker v-model="client.statusId" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.presaleUserId') }}</label>
        <user-picker v-model="client.presaleUserId" :disabled="!hasPermission(permissions.CLIENT_UPDATE_REPRESENTATIVE)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.representativeUserId') }}</label>
        <user-picker v-model="client.representativeUserId" :disabled="!hasPermission(permissions.CLIENT_UPDATE_REPRESENTATIVE)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.retentionUserId') }}</label>
        <user-picker v-model="client.retentionUserId" :disabled="!hasPermission(permissions.CLIENT_UPDATE_REPRESENTATIVE)"/>
      </b-col>
    </b-row>
    <b-row class="mt-5" v-if="hasPermission(permissions.CLIENT_READ_AFFILIATE)">
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-person-shelter"></i>
          {{ $t('tabs.clients.affiliate') }}
        </h4>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.affiliateId') }}</label>
        <affiliate-select-box v-model="client.affiliateId"/>
      </b-col>

      <b-col cols="4" xl="3">
        <label>{{ $t('columns.campaign') }}</label>
        <b-form-input v-model="client.campaign"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.banner') }}</label>
        <b-form-input v-model="client.banner"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.tag') }}</label>
        <b-form-input v-model="client.tag"/>
      </b-col>
    </b-row>
    <b-row class="mt-5" v-if="hasPermission(permissions.CLIENT_READ)">


      <b-col cols="6">
        <b-row>
          <b-col cols="12" title="test">
            <client-events ref="events" v-bind:client-id="client.id" autoload/>
          </b-col>
        </b-row>
      </b-col>


      <b-col cols="6">
        <b-row class="justify-content-end">
          <b-col cols="12">
            <client-notes ref="notes" v-bind:client-id="client.id" autoload/>
          </b-col>
        </b-row>
      </b-col>

    </b-row>


    <email-editor-modal @emailTemplateSaved="sendEmail" ref="emailEditorModal" :receiver="client"></email-editor-modal>
      &nbsp;
      <div class="text-center mt-2">
        <b-button block size="md" variant="primary" @click="update">{{$t('operations.save')}}</b-button>
      </div>
  </div>
</template>

<script>
import Client from '@/model/client/Client';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import { mapActions } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox';
import mixinBase from '@/components/mixin/mixinBase';
import UserPicker from '@/components/widget/picker/userPicker';
import ClientStatusPicker from '@/components/widget/picker/clientStatusPicker';
import EmailEditorModal from '@/components/partial/mailTemplates/EmailEditorModal';
import ClientNotes from '@/components/partial/notes/notes';
import ClientEvents from '@/components/partial/notes/events';
import AffiliateSelectBox from '@/components/widget/select/AffiliateSelectBox.vue';

export default {
  name: 'ClientEditDetails',
  components: {
    ClientNotes,
    ClientEvents,
    UserPicker,
    EmailEditorModal,
    CountriesSelectBox,
    BusinessUnitSelectBox,
    OrganizationSelectBox,
    AffiliateSelectBox,
    ClientStatusPicker
  },
  mixins: [mixinBase, mixinPermissions, mixinNotifications],
  data: () => ({
    client: Client(),
    sendEmailPassword: false,
    mandatoryFieldState: {"stateFirstName": false, "stateLastName": false,
      "stateEmail": false, "stateCountry": false},
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    }
  },
  watch: {
    value: {
      handler() {
        this.client = Object.assign(Client(), this.value);
      },
      immediate: true
    }
  },
  computed: {
    stateFirstName() {
      if(this.client === null || this.client.firstName===null || this.client.firstName.length < 1) {
        this.mandatoryFieldState.stateFirstName = false;
        return false;
      }
      else {
        this.mandatoryFieldState.stateFirstName = true
        return  null;
      }
    },
    stateLastName() {
      if(this.client === null ||this.client.lastName===null || this.client.lastName.length < 1) {
        this.mandatoryFieldState.stateLastName = false;
        return false;
      }
      else {
        this.mandatoryFieldState.stateLastName = true
        return null;
      }
    },
    stateEmail() {
      if(this.client === null ||this.client.email===null ||
          !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.client.email))) {
        this.mandatoryFieldState.stateEmail = false;
        return false;
      }
      else {
        this.mandatoryFieldState.stateEmail = true;
        return null;
      }
    },
    stateCountry() {
      if(this.client === null ||this.client.country===null || this.client.country.length < 1) {
        this.mandatoryFieldState.stateCountry = false;
        return false;
      }
      else {
        this.mandatoryFieldState.stateCountry = true
        return null;
      }
    },
    invalidFirstName() {
      if (this.client.firstName.length > 0) {
        return 'Enter at least 1 characters.'
      }
      return 'Please enter something.'
    },
    invalidLastName() {
      if (this.client.lastName.length > 0) {
        return 'Enter at least 1 characters.'
      }
      return 'Please enter something.'
    },
    invalidEmail() {
      if (this.client.email.length > 0) {
        return 'Enter at least 1 characters.'
      }
      return 'Please enter something.'
    },
    invalidCountry() {
      if (this.client.country.length > 0) {
        return 'Enter at least 1 characters.'
      }
      return 'Please enter something.'
    }
  },
  methods: {
    ...mapActions('clients', ['updateClient', 'handlePasswordEvent']),

    openEmailEditor() {
      // Show the email editor modal
      this.$refs.emailEditorModal.showModal = true;
    },
    sendEmail() {
      this.$refs.emailEditorModal.showModal = false;
    },
    generatePassword() {
      const length = 10; // Length of the generated password
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Characters to include in the password
      let newPassword = '';
      for (let i = 0; i < length; i++) {
        newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      this.client.password = newPassword; // Set the generated password to client.password
    },
    passwordEvent(sendMail) {
      let $this = this
      this.handlePasswordEvent({ clientId: this.client.id, sendMail: sendMail, password: this.client.password })
          .then((client) => {
            if(sendMail) {
              this.showSuccessNotification($this.$t('globalMessages.emailSentSuccessfully', { system: $this.$t('passwordSent') }))
            }else {
              this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.clients') }))
            }
          }, (err) => {
            $this.showErrorNotification($this.$t('globalMessages.emailSentError', { system: $this.$t('passwordSent') }), err.error + ' ' + err.params)
          })
    },

    update() {
      let neededField = this.checkMandatory();
      if (!neededField)
        return;
      let $this = this;
      this.updateClient({
        client: this.client,
        id: this.client.id
      }).then(
          () => {
            this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.clients') }));
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.clients') }), err.error + ' ' + err.params);
          });
      this.$emit('input', this.client);
      this.refreshTables();
    },
    checkMandatory(){
      for(let key in this.mandatoryFieldState){
        if (this.mandatoryFieldState[key]=== false) {
          console.log("state is false")
          return false;
        }
      }
      console.log("state is true")
      return true;
    },
  }
};
</script>

<style scoped>

</style>
