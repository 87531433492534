<template>
  <b-modal size="lg" v-model="showModal" :title="$t('Send mail to ')+ getName()"
           @ok="sendMail" @hidden="resetEditor"
  >
    <div>
      <b-row>

        <b-col cols="12">
          <label>{{ $t('columns.subject') }}</label>
          <b-form-input v-model="mailTemplate.subject"/>
        </b-col>


      </b-row>
      <b-row class="mt-2">

        <b-col cols="12" class="mb-2 mt-1">
          <b-form-group label-for="realEstate-name">
            <h5 style="color: dodgerblue">Placeholder Options</h5>
            <ul class="horizontal-list">
              <li v-for="placeholder in placeholders" :key="placeholder" @click="insertPlaceholder(placeholder)">
                {{ placeholder }}
              </li>
            </ul>
            <quill-editor
                ref="quillEditor"
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="mailTemplate.body"
                @change="onEditorChange"

            />
          </b-form-group>
        </b-col>

      </b-row>

    </div>


  </b-modal>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  data() {
    return {
      showModal: false,
      placeholders: ['#firstname#', '#lastname#', '#fullname#', '#email#', '#phone#', '#country#', '#state#', '#postcode#', '#address#',
        '#balance_total#', '#company#', '#platform#', '#link#', '#username#', '#password#', '#amount#', '#currency#'],

      mailTemplate: {
        // Initialize your mail template properties here
        subject: '',
        body: '',
        // ... other properties
      },
    };
  },
  props: {
    receiver: {
      type: Object,
      default: null,
      required: true,
    },
  },
  created() {
    this.mailTemplate.body = 'Hello ' + this.getName() + ',\n\n ';
  },
  computed: {

  },
  methods: {
    ...mapActions('email', ['sendManualMail']),
    getName() {
      if (this.receiver.fullName != null) {
        return this.receiver.fullName;
      } else {
        return this.receiver.firstName + ' ' + this.receiver.lastName;
      }

    },
    sendMail() {
      this.sendManualMail({
        email: this.mailTemplate,
        clientId: this.receiver.id
      })
          .then(() => {
            // Show a success notification
            this.showSuccessNotification(this.$t('mailTemplate.sent'));
            // Reset the editor
            this.resetEditor();
          })
          .catch(() => {
            // Show an error notification
            this.showErrorNotification(this.$t('mailTemplate.error'));
          });
    },
    resetEditor() {
      // Reset the editor state when the modal is hidden
      // For example, you can clear the form fields
      this.mailTemplate.subject = '';
      this.mailTemplate.body = 'Hello ' + this.getName() + ',\n\n';
      // ... reset other properties
    },
    save() {
      // Handle save button click
      // Emit an event to notify the parent component about the saved email template
      this.$emit('emailTemplateSaved', this.mailTemplate);
      // Close the modal
      this.showModal = false;
    },

    insertPlaceholder(placeholder) {
      const quill = this.$refs.quillEditor.quill;
      const range = quill.getSelection(true);

      if (range) {
        quill.insertText(range.index, placeholder);
        quill.setSelection(range.index + placeholder.length);
      } else {
        quill.insertText(0, placeholder);
        quill.setSelection(placeholder.length);
      }
    },
    onEditorChange({
      quill,
      html,
      text
    }) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.mailTemplate.body === html) {
        return;
      }
      this.mailTemplate.body = html;
    },
  },
};
</script>

<style scoped>

.horizontal-list {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap; /* Allow the items to wrap to the next line */
  max-width: 100%; /* Limit the width to 100% of the modal */
  margin-bottom: 10px; /* Add some space between the rows */
}

.horizontal-list li {
  margin-right: 10px;
}
</style>
