<template>
  <b-row>
    <b-col cols="4">
      <b-form-group class="m-0">
        <b-input-group size="sm"  style="width: 100%">
          <template #append>
            <div @click="dialog.showGraph = dialog.showGraph=!dialog.showGraph" class="border" style="background-color: #fafafa; cursor: pointer; width: 100%; min-width: 30px; margin-left: -10px; z-index: 9;border-radius: 0 5px 5px 0">
              <i class="fa-solid fa-chart-column" style="font-size: 16px; margin-top: 5px; padding: 0 5px"></i>
            </div>
          </template>
          <v-select class="small"
                    style="width: 90%"
                    v-model="tradeDetails.symbolId"
                    v-bind:options="symbols"
                    :reduce="s=>s.id"
                    label="name"
                    :filterBy="filterSymbol"
                    :clearable="false"
          >
            <template #option="option">
              <div class="">
                <p class="m-0 font-small-2">{{ option.name }} <span v-if="option.tickDetails != null && option.tickDetails.exchange != null">({{option.tickDetails.exchange}})</span></p>
                <p class="m-0 font-small-1 text-muted">{{ option.description }}</p>
              </div>
            </template>
          </v-select>
        </b-input-group>
        <template #description>
          <span v-if="tickerDeprecated">
          {{ lastTick.time | moment('from') }}
          </span>
        </template>
      </b-form-group>
    </b-col>
    <b-col cols="4">
      <v-select class="small" v-model="tradeDetails.command" v-bind:options="commands" :clearable="false" @input="refreshTicker">
        <template #selected-option="option">
          <div class="d-flex align-items-center">
            <i :class="[getTradeCmdEnrichedEnum(option.label).textClass, getTradeCmdEnrichedEnum(option.label).icon]"></i>
            <span class="ml-1 font-weight-bolder text-uppercase" :class="[getTradeCmdEnrichedEnum(option.label).textClass]">{{ $t(`trade.cmd.${option.label}`) }}</span>
          </div>
        </template>
        <template #option="option">
          <div class="d-flex align-items-center">
            <i :class="[getTradeCmdEnrichedEnum(option.label).textClass, getTradeCmdEnrichedEnum(option.label).icon]"></i>
            <span class="ml-1 font-weight-bolder text-uppercase" :class="[getTradeCmdEnrichedEnum(option.label).textClass]">{{ $t(`trade.cmd.${option.label}`) }}</span>
          </div>
        </template>
      </v-select>
    </b-col>
    <b-col cols="4">
      <b-form-group class="m-0">
        <template #description>
          {{ $t('trade.contractSize') }}: 1000
        </template>
        <b-input-group size="sm" append="lots" style="width: 100%">
          <b-input v-model="tradeDetails.lots"></b-input>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { isSell, TradeCmdEnum, getTradeCmdEnrichedEnum } from '@/model/trade/TradeCmdEnum'
import Moment from 'moment/moment'

export default {
  name: 'tradeOrderSymbolDataPart',
  props:{
    value:{},
    dialog:{},
    symbols:{},
    lastTick:{},
  },
  computed:{
    tradeDetails:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    },
    commands() {
      return Object.keys(TradeCmdEnum);
    },
    tickerDeprecated(){
      return this.$moment().diff(this.$moment(this.lastTick.time), 'minutes') > 60
    }
  },
  methods:{
    isSell,
    getTradeCmdEnrichedEnum,
    refreshTicker(){
      this.$emit('refreshTicker')
    },
    filterSymbol(option, label, query){
      return option.name.toLowerCase().includes(query.toLowerCase()) || option.description.toLowerCase().includes(query.toLowerCase())
    }
  }
}
</script>



<style scoped>

</style>
