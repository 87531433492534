import api from '@/api'

export default {
  namespaced: true,
  state: {
    groups: [],
    lastUpdated: null
  },
  actions: {
    getGroups({ commit },  { filter, pagination, sort }) {
      let request = { ...(filter || {}), pagination, sort }
      return api.base()
        .post('/trading/group/get/all',  request)
        .then(
          response => {
            return response.data.data
          }
        )
    },
    getGroupsBasic({ commit },  { filter, pagination, sort }) {
      let request = { ...(filter || {}), pagination, sort }
      return api.base()
        .post('/trading/group/get/basic/all',  request)
        .then(
          response => {
            return response.data.data
          }
        )
    },
  }
}
