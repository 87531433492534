export default {
  data:()=>({
    refreshKey: 0,
  }),
  created: function () {

  },
  computed:{
    _baseKey(){
      return this.refreshKey;
    },
    queryParams(){
      return this.$route.query
    }
  },
  methods: {
    refreshTables(){
      console.log("refreshing table...")
      this.$root.$emit('system::refresh-tables')
    },
    forceUpdate() {
      this.refreshKey++
    },

    pushState(data) {
      const url = new URL(window.location.href);
      Object.keys(data).forEach(key => {
        if(data[key] == null){
            url.searchParams.delete(key);
        }else {
          url.searchParams.set(key, data[key])
        }
      });
      window.history.pushState( null , null, url );
    },
    convertToEURO(someNumber, currency) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: currency || "EUR",
      }).format(someNumber);
    },
    formatWithLineBreaks(value) {
      if (!value) return "";
      return value.replace(/ /g, '<br>');
    },
    getErrorDescription(error, formBase) {
      if (error == null || error.errorId == null || !this.$te(`errorDefinition.error.${error.errorId}`)) {
        return this.$t(`errorDefinition.error.UNDEFINED`)
      }
      if (error.params == null) {
        return this.$t(`errorDefinition.error.${error.errorId}`)
      }
      let $this = this;
      let params = error.params.reduce((acc, val, index) => {
        let param = val.toLowerCase()
        if ((error.errorId === 'BAD_REQUEST' || error.errorId === 'ALREADY_EXISTS') && $this.$te(`columns.${param}`)) {
          param = $this.$t(`${formBase || 'columns'}.${param}`)
        } else if ($this.$te(`errorDefinition.params.${param}`)) {
          param = $this.$t(`errorDefinition.params.${param}`)
        }
        return Object.assign(acc, { [`param-${index}`]: param })
      }, {})
      return this.$t(`errorDefinition.error.${error.errorId}`, params)
    },
    showClient(clientId){
        this.$root.$emit('system::show-client', clientId)
    },
    asyncDownloadFile(file) {
      let link = document.createElement("a");
      console.log("getDocument", file)
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', file.fileName);
      link.href = URL.createObjectURL(file.data);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    showPrompt(title, message, onConfirm, onCancel) {
      let $this = this
      this.$bvModal.msgBoxConfirm(message, {
        title: title || $this.$t('globalMessages.pleaseConfirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value === true && onConfirm) {
            onConfirm()
          } else if (onCancel) {
            onCancel()
          }
        })
        .catch(err => {
          console.log(err)
          if (onCancel) {
            onCancel()
          }
        })
    },
  }
}
