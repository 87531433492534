<template>
  <b-modal v-model="active" centered hide-footer :title="modalTitle"  size="lg" @ok="saveTransaction"
           @cancel="closeModal" cancel-variant="outline-danger"
  >
    <b-row>
      <b-col cols="6">
        <b-form-group label="From Account">
          <v-select v-model="transferTransaction.accountId" :clearable="false"
                    v-bind:options="accounts"
                    :reduce="s=>s.id"
                    label="displayLabel"
                    @input="onAccountSelected"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="To Account">
          <v-select v-model="transferTransaction.accountTransferId" :clearable="false"
                    v-bind:options="filteredAccounts"
                    :reduce="s => s.id"
                    label="displayLabel"
                    v-bind:disabled="!accountIsSet"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Amount">
          <b-form-input v-model="transferTransaction.amount" type="number" v-bind:disabled="!accountIsSet"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Status">
          <v-select v-model="transferTransaction.status" :clearable="false"
                    :options="$store.getters['data/allMonetaryStatus']"
                    v-bind:disabled="!accountIsSet"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="PSP">
          <v-select v-model="transferTransaction.paymentDetails.processorName" :clearable="false"
                    :options="pspOptions" v-bind:disabled="true"
          ></v-select>
        </b-form-group>
      </b-col>

<!--      <b-col cols="4">
        <b-form-group >
          <b-form-checkbox  v-model="transferTransaction.realType" class="mt-3"  value="FAKE" unchecked-value="REAL" v-bind:disabled="!accountIsSet">
            {{$t('monetary.notRegister')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>-->
      <b-col cols="12">
        <b-form-group label="Description">
          <b-form-textarea v-model="transferTransaction.description" v-bind:disabled="!accountIsSet"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col cols="12">
        <b-button :disabled="transferIsDisabled" block variant="primary" @click="saveTransaction">{{$t('monetary.transfer')}} </b-button>
      </b-col>

    </b-row>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'

const getNewTransaction = ()=>({
  clientId: null,
  accountId: null,
  paymentDetails: {processorName:"Credit & Debit Card"},
  action:"PAYMENT",
  status:"APPROVED",
  realType:"FAKE",
})

export default {
  name: 'monetaryMultiAccountModal',
  mixins:[mixinBase, mixinNotifications],
  data:()=>({
    clientId: null,
    modalTitle: 'Transfer Transaction',
    accounts: [],
    active: false,
    typeOptions: ['DEPOSIT', 'WITHDRAWAL'],
    realOptions: ['REAL', 'FAKE'],
    pspOptions: ['Bank Transfer', 'Non registered PSP','Credit & Debit Card','BTC','ETH','Byond Byond Pay'], // Example options, replace with your options
    currencyOptions: ['USD', 'EUR', 'BTC'], // Example options, replace with your options
    transferTransaction: getNewTransaction(),
  }),
  computed:{
    accountIsSet(){
      return  this.transferTransaction.accountId != null;
    },
    transferIsDisabled(){
      return !this.accountIsSet || this.transferTransaction.accountTransferId == null || this.transferTransaction.amount == null || this.transferTransaction.amount <= 0
    },
    filteredAccounts() {
      if (this.transferTransaction.accountId == null) {
        return [];
      }
      let transferAccountId = this.transferTransaction.accountId;
      return this.accounts.filter(account => account.id !== transferAccountId && account.currency === this.transferTransaction.currency);
    }
  },
  methods:{
    ...mapActions('monetary', ['transferMonetary']),

    ...mapActions('account', ['getAccountsByClient']),
    showModal(clientId){
      this.clientId = clientId;
      this.transferTransaction['clientId'] = this.clientId;
      this.active = true;
      this.refresh();
    },
    async refresh() {
      let $this = this;
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }
      this.accounts = await this.getAccountsByClient(this.clientId);
      this.accounts = this.reGenerateAccounts(this.accounts);
    },
    reGenerateAccounts(accounts) {
      return accounts.map(account => {
        const label = account.isDemo ? `${account.id} Demo-` : `${account.id} Real-`;
        const displayLabel = `${label} ${account.balance} ${account.currency}`;
        return {
          ...account,
          displayLabel
        }; // Spread account fields and add displayLabel
      });
    },
    onAccountSelected(accId){
      let account = this.accounts.find(acc=>acc.id === accId);
      if(account == null){
        return
      }
      this.transferTransaction.currency = account.currency
    },
    async saveTransaction() {
      let $this = this
      this.transferMonetary({
        monetary: this.transferTransaction
      })
          .then(() => {
            $this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.monetaries') }))
            $this.refreshTables()
            $this.closeModal()
          }, err => {
            this.showErrorNotification(this.$t('globalMessages.fetchDataError', { system: this.$t('menu.monetaries') }), err.response.data.error)
          })

    },
    closeModal() {
      this.active = false;
      this.transferTransaction = getNewTransaction();
    },
  }
}
</script>



<style scoped>

</style>
