export default function (other) {
  other = other || {}
  return {
    branchId: other.branchId || null,
    orgId: other.orgId || null,
    creationTime: other.creationTime || null,
    firstName: other.firstName || null,
    lastName: other.lastName || null,
    login: other.login || null,
    password: other.password || null,
    phone: other.phone || null,
    email: other.email || null,
    alias: other.alias || null,
    code: other.code || null,
    company: other.company || null,
    website: other.website || null,
    group: other.group || null,
    skype: other.skype || null,
    alertEmail: other.alertEmail || null,
    active: other.active || null,
    hideFtd: other.hideFtd || null,
    affiliateCpas: other.affiliateCpas || null,
    openingTime: other.openingTime || null,

  }
}
