import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    type: other.type || null,
    active: other.active || true,
    isDemo: other.isDemo || null,
    marginCall: other.marginCall || null,
    name: other.name || null,
    email: other.email || null,
    orgIds: other.orgIds || null,

    //Search
    search: other.search || '',
    clientDetails: other.clientDetails || {},
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}
