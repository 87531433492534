<template>
  <b-row class="mt-1">
    <!--sl-->
    <b-col cols="4">
      <b-form-group class="m-0">
        <b-input-group size="sm" prepend="swap" style="width: 100%">
          <b-form-input v-model="tradeDetails.swap"></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>
    <!--tp-->
    <b-col cols="4">
      <b-form-group class="m-0">
        <b-input-group size="sm" :prepend="$t('trade.taxes')" style="width: 100%">
          <b-form-input v-model="tradeDetails.taxes"></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>
    <!--commission-->
    <b-col cols="4">
      <b-form-group class="m-0">
        <b-input-group size="sm" :prepend="$t('trade.commission')" style="width: 100%">
          <b-form-input v-model="tradeDetails.commission"></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import { TradeCmdEnum } from '@/model/trade/TradeCmdEnum'

export default {
  name: 'tradeOrderCommissionPart',
  props: {
    value: {},
  },
  computed: {
    tradeDetails: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    commands() {
      return Object.keys(TradeCmdEnum)
    },
  },
  methods: {
    refreshTicker() {
      this.$emit('refreshTicker')
    }
  }
}
</script>


<style scoped>

</style>
