<!-- FilePreviewModal.vue -->
<template>
  <b-modal size="xl" v-model="showModal" title="File Preview" hide-footer no-close-on-backdrop @hidden="modalHidden" >
    <div class="file-content">
      <object :data="fileUrl" :type="fileType" :width="fileWidth" :height="fileHeight"></object>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: {
     type:Boolean,
     default: false

    }, // Use a prop to control the modal visibility
    fileUrl: String, // URL of the file content
    fileType: String, // Content type of the file (e.g., 'application/pdf')
  },
  data() {
    return {
      fileWidth: '100%', // Default width
      fileHeight: '500px', // Default height
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        // When the modal is shown, calculate the file's dimensions
        this.calculateFileDimensions();
      }
    },
  },
  methods: {
    modalHidden() {
      this.$emit('close'); // Emit a close event to notify the parent component
    },
    calculateFileDimensions() {
      // You may need to adjust this logic based on your specific file type (e.g., PDF, image, etc.)
      // Here, we assume it's an image file for demonstration purposes
      const img = new Image();
      img.src = this.fileUrl;
      img.onload = () => {
        this.fileWidth = img.width + 'px';
        this.fileHeight = img.height + 'px';
      };
    },
  },
};
</script>
