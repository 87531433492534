export default function () {
  return {
    //CALENDAR_MODULE
    CALENDAR_READ: 'CALENDAR_READ',
    CALENDAR_READ_RETENTION: 'CALENDAR_READ_RETENTION',
    CALENDAR_UPDATE: 'CALENDAR_UPDATE',
    CALENDAR_READ_ALL: 'CALENDAR_READ_ALL',
    CALENDAR_UPDATE_ALL: 'CALENDAR_UPDATE_ALL',

    //ORG_MODULE
    ORG_READ_ALL: 'ORG_READ_ALL',
    ORG_UPDATE: 'ORG_UPDATE',

    //BU_MODULE
    BU_READ_ALL: 'BU_READ_ALL',
    BU_UPDATE: 'BU_UPDATE',

    //USER_MODULE
    USER_READ_BASIC: 'USER_READ_BASIC',
    USER_READ_ALL: 'USER_READ_ALL',
    USER_UPDATE: 'USER_UPDATE',

    //USER_ROLES_MODULE
    USER_ROLES_READ_ALL: 'USER_ROLES',
    USER_ROLES_UPDATE: 'USER_ROLES_UPDATE',

    //AFFILIATE_MODULE
    AFFILIATE_READ_ALL: 'AFFILIATE_READ_ALL',
    AFFILIATE_UPDATE: 'AFFILIATE_UPDATE',

    //CLIENT_MODULE
    CLIENT_READ: 'CLIENT_READ',
    CLIENT_READ_WITH_AFFILIATE: 'CLIENT_READ_WITH_AFFILIATE',
    CLIENT_READ_ALL: 'CLIENT_READ_ALL',
    CLIENT_READ_CONTACT_IN_LIST: 'CLIENT_READ_CONTACT_IN_LIST',
    CLIENT_READ_REPRESENTATIVE: 'CLIENT_READ_REPRESENTATIVE',
    CLIENT_READ_AFFILIATE: 'CLIENT_READ_AFFILIATE',

    CLIENT_UPDATE: 'CLIENT_UPDATE',
    CLIENT_UPDATE_DETAILS: 'CLIENT_UPDATE_DETAILS',
    CLIENT_UPDATE_BU: 'CLIENT_UPDATE_BU',
    CLIENT_UPDATE_AFFILIATE: 'CLIENT_UPDATE_AFFILIATE',
    CLIENT_UPDATE_ORG: 'CLIENT_UPDATE_ORG',
    CLIENT_UPDATE_STATUS: 'CLIENT_UPDATE_STATUS',
    CLIENT_UPDATE_REPRESENTATIVE: 'CLIENT_UPDATE_REPRESENTATIVE',
    CLIENT_UPDATE_CONTACT: 'CLIENT_UPDATE_CONTACT',

    //ACCOUNT_MODULE
    ACCOUNT_READ: 'ACCOUNT_READ',
    ACCOUNT_READ_ALL: 'ACCOUNT_READ_ALL',
    ACCOUNT_CREATE: 'ACCOUNT_CREATE',
    ACCOUNT_OPERATION: 'ACCOUNT_OPERATION',

    //TRADE_MODULE
    TRADE_READ: 'TRADE_READ',
    TRADE_READ_ALL: 'TRADE_READ_ALL',
    TRADE_MANIPULATE: 'TRADE_MANIPULATE',

    //MONETARY_MODULE
    MONETARY_READ: 'MONETARY_READ',
    MONETARY_READ_ALL: 'MONETARY_READ_ALL',
    MONETARY_UPDATE: 'MONETARY_UPDATE',
    MONETARY_WITHDRAWAL_REQUEST_READ: 'MONETARY_WITHDRAWAL_READ',
    MONETARY_REPORT_READ: 'MONETARY_REPORT_READ',

    //KYC_MODULE
    KYC_READ: 'KYC_READ',
    KYC_READ_ALL: 'KYC_READ_ALL',
    KYC_UPDATE: 'KYC_UPDATE',

    //DOCUMENT_MODULE
    DOCUMENT_READ: "DOCUMENT_READ",
    DOCUMENT_READ_ALL: "DOCUMENT_READ_ALL",
    DOCUMENT_UPDATE: "DOCUMENT_UPDATE",

    //SMTP_MODULE
    SMTP_READ: 'SMTP_READ',
    SMTP_READ_ALL: 'SMTP_READ_ALL',
    SMTP_UPDATE: 'SMTP_UPDATE',

    // MAIL__TEMPLATE_MODULE
    MAIL_TEMPLATE_READ_ALL: 'MAIL_TEMPLATE_READ',
    MAIL_TEMPLATE_UPDATE: 'MAIL_TEMPLATE_UPDATE',

    //TRADING SYSTEM MODULE
    TRADING_SYSTEM_SYMBOLS_UPDATE: "TRADING_SYSTEM_READ",
    TRADING_SYSTEM_SYMBOLS_GROUPS_UPDATE: "TRADING_SYSTEM_SYMBOLS_GROUPS_UPDATE",

    //TRADING MODULE
    TRADING_READ_ALL: "TRADING_READ_ALL",

    CLIENT_STATUS_READ_ALL: 'CLIENT_STATUS_READ_ALL',
    CLIENT_STATUS_UPDATE: 'CLIENT_STATUS_UPDATE',

  }
}
