import api from '@/api';
import MailTemplateRequest from '@/model/mailTemplate/MailTemplateRequest';
import MailTemplateSearchRequest from '@/model/mailTemplate/MailTemplateSearchRequest';

export default {
  namespaced: true,
  state: {
    statusIds: []
  },
  getters: {
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id)
  },
  mutations: {
    updateData(state, data) {
    }
  },
  actions: {
    refreshData({ commit }) {
      return api.base()
        .get('/mailTemplate/data')
        .then(
          response => {
            commit('updateData', response.data.data);
          }
        );
    },
    getAllMailTemplates(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/mailTemplate/get/all',
          MailTemplateSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getMailTemplateById({ commit }, id) {
      return api.base()
        .get('/mailTemplate/id/' + id)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateMailTemplate({ commit }, {
      mailTemplate,
      id
    }) {
      return api.base()
        .post('/mailTemplate/update/' + id, MailTemplateRequest(mailTemplate))
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    createMailTemplate({ commit }, { mailTemplate }) {
      return api.base()
        .post('/mailTemplate/add', MailTemplateRequest(mailTemplate))
        .then(
          response => {
            return response.data.data;
          }
        );
    },

  }

};
